import { action, computed, observable, runInAction } from "mobx";

import { flatten } from "@bps/fluent-ui";
import { chunksOf } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { appContext } from "@libs/config/app-context.utils.ts";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import {
  deepEqualResolver,
  sharePendingPromise
} from "@libs/decorators/sharePendingPromise.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AddIndividualContactDto,
  AddOrganisationContactDto,
  AddPatientDto,
  AddPatientNoticeDto,
  ContactDto,
  ContactResultItemDto,
  ContactType,
  GetContactsDto,
  GetMatchedContactsDto,
  OrganisationRoleType,
  OrganisationRoleTypeCode,
  OrgUnitCompanyDataType,
  OrgUnitDto,
  PatchIndividualDto,
  PatchOrganisationContactDto,
  PatchPatientDto,
  PatientNoticeArgs,
  PatientNoticeDto,
  ProviderDto,
  RelationshipDto,
  SaveProfilePictureDto,
  SaveSignatureDto,
  UpdateMergedStatusRequest,
  UpdateOrgUnitDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IPracticeGateway } from "@libs/gateways/practice/PracticeGateway.interface.ts";
import { patchModel } from "@libs/models/model.utils.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import {
  capitalizeSentence,
  getOrThrow,
  wildCardCheck
} from "@libs/utils/utils.ts";
import { GetContactOptions } from "@shared-types/practice/get-contact-options.type.ts";
import { SystemNoticeType } from "@shared-types/practice/system-notice-type.type.ts";
import { SystemNotice } from "@shared-types/practice/system-notice.interface.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccountBalance } from "@stores/billing/models/AccountBalance.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { SaveProvider } from "@stores/practice/types/save-provider.type.ts";
import {
  capitaliseAddresses,
  capitaliseFullName
} from "@stores/practice/utils/practice.utils.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { PatientNotice } from "./models/PatientNotice.ts";
import { PracOrgUnit } from "./models/PracOrgUnit.ts";
import { Provider, toProviderDto } from "./models/Provider.ts";
import { PracticeUi } from "./PracticeUi.ts";
import { PracticeRef } from "./ref/PracticeRef.ts";

type UpdateContact =
  | Omit<PatchPatientDto, "eTag">
  | Omit<PatchIndividualDto, "eTag">
  | Omit<PatchOrganisationContactDto, "eTag">;

export class PracticeStore implements Store<PracticeStore, PracticeRef> {
  constructor(
    private gateway: IPracticeGateway,
    public hub: IHubGateway
  ) {
    this.ref = new PracticeRef(gateway);
  }

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.Contact, this.onContactUpdateEvent);
    this.hub.onEntityEvent(Entity.Provider, this.onProviderEvent);
    this.hub.onEntityEvent(Entity.PatientNotice, this.onPatientNoticeEvent);
    this.hub.onEntityEvent(Entity.Service, this.onServiceEvent);
    this.hub.onEntityEvent(Entity.OrgUnit, this.onOrgUnitUpdateEvent);
  }

  root: IRootStore;
  ref: PracticeRef;
  ui = new PracticeUi(this);

  private get core() {
    return this.root.core;
  }

  private get notification() {
    return this.root.notification;
  }

  contactsMap = observable.map<string, Contact>();
  providersMap = observable.map<string, Provider>();
  pracOrgUnitMap = observable.map<string, PracOrgUnit>();
  patientNoticesMap = observable.map<string, PatientNotice>();
  allOrgUnitsLocationDataMap = observable.map<string, PracOrgUnit>();

  @observable
  public systemNotices: SystemNotice[] = [];

  @action
  private onServiceEvent = async (event: EntityEventData) => {
    // this function treats Update events as the same as Create events because
    //  updated fees will have a new ID
    this.ui.lastServiceETag = event.etag;
  };

  @computed
  get contactTitles() {
    return this.core.ref.titles.values
      .filter(x => x.contactCommon)
      .concat(this.core.ref.titles.values.filter(x => !x.contactCommon));
  }

  @action
  private mergeContact = (dto: ContactDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Contact(this.root, dto),
      map: this.contactsMap
    });
  };

  @action
  mergeProvider = (dto: ProviderDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Provider(dto),
      map: this.providersMap
    });
  };

  @action
  private mergeOrgUnit = (dto: OrgUnitDto) => {
    return mergeModel({
      dto,
      map: this.pracOrgUnitMap,
      getNewModel: () => new PracOrgUnit(dto)
    });
  };

  @action
  private mergePatientNotice = (dto: PatientNoticeDto) => {
    return mergeModel({
      dto,
      map: this.patientNoticesMap,
      getNewModel: () => new PatientNotice(dto)
    });
  };

  @sharePendingPromise()
  getOrgUnit = async (
    orgUnitId: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<PracOrgUnit | undefined> => {
    const cachedOrgUnit = this.pracOrgUnitMap.get(orgUnitId);
    if (!options.ignoreCache && cachedOrgUnit) {
      return cachedOrgUnit;
    }

    const response = await this.gateway.getOrgUnit(orgUnitId);

    try {
      return response ? this.mergeOrgUnit(response) : undefined;
    } catch (error) {
      throw error;
    }
  };

  async updateOrgUnit(request: Omit<UpdateOrgUnitDto, "eTag">) {
    const pracOrgUnit =
      this.pracOrgUnitMap.get(request.id) ??
      new PracOrgUnit({
        id: request.id,
        orgUnitCompanyData: request.orgUnitCompanyData,
        eTag: ""
      });
    return this.gateway
      .updateOrgUnit({
        id: request.id,
        orgUnitCompanyData: pracOrgUnit.getUpdatedOrgUnitCompanyData(
          request.orgUnitCompanyData
        ),
        eTag: pracOrgUnit.eTag
      })
      .then(this.mergeOrgUnit);
  }

  @action
  private onOrgUnitUpdateEvent = async (event: EntityEventData) => {
    try {
      if (
        event.id != null &&
        (event.action === EventAction.Create ||
          event.action === EventAction.Update)
      ) {
        const pracOrgUnit = this.pracOrgUnitMap.get(event.id);
        if (!pracOrgUnit || (pracOrgUnit && pracOrgUnit.eTag !== event.etag)) {
          this.getOrgUnit(event.id, { ignoreCache: true });
          runInAction(() => {
            this.ui.recentlyUpdatedOrgUnitEtag = event.etag;
          });
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  @action
  @sharePendingPromise()
  async getSaSUri() {
    return await this.gateway.getSaSUri();
  }

  @action
  async updateProfilePicture(request: SaveProfilePictureDto) {
    const contact = await this.gateway.postProfilePicture(request);
    return (
      this.contactsMap
        .get(contact.id)
        // updateFromPatch used instead of mergeContact because postProfilePicture updates profilePictureUrl field only and returns ContactDto with the previous eTag. Ilya S.
        ?.updateFromPatch({ profilePictureUrl: contact.profilePictureUrl })
    );
  }

  @action
  deleteProfilePicture = async (id: string) => {
    await this.gateway.deleteProfilePicture(id);
    const updatedContact = this.contactsMap.get(id);

    if (updatedContact) {
      updatedContact.updateFromDto({
        ...updatedContact.dto,
        profilePictureUrl: undefined
      });
    } else {
      const contactDto = await this.gateway.getContact(id);
      this.mergeContact(contactDto);
    }
  };

  @action
  updateSignature = async (request: SaveSignatureDto) => {
    const updatedProvider = await this.gateway.postSignature(request);

    if (updatedProvider) {
      return this.providersMap
        .get(updatedProvider.id)
        ?.updateFromPatch({ signatureUrl: updatedProvider.signatureUrl });
    }
  };

  @action
  deleteSignature = async (id: string) => {
    await this.gateway.deleteSignature(id);
    const updatedProvider = this.providersMap.get(id);

    if (updatedProvider) {
      updatedProvider.updateFromDto({
        ...updatedProvider.dto,
        signatureUrl: undefined
      });
    } else {
      const providerDto = await this.gateway.getProvider(id);
      this.mergeProvider(providerDto);
    }
  };

  @action
  updateComponyLogoPicture = async (request: SaveProfilePictureDto) => {
    const orgUnit = await this.gateway.postOrgUnitProfilePicture(request);
    if (orgUnit)
      return this.pracOrgUnitMap
        .get(orgUnit.id)
        ?.updateFromPatch({ profilePictureUrl: orgUnit.profilePictureUrl });
  };

  @action
  deleteComponyLogoPicture = async (id: string) => {
    await this.gateway.deleteOrgUnitProfilePicture(id);
    const updatedOrgUnit = this.pracOrgUnitMap.get(id);

    if (updatedOrgUnit) {
      updatedOrgUnit.updateFromDto({
        ...updatedOrgUnit.dto,
        profilePictureUrl: undefined
      });
    }
  };

  addPatient = async (data: AddPatientDto) => {
    // Name and addresses get capitalised on add only
    const dtoWithCapitalisation: AddPatientDto = {
      ...data,
      fullName: capitaliseFullName(data.fullName),
      addresses: data.addresses && capitaliseAddresses(data.addresses)
    };

    const patient = await this.gateway
      .addPatient(dtoWithCapitalisation)
      .then(this.mergeContact);
    this.onAddedContact(patient);
    return patient;
  };

  @action
  private updateContact = async (request: UpdateContact) => {
    const contact = getOrThrow(this.contactsMap, request.id);

    // always capitalise addresses
    const requestWithCapitalisation = {
      ...request,
      addresses: request.addresses && capitaliseAddresses(request.addresses)
    };

    try {
      await patchModel<Contact, ContactDto, UpdateContact>(
        requestWithCapitalisation,
        req => {
          switch (contact.type) {
            case ContactType.Organisation: {
              this.retainOrganisationContactMetadata(contact, req);

              return this.gateway.updateOrganisationContact(req);
            }
            case ContactType.Individual: {
              return this.gateway.updateIndividual(req);
            }
            default: {
              throw Error(`Unknown contact type ${contact.type}`);
            }
          }
        },
        {
          modelMap: this.contactsMap
        }
      );

      this.notification.success(
        notificationMessages.contactUpdated(contact.name)
      );
    } catch (e) {
      this.notification.error(e);
    }

    return contact;
  };

  @action
  updatePatient = async (request: Omit<PatchPatientDto, "eTag">) => {
    const contact = getOrThrow(this.contactsMap, request.id);

    // always capitalise addresses
    const requestWithCapitalisation = {
      ...request,
      addresses: request.addresses && capitaliseAddresses(request.addresses)
    };

    await patchModel(
      requestWithCapitalisation,
      req => this.gateway.updatePatient(req),
      {
        modelMap: this.contactsMap
      }
    );

    this.notification.success(
      notificationMessages.contactUpdated(contact.name)
    );

    return contact;
  };

  updatePatientMergedStatus = async (
    id: string,
    request: UpdateMergedStatusRequest
  ) => {
    const patient = await this.gateway.updatePatientMergedStatus(id, request);
    return this.mergeContact(patient);
  };

  addIndividual = async (data: AddIndividualContactDto) => {
    // Name and addresses get capitalised on add only
    const dtoWithCapitalisation: AddIndividualContactDto = {
      ...data,
      fullName: capitaliseFullName(data.fullName),
      addresses: data.addresses && capitaliseAddresses(data.addresses)
    };

    const contact = await this.gateway
      .addIndividualContact(dtoWithCapitalisation)
      .then(this.mergeContact);
    this.onAddedContact(contact);
    return contact;
  };

  @action
  updateIndividual = async (request: Omit<PatchIndividualDto, "eTag">) =>
    this.updateContact(request);

  addOrganisation = async (data: AddOrganisationContactDto) => {
    // Name and addresses get capitalised on add only
    const dtoWithCapitalisation: AddOrganisationContactDto = {
      ...data,
      organisationName:
        data.organisationName &&
        capitalizeSentence(data.organisationName, { allWords: true }),
      addresses: data.addresses && capitaliseAddresses(data.addresses)
    };

    const contact = await this.gateway
      .addOrganisation(dtoWithCapitalisation)
      .then(this.mergeContact);
    this.onAddedContact(contact);
    return contact;
  };

  @action
  updateOrganisation = async (
    request: Omit<PatchOrganisationContactDto, "eTag">
  ) => this.updateContact(request);

  private retainOrganisationContactMetadata(
    contact: Contact,
    req: DeepNullableExcept<
      DeepPartialExcept<Omit<UpdateContact, "id">, string | number | any[]>,
      string | number | any[]
    > & { id: string; eTag: string }
  ) {
    const existingRelationships = contact.relationships;
    req.relationships = req.relationships?.map((r: RelationshipDto) => {
      const existing = existingRelationships.find(
        e => e.relatedContactId === r.relatedContactId
      );
      //If there is an existing relationship with metadata, then retain this metadata
      if (existing && existing.metadata && !r.metadata) {
        r.metadata = existing.metadata;
      }
      return r;
    });
  }

  fetchContacts = (options: {
    filter: GetContactsDto;
    resultFilterPredicate?: (r: ContactResultItemDto) => boolean;
  }): Promise<QueryResult<Contact>> => {
    const { filter, resultFilterPredicate } = options;
    return this.gateway
      .getContacts(
        processFilter({
          ...filter
        })
      )
      .then(dtoResult => {
        const { results, ...rest } = dtoResult;
        const resultsFinal = resultFilterPredicate
          ? results.filter(resultFilterPredicate)
          : results;

        return {
          results: resultsFinal.map(x => this.mergeContact(x.value)),
          ...rest
        };
      });
  };

  fetchMatchedContacts = (options: {
    filter: GetMatchedContactsDto;
    resultFilterPredicate?: (r: ContactResultItemDto) => boolean;
  }) => {
    const { filter, resultFilterPredicate } = options;
    return this.gateway.getMatchedContacts(filter).then(dtoResult => {
      const { results, ...rest } = dtoResult;
      const resultsFinal = resultFilterPredicate
        ? results.filter(resultFilterPredicate)
        : results;

      return observable<QueryResult<Contact>>({
        results: resultsFinal.map(x => this.mergeContact(x.value)),
        ...rest
      });
    });
  };

  @sharePendingPromise()
  private async fetchContact(id: string) {
    const contactDto = await this.gateway.getContact(id);
    return this.mergeContact(contactDto);
  }

  @sharePendingPromise()
  getContact = async (
    id: string,
    options: GetContactOptions = {
      ignoreCache: false,
      includeRelationships: false,
      includeContactPreferences: false
    }
  ) => {
    try {
      const loadContactPromise = new Promise<Contact>(
        async (resolve, reject) => {
          try {
            const contact =
              (!options.ignoreCache && this.contactsMap.get(id)) ||
              (await this.fetchContact(id));

            if (options.includeRelationships) {
              await contact.loadRelatedContacts();
            }
            resolve(contact);
          } catch (error) {
            reject(error);
          }
        }
      );

      const loadContactPreferencesPromise = options.includeContactPreferences
        ? this.root.comms.getContactPreference(id)
        : undefined;

      const [contact] = await Promise.all([
        loadContactPromise,
        loadContactPreferencesPromise
      ]);

      return contact;
    } catch (error) {
      this.ui.contactErrorSubscribers.forEach(handler => {
        handler(id, error);
      });
      throw error;
    }
  };

  @sharePendingPromise()
  async getProvider(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    const cached = this.providersMap.get(id);
    if (options.ignoreCache || !cached) {
      const dto = await this.gateway.getProviderWithSignatureUrl(id);
      return this.mergeProvider(dto);
    }

    return cached;
  }

  async addProvider(request: SaveProvider) {
    const providerDto = await this.gateway.addProvider({
      ...toProviderDto({ ...request, orgUnitId: this.core.locationId })
    });
    return this.mergeProvider(providerDto);
  }

  async updateProvider(request: SaveProvider) {
    const provider = getOrThrow(this.providersMap, request.id);

    const dto = await this.gateway.putProvider({
      ...toProviderDto({
        ...request,
        orgUnitId: this.core.locationId
      }),
      eTag: provider.eTag
    });

    return this.mergeProvider(dto);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getContactsById(ids: string[]): Promise<Contact[]> {
    if (!ids || !ids.length) {
      return [];
    }

    const newIds = ids.filter(id => !this.contactsMap.get(id));

    if (newIds.length) {
      const contactDtoChunks = await Promise.all(
        chunksOf(newIds, 40).map(chunk =>
          this.gateway
            .getContacts({ ids: chunk, take: chunk.length })
            .then(data => data.results.map(x => x.value))
        )
      );

      flatten(contactDtoChunks).map(x => this.mergeContact(x));
    }

    // return both newly fetched and skipped contacts
    return ids.map(id => this.contactsMap.get(id)!);
  }

  /**
   * onContactUpdateEvent is called when a contact update event is received from the SignalR hub
   */
  private onContactUpdateEvent = async (event: EntityEventData) => {
    // retrieve the updated contact unless the store already has the latest version
    try {
      const contact = this.contactsMap.get(event.id);
      const relationUpdated = event.action === EventAction.UpdateRelationship;
      if (contact && (contact.eTag !== event.etag || relationUpdated)) {
        // any contact updates triggers contact update event, even when a contact is a patient
        await this.getContact(event.id, {
          ignoreCache: true,
          includeRelationships: relationUpdated
        });
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onProviderEvent = async (event: EntityEventData) => {
    try {
      if (
        event.action === EventAction.Create ||
        event.action === EventAction.Update
      ) {
        if (this.root.core.hasPermissions([Permission.ProviderRead])) {
          runInAction(() => {
            this.ui.recentlyUpdatedProviderEtag = event.etag;
          });

          const provider = this.providersMap.get(event.id);
          if (!provider || provider.eTag !== event.etag) {
            await this.getProvider(event.id, { ignoreCache: true });
          }
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onPatientNoticeEvent = (event: EntityEventData) => {
    if (event.action === EventAction.Delete) {
      runInAction(() => {
        this.patientNoticesMap.delete(event.id);
      });
    }
  };

  @action
  private onAddedContact(contact: Contact): void {
    this.ui.recentlyAddedContactId = contact.id;

    setTimeout(
      () =>
        runInAction(() => {
          this.ui.recentlyAddedContactId = undefined;
        }),
      appContext.config.notificationDuration
    );

    this.notification.success(`${contact.name} has been added.`);
  }

  async getPatientNotices(patientId: string): Promise<PatientNotice[]> {
    try {
      const notices = await this.gateway.getPatientNotices(patientId);
      return notices.map(this.mergePatientNotice);
    } catch (e) {
      throw e;
    }
  }

  @action
  getPatientNotice = async (id: string): Promise<PatientNoticeDto> => {
    try {
      const map = this.patientNoticesMap.get(id);
      if (map) return map;

      const patientNotice = await this.gateway.getPatientNotice(id);
      return this.mergePatientNotice(patientNotice);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  };

  getPatientNoticesByArgs = async (
    args: PatientNoticeArgs
  ): Promise<PatientNotice[]> => {
    const notices = await this.gateway.getPatientNoticesByArgs(args);
    return notices.map(this.mergePatientNotice);
  };

  @action
  addPatientNotice = async (
    newNotice: AddPatientNoticeDto
  ): Promise<PatientNotice> => {
    const patientNotice = await this.gateway.addPatientNotice(newNotice);
    this.notification.success("Patient notice has been recorded.");
    return this.mergePatientNotice(patientNotice);
  };

  @action
  updatePatientNotice = async (
    newNotice: PatientNoticeDto
  ): Promise<PatientNotice> => {
    try {
      const patientNotice = await this.gateway.updatePatientNotice(newNotice);
      this.notification.success("Patient notice has been updated.");
      return this.mergePatientNotice(patientNotice);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  };

  @action
  deletePatientNotice = async (id: string): Promise<void> => {
    try {
      await this.gateway.deletePatientNotice(id);
      runInAction(() => {
        this.patientNoticesMap.delete(id);
      });

      this.notification.success("Patient notice has been deleted.");
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  };

  generateSystemNotices = (
    patient: Contact,
    accountTotals?: AccountBalance
  ): SystemNotice[] => {
    const systemNotices: SystemNotice[] = [];
    const patientId = patient.id;

    if (accountTotals?.unallocatedCredit || accountTotals?.totalOwing) {
      const owingTotal =
        accountTotals.totalOwing > 0
          ? currencyFormat(accountTotals.totalOwing)
          : undefined;

      const unallocatedCredit =
        accountTotals.unallocatedCredit > 0
          ? currencyFormat(accountTotals.unallocatedCredit)
          : undefined;

      systemNotices.push({
        type: SystemNoticeType.account,
        owingTotal,
        unallocatedCredit,
        patientId
      });
    }

    if (patient.healthInsuranceIsExpired || patient.healthInsuranceWillExpire) {
      systemNotices.push({
        type: SystemNoticeType.cardExpired,
        comment: `Health fund (expire${
          patient.healthInsuranceIsExpired ? "d" : "s"
        } ${patient.healthInsuranceExpiryDate?.toDayDefaultFormat()})`,
        patientId
      });
    }

    if (patient.medicareIsExpired || patient.medicareWillExpire) {
      systemNotices.push({
        type: SystemNoticeType.cardExpired,
        comment: `Medicare (expire${
          patient.medicareIsExpired ? "d" : "s"
        } ${patient.medicareExpiryDate?.toDayDefaultFormat()})`,
        patientId
      });
    }

    if (patient.cscIsExpired || patient.cscWillExpire) {
      systemNotices.push({
        type: SystemNoticeType.cardExpired,
        comment: `Community Services Card (expire${
          patient.cscIsExpired ? "d" : "s"
        } ${patient.cscExpiryDate?.toDayDefaultFormat()})`,
        patientId
      });
    }

    if (patient.interpreterLanguage) {
      systemNotices.push({
        type: SystemNoticeType.interpreter,
        comment: `${patient.interpreterLanguageValue}`,
        patientId
      });
    }

    return systemNotices;
  };

  getSystemNotices = async (patientId: string): Promise<SystemNotice[]> => {
    const [patient, accountTotals] = await Promise.all([
      this.getContact(patientId),
      this.core.hasPermissions(Permission.AccountHistoryAllowed)
        ? this.root.billing.getAccountBalanceForAccountId(patientId)
        : undefined
    ]);
    return this.generateSystemNotices(patient, accountTotals);
  };

  loadSystemNotices = async (patientIds: string[]) => {
    const systemNotices = await Promise.all(
      patientIds.map(id => this.getSystemNotices(id))
    );

    runInAction(() => {
      // Update with newly generated while keeping old
      this.systemNotices = [
        ...this.systemNotices.filter(sn => !patientIds.includes(sn.patientId)),
        ...flatten(systemNotices)
      ];
    });

    return this.systemNotices;
  };

  private defaultNzPublicInsurerId: string | undefined;
  private defaultNzPrivateInsurerId: string | undefined;

  getDefaultInsurers = async (): Promise<{
    defaultNzPublicInsurerId: string;
    defaultNzPrivateInsurerId: string;
  }> => {
    if (!this.defaultNzPublicInsurerId || !this.defaultNzPrivateInsurerId) {
      try {
        const pr1 = this.gateway.getOrganisations({
          organisationRoleTypeCodes: [OrganisationRoleType.NzPublicInsurer]
        });

        const pr2 = this.gateway.getOrganisations({
          organisationRoleTypeCodes: [OrganisationRoleType.NzPrivateInsurer]
        });

        const [pubInsurers, pvtInsurers] = await Promise.all([pr1, pr2]);

        this.defaultNzPublicInsurerId = pubInsurers[0]?.id;
        this.defaultNzPrivateInsurerId = pvtInsurers[0]?.id;

        if (!this.defaultNzPublicInsurerId && !this.defaultNzPrivateInsurerId) {
          throw new Error("Unable to find default insurer");
        }
      } catch (e) {
        this.notification.error(e);
        throw e;
      }
    }

    return {
      defaultNzPublicInsurerId: this.defaultNzPublicInsurerId,
      defaultNzPrivateInsurerId: this.defaultNzPrivateInsurerId
    };
  };

  getLinkedAccreditedEmployers = async (id: string) => {
    try {
      const response = await this.gateway.getLinkedAccreditedEmployers(id);
      return response.map(this.mergeContact);
    } catch (e) {
      throw e;
    }
  };

  isNzPublicInsurer = (
    organisationRoles: OrganisationRoleTypeCode[]
  ): boolean =>
    organisationRoles.some(
      orgRole =>
        orgRole.organisationRoleTypeCode ===
        OrganisationRoleType.NzPublicInsurer
    );

  mergePatients = async (masterPatientId: string, duplicatePatientId: string) =>
    this.gateway.mergePatients({
      masterPatientId,
      duplicatePatientId
    });

  @sharePendingPromise()
  async getAllOrgUnitsLocationData() {
    const dtos = await this.gateway.getOrgUnitByArgs({
      orgUnitCompanyDataTypeCodes: [OrgUnitCompanyDataType.LocationData]
    });

    return dtos.map(this.mergeOrgUnitsLocationData);
  }

  @action
  private mergeOrgUnitsLocationData = (dto: OrgUnitDto) => {
    return mergeModel({
      dto,
      map: this.allOrgUnitsLocationDataMap,
      getNewModel: () => new PracOrgUnit(dto)
    });
  };
}

function processFilter({ search, ...rest }: GetContactsDto): GetContactsDto {
  return {
    ...rest,
    search: search ? wildCardCheck(search) : undefined
  };
}
