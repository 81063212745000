import {
  AccScheduleDto,
  AccScheduleProcessingStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { ModelBase } from "@libs/models/Model.ts";

export class AccSchedule extends ModelBase<AccScheduleDto> {
  accSchedule: any;

  get claimSubmittable() {
    return (
      !this.dto.processingStatus ||
      this.dto.processingStatus === AccScheduleProcessingStatuses.Error
    );
  }

  get accChangedDate() {
    return this.dto?.changedDate;
  }

  get transactionStatus() {
    return this.dto?.transactionStatus;
  }

  get submittedDate() {
    return this.dto?.submittedDate;
  }

  get errorMessages() {
    return this.dto.errorMessages;
  }
}
