import { computed } from "mobx";

import { EncounterClinicalDataDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ModelBase } from "@libs/models/Model.ts";

export class EncounterClinicalData extends ModelBase<EncounterClinicalDataDto> {
  @computed
  get reaction() {
    return this.dto.reaction;
  }

  @computed
  get reactionsChecked() {
    return this.dto.reactionsChecked;
  }

  @computed
  get k10() {
    return this.dto.k10;
  }

  @computed
  get nprs() {
    return this.dto.nprs;
  }

  @computed
  get orebro() {
    return this.dto.orebro;
  }

  @computed
  get grcs() {
    return this.dto.grcs;
  }

  @computed
  get medication() {
    return this.dto.medication;
  }

  @computed
  get medicalHistory() {
    return this.dto.medicalHistory;
  }

  @computed
  get imagingRequests() {
    return this.dto.imagingRequests;
  }

  @computed
  get imagingReports() {
    return this.dto.imagingReports;
  }

  @computed
  get physicalActivity() {
    return this.dto.physicalActivity;
  }

  @computed
  get social() {
    return this.dto.social;
  }

  @computed
  get patientDemographicUpdate() {
    return this.dto.patientDemographicUpdate;
  }

  @computed
  get currentInjury() {
    return this.dto.currentInjury;
  }

  @computed
  get goals() {
    return this.dto.goals;
  }

  @computed
  get treatmentPlan() {
    return this.dto.treatmentPlan;
  }

  @computed
  get consentAndWarnings() {
    return this.dto.consentAndWarnings;
  }

  @computed
  get injuryArea() {
    return this.dto.injuryArea;
  }

  @computed
  get reasonForVisit() {
    return this.dto.reasonForVisit;
  }

  @computed
  get tobacco() {
    return this.dto.tobacco;
  }

  @computed
  get substanceUse() {
    return this.dto.substanceUse;
  }

  @computed
  get alcoholConfirmed() {
    return this.dto.alcoholConfirmed;
  }

  @computed
  get tobaccoConfirmed() {
    return this.dto.tobaccoConfirmed;
  }

  @computed
  get substanceUseConfirmed() {
    return this.dto.substanceUseConfirmed;
  }

  @computed
  get socialHistoryConfirmed() {
    return this.dto.socialHistoryConfirmed;
  }

  @computed
  get physicalActivityConfirmed() {
    return this.dto.physicalActivityConfirmed;
  }

  @computed
  get sleepConfirmed() {
    return this.dto.sleepConfirmed;
  }

  @computed
  get dermatomesAndMyotomesConfirmed() {
    return this.dto.dermatomesAndMyotomesConfirmed;
  }

  @computed
  get sleep() {
    return this.dto.sleep;
  }

  @computed
  get alcohol() {
    return this.dto.alcohol;
  }

  @computed
  get fullBody() {
    return this.dto.fullBody;
  }

  @computed
  get bodyArea() {
    return this.dto.bodyArea;
  }

  @computed
  get bodyAreaConfirmed() {
    return this.dto.bodyAreaConfirmed;
  }

  @computed
  get amendments() {
    return this.dto.amendment;
  }

  @computed
  get psfs() {
    return this.dto.psfs;
  }

  @computed
  get psfsContext() {
    return this.dto.psfsContext;
  }

  @computed
  get dash() {
    return this.dto.dash;
  }

  @computed
  get workHistory() {
    return this.dto.workHistory;
  }

  @computed
  get workHistoryConfirmed() {
    return this.dto.workHistoryConfirmed;
  }

  @computed
  get clinicalTask() {
    return this.dto.clinicalTask;
  }

  @computed
  get clinicalActivity() {
    return this.dto.clinicalActivity;
  }

  @computed
  get physioBody() {
    return this.dto.physioBody;
  }

  @computed
  get physioMedicalHistory() {
    return this.dto.physioMedicalHistory;
  }

  get customClinicalTool() {
    return this.dto.customClinicalTool;
  }

  @computed
  get customClinicalToolContext() {
    return this.dto.customClinicalToolContext;
  }

  @computed
  get postureOrObservations() {
    return this.dto.postureOrObservations;
  }

  @computed
  get otherMovements() {
    return this.dto.otherMovements;
  }

  @computed
  get analysisAndPlan() {
    return this.dto.analysisAndPlan;
  }

  @computed
  get treatmentAndEducation() {
    return this.dto.treatmentAndEducation;
  }

  @computed
  get followUpNotes() {
    return this.dto.followUpNotes;
  }

  @computed
  get diagnoses() {
    return this.dto.diagnoses;
  }

  @computed
  get injury() {
    return this.dto.injury;
  }

  @computed
  get discharge() {
    return this.dto.discharge;
  }

  @computed
  get claimReview() {
    return this.dto.claimReview;
  }

  @computed
  get patientTreatmentPlan() {
    return this.dto.patientTreatmentPlan;
  }

  @computed
  get documentOperations() {
    return this.dto.documentOperations;
  }

  @computed
  get primaryDiagnoses() {
    return this.dto.diagnoses?.diagnoses?.find(item => item.isPrimaryDiagnosis);
  }

  @computed
  get generalExamination() {
    return this.dto.generalExamination;
  }

  @computed
  get generalExamConfirmed() {
    return this.dto.generalExamConfirmed;
  }

  @computed
  get clinicalFlags() {
    return this.dto.clinicalFlags;
  }

  @computed
  get systemsReview() {
    return this.dto.systemsReview;
  }

  @computed
  get systemsReviewConfirmed() {
    return this.dto.systemsReviewConfirmed;
  }

  @computed
  get medicationsAndSupplements() {
    return this.dto.medicationsAndSupplements;
  }

  @computed
  get medicationsAndSupplementsConfirmed() {
    return this.dto.medicationsAndSupplementsConfirmed;
  }

  @computed
  get clinicalFlagsConfirmed() {
    return this.dto.clinicalFlagsConfirmed;
  }

  @computed
  get hofpcConfirmed() {
    return this.dto.hofpcConfirmed;
  }

  @computed
  get dermatomesAndMyotomes() {
    return this.dto.dermatomesAndMyotomes;
  }

  @computed
  get familyHistory() {
    return this.dto.familyHistory;
  }

  @computed
  get familyHistoryConfirmed() {
    return this.dto.familyHistoryConfirmed;
  }

  @computed
  get rand36() {
    return this.dto.rand36;
  }

  @computed
  get epds() {
    return this.dto.epds;
  }

  @computed
  get dass21() {
    return this.dto.dass21;
  }

  @computed
  get centralNervousSystem() {
    return this.dto.centralNervousSystem;
  }

  @computed
  get centralNervousSystemConfirmed() {
    return this.dto.centralNervousSystemConfirmed;
  }

  @computed
  get prescriptions() {
    return this.dto.prescriptions;
  }
}
