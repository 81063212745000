import { computed, observable, runInAction } from "mobx";

import { DateTime } from "@bps/utils";
import { OrgUnitDto } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  AddressDto,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { findCommValue } from "@stores/practice/utils/practice.utils.ts";

import { addressText } from "./Address.ts";

export class OrgUnit extends Model<OrgUnitDto> {
  constructor(
    private root: IRootStore,
    dto: OrgUnitDto
  ) {
    super(dto);
  }

  @observable
  isDefaultLocation: boolean | undefined;

  public preloadIsDefaultLocation = async (orgUnitId: string) => {
    const pracOrgUnit = await this.root.practice.getOrgUnit(orgUnitId);
    if (pracOrgUnit) {
      runInAction(() => {
        this.isDefaultLocation = pracOrgUnit.isDefault;
      });
    }
  };
  @computed
  get hierarchyType() {
    return this.dto.hierarchyType;
  }

  @computed
  get name() {
    return this.dto.name;
  }

  @computed
  get isInactive() {
    return this.dto.isInactive;
  }

  @computed
  get tagLine() {
    return this.dto.tagLine;
  }

  @computed
  get timezone() {
    return this.dto.timeZone;
  }

  @computed
  get addresses() {
    return this.dto.addresses || [];
  }

  @computed
  get contact() {
    return this.dto.contact || [];
  }

  @computed
  get workPhone(): string | undefined {
    return findCommValue(this.contact, CommunicationType.WorkPhone);
  }

  @computed
  get firstAddress(): AddressDto | undefined {
    return this.addresses && this.addresses.length
      ? this.addresses[0]
      : undefined;
  }

  get address() {
    return this.firstAddress ? addressText(this.firstAddress) : undefined;
  }

  @computed
  get email(): string | undefined {
    return findCommValue(this.contact, CommunicationType.Email);
  }

  @computed
  get parentOrgUnitId() {
    return this.dto.parentOrgUnitId;
  }

  @computed
  get parentOrgUnit(): OrgUnit | undefined {
    return this.dto.parentOrgUnitId
      ? this.root.core.orgUnitsMap.get(this.dto.parentOrgUnitId)
      : undefined;
  }

  @computed
  get createdLocationDate() {
    return DateTime.fromISO(this.dto.changeLog.createdDate);
  }

  @computed
  get locationData() {
    return this.root.practice.allOrgUnitsLocationDataMap.get(this.id)
      ?.orgUnitLocationData;
  }
}
