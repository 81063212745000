import { computed } from "mobx";

import { DateTime, DEFAULT_CALENDAR_FORMATS } from "@bps/utils";
import { ClinicalTaskDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { ClinicalTaskStatus } from "@shared-types/clinical/clinical-task-status.enum.ts";

export class ClinicalTask extends Model<ClinicalTaskDto> {
  @computed get id() {
    return this.dto.id;
  }

  @computed get patientId() {
    return this.dto.patientId;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }
  @computed get taskType() {
    return this.dto.taskType;
  }

  @computed get dueDate() {
    return this.dto.dueDate;
  }

  @computed get dueInVisits() {
    return this.dto.dueInVisits;
  }

  @computed get remainingVisits() {
    return this.dto.remainingVisits;
  }

  @computed get priority() {
    return this.dto.priority;
  }

  @computed get comment() {
    return this.dto.comment;
  }

  @computed get isCompleted() {
    return this.dto.isCompleted;
  }

  @computed get completionNotes() {
    return this.dto.completionNotes;
  }

  @computed get isDeleted() {
    return this.dto.isDeleted;
  }

  @computed get isSystemGenerated() {
    return this.dto.isSystemGenerated;
  }

  @computed get deletedComment() {
    return this.dto.deletedComment;
  }

  @computed get lockedBy() {
    return this.dto.lockedBy;
  }

  @computed get islocked() {
    return !!this.dto.lockedBy;
  }

  @computed get isLocked() {
    return !!this.dto.lockedBy;
  }

  @computed get claimNumber() {
    return this.dto.claimNumber;
  }

  @computed get businessRole() {
    return this.dto.businessRole;
  }

  @computed get secGroupId() {
    return this.dto.secGroupId;
  }

  @computed get completedBy() {
    return this.dto.completedBy;
  }

  @computed get completedDate() {
    const date =
      DateTime.fromISO(this.dto.completedDate) ??
      DateTime.fromISO(this.dto.changeLog?.updatedDate);
    return date?.toFormat(DEFAULT_CALENDAR_FORMATS.LAST_DAY);
  }

  @computed get taskStatus() {
    if (!!this.dto.isCompleted) return ClinicalTaskStatus.Completed;

    if (!this.dto.dueDate && !this.dto.dueInVisits) return undefined;

    // Task Status by DueDate
    if (this.dto.dueDate) {
      const dueDate = DateTime.fromISO(this.dto.dueDate)
        .startOf("day")
        .toMillis();

      const currentDate = DateTime.today().toMillis();

      if (dueDate === currentDate) return ClinicalTaskStatus.Today;

      return dueDate > currentDate
        ? ClinicalTaskStatus.Upcoming
        : ClinicalTaskStatus.Overdue;
    }

    // Task status by DueInVisits
    const remainingVisits = this.dto.remainingVisits ?? 0;

    if (remainingVisits > 0) return ClinicalTaskStatus.Upcoming;
    if (remainingVisits === 0) return ClinicalTaskStatus.Today;
    if (remainingVisits < 0) return ClinicalTaskStatus.Overdue;
    return undefined;
  }
}
