import { AppointmentConfirmationCampaignDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class AppointmentConfirmationCampaign extends Model<AppointmentConfirmationCampaignDto> {
  get name(): string {
    return this.dto.name;
  }

  get newAppointmentCampaignId(): string {
    return this.dto.newAppointmentCampaignId;
  }

  get rescheduleAppointmentCampaignId(): string {
    return this.dto.rescheduleAppointmentCampaignId;
  }

  get cancelAppointmentCampaignId(): string {
    return this.dto.cancelAppointmentCampaignId;
  }
}
