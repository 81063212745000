import { computed } from "mobx";

import { PatientSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

type PatientSettingDtoExtended = PatientSettingDto & {
  id: string;
};

export class PatientSetting extends Model<PatientSettingDtoExtended> {
  constructor(
    private store: IRootStore,
    dto: PatientSettingDtoExtended
  ) {
    super(dto);
  }

  @computed
  get patientId() {
    return this.dto.patientId;
  }

  @computed
  get pronounMessage() {
    return this.dto.pronounMessage ?? false;
  }
}
