import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import {
  GstMethod,
  ServiceDto,
  ServiceInstanceDto,
  ServiceRuleType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class Service extends Model<ServiceDto> {
  constructor(
    private root: IRootStore,
    dto: ServiceDto
  ) {
    super(dto);
  }

  get instances() {
    return this.dto.instances;
  }

  get scheduleId() {
    return this.dto.scheduleId;
  }

  get code() {
    return this.dto.code;
  }

  get description() {
    return this.dto.description || "";
  }

  get name() {
    return this.dto.name || "";
  }

  get isService() {
    return this.dto.isService;
  }

  get feeType() {
    return this.dto.feeType;
  }

  get gstMethod() {
    return this.dto.gstMethod;
  }

  get benefitId() {
    return this.dto.benefitId;
  }

  get isActive() {
    return !!this.currentInstance?.isActive;
  }

  get gstIncluded() {
    return (
      this.gstMethod === GstMethod.included ||
      this.gstMethod === GstMethod.calculateRounded
    );
  }

  get startDate() {
    return this.instances[0].effectiveDate;
  }

  get endDate() {
    if (this.nextInstance) {
      if (!this.nextInstance.isActive) {
        return this.nextInstance.effectiveDate;
      }
    } else if (this.currentInstance && !this.currentInstance.isActive) {
      return this.currentInstance.effectiveDate;
    }

    return undefined;
  }

  get feeIsEditable() {
    return this?.currentInstance?.rules?.some(
      rule => rule.ruleType === ServiceRuleType.UserDefinedAmount
    );
  }

  @computed
  get currentInstanceIndex(): number {
    const localDateTime = DateTime.now().toISODate();

    const reversedIndex = Array.from(this.instances)
      .reverse()
      .findIndex(instance => {
        return (
          DateTime.fromISO(instance.effectiveDate) <=
          DateTime.fromISO(localDateTime)
        );
      });

    if (reversedIndex === -1) {
      return reversedIndex;
    }

    return this.instances.length - 1 - reversedIndex;
  }

  get currentInstance(): ServiceInstanceDto | undefined {
    return this.currentInstanceIndex === -1
      ? undefined
      : this.instances[this.currentInstanceIndex];
  }

  get nextInstance(): ServiceInstanceDto | undefined {
    if (this.currentInstanceIndex === -1) {
      return this.instances[0];
    }

    if (this.currentInstanceIndex < this.instances.length) {
      return this.instances[this.currentInstanceIndex + 1];
    }

    return undefined;
  }

  @computed
  get currentNextOrMostRecentActiveInstance() {
    if (this.currentInstance?.isActive) {
      return this.currentInstance;
    }

    if (this.nextInstance?.isActive) {
      return this.nextInstance;
    }

    return Array.from(this.instances)
      .reverse()
      .find(instance => instance.isActive);
  }

  @computed
  get lockedInstances() {
    return this.instances.filter(
      instance =>
        instance.effectiveDate !== this.currentInstance?.effectiveDate &&
        instance.effectiveDate !== this.nextInstance?.effectiveDate
    );
  }

  get displayDescription() {
    return this.name || this.description;
  }
}
