import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { IDocumentGateway } from "@libs/gateways/document/DocumentGateway.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class DocumentRef implements Ref<keyof DocumentRef> {
  constructor(private gateway: IDocumentGateway) {}

  reasonsForDeleteTemplate = new RefDataAccessor(() =>
    this.gateway.getReasonsForDeleteTemplate()
  );
}
