import { RefData } from "@libs/api/ref-data/RefData.ts";
import { Country } from "@libs/enums/country.enum.ts";
import { CommunicationKind } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  CommunicationDto,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { findCommValue } from "@stores/practice/utils/practice.utils.ts";

import { CoreStore } from "../CoreStore.ts";

export interface RefCommunicationType extends RefData<CommunicationType> {
  kind: CommunicationKind;
  preferredCommsOrder?: number;
  isRingable: boolean;
}

export function isPhone(core: CoreStore, comm?: CommunicationDto): boolean {
  return (
    !!comm &&
    !!comm.type &&
    core.ref.contactCommTypes.map.get(comm.type)?.kind ===
      CommunicationKind.Phone
  );
}

export const isRingable = (core: CoreStore, comm?: CommunicationDto) => {
  return (
    !!comm &&
    !!comm.type &&
    core.ref.contactCommTypes.map.get(comm.type)?.isRingable
  );
};

export function formatCommunication(
  comm: CommunicationDto,
  core: CoreStore
): CommunicationDto {
  const kind = core.ref.contactCommTypes.get(comm.type)?.kind;
  return {
    ...comm,
    value: kind
      ? formatCommunicationValue(
          comm.value,
          kind,
          core.tenantDetails && core.tenantDetails.country
        )
      : comm.value
  };
}

export function formatCommunicationValue(
  value: string,
  commKind: CommunicationKind,
  country?: Country
): string {
  switch (commKind) {
    case CommunicationKind.Phone: {
      return formatPhone(value, country);
    }
    default:
      return value;
  }
}

export function parseCommunication(value: string, commKind: CommunicationKind) {
  switch (commKind) {
    case CommunicationKind.Phone: {
      return parsePhone(value);
    }
    default:
      return value;
  }
}

export function parsePhone(value: string | undefined) {
  return value && value.replace(/ /g, "");
}

export function formatPhone(value: string, country?: Country) {
  if (!value) {
    return value;
  }

  const updatedValue = value.replace(/ /g, "");
  if (country === Country.Australia) {
    switch (updatedValue.length) {
      case 10: {
        return updatedValue.charAt(0) === "0"
          ? updatedValue.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3")
          : updatedValue.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
      }
      case 11: {
        return updatedValue.charAt(0) === "0"
          ? updatedValue.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3")
          : updatedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4");
      }
      case 12: {
        if (updatedValue.length === 12 && updatedValue.charAt(0) === "+") {
          return updatedValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{3})/,
            "$1 $2 $3 $4"
          );
        } else if (
          updatedValue.length === 12 &&
          updatedValue.charAt(0) === "6"
        ) {
          return updatedValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})/,
            "$1 $2 $3 $4"
          );
        } else if (
          updatedValue.length === 12 &&
          /\([\d]+\)/.test(updatedValue)
        ) {
          return updatedValue.replace(
            /\((\d{2})\)(\d{4})(\d{4})/,
            "($1) $2 $3"
          );
        } else {
          return value;
        }
      }
      default:
        return value;
    }
  }
  return value;
}

export const canReceiveForm = (array: CommunicationDto[] | undefined) => {
  const mobilePhone = findCommValue(array, CommunicationType.Mobile);
  const email = findCommValue(array, CommunicationType.Email);
  return email !== undefined || mobilePhone !== undefined;
};

function communicationRank(
  comms: { type: CommunicationType; preferred?: boolean },
  { prioritisePreferred }: { prioritisePreferred?: boolean } = {
    prioritisePreferred: true
  }
): number {
  if (prioritisePreferred && comms.preferred) {
    return 0;
  }

  // Phone is highest priority only for organisations, but only organisations
  // have phone as an option, thus it is highest
  switch (comms.type) {
    case CommunicationType.Phone:
      return 1;
    case CommunicationType.Mobile:
      return 2;
    case CommunicationType.HomePhone:
      return 3;
    case CommunicationType.WorkPhone:
      return 4;
    case CommunicationType.AfterHours:
      return 5;
    case CommunicationType.Email:
      return 6;
    case CommunicationType.Fax:
      return 7;
    case CommunicationType.Website:
      return 8;
    default:
      return 7;
  }
}

export const communicationComparer = (
  a: { type: CommunicationType },
  b: { type: CommunicationType }
) =>
  communicationRank(a, { prioritisePreferred: false }) -
  communicationRank(b, { prioritisePreferred: false });

export const communicationComparerWithPreference = (
  a: { type: CommunicationType; preferred?: boolean },
  b: { type: CommunicationType; preferred?: boolean }
) => communicationRank(a) - communicationRank(b);
