import { pickBy } from "lodash";
// eslint-disable-next-line import/extensions
import isEqualWith from "lodash/isEqualWith";
// eslint-disable-next-line import/extensions
import omitBy from "lodash/omitBy";

import { DATE_FORMATS, DateTime, unique, upsertItem } from "@bps/utils";
import {
  InvoiceItemDto,
  ServiceSearchDto,
  UpsertInvoiceItemNewDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  ClinicalDataElementBaseDto,
  ClinicalDataElementWithDeleteDto,
  ClinicalDataType,
  ClinicalNoteDataElement,
  ClinicalNoteFormat,
  ClinicalNoteSections,
  DischargeDataItemDto,
  DocumentDestinationType,
  EncounterClinicalNoteDto,
  FULLBODY_IMAGES,
  soapSections,
  sotapSections,
  StoreType,
  TodaysNotesHeading,
  TodaysNotesStructuredNote
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { getInvoiceItem } from "@stores/billing/utils/billing.utils.ts";
import { PatientClinicalRecordTab } from "@stores/clinical/models/clinical-tab/PatientClinicalRecordTab.ts";

export function getClinicalDataLastUpdatedDate(
  data?: ClinicalDataElementBaseDto
) {
  if (data?.updateLog) return DateTime.fromISO(data.updateLog.updatedDateTime);
  if (data?.createLog) return DateTime.fromISO(data.createLog.createdDateTime);
  return undefined;
}

export function getClinicalDataLastUpdatedUserId(
  data?: ClinicalDataElementBaseDto
) {
  if (data?.updateLog) return data.updateLog.updatedById;
  if (data?.createLog) return data.createLog.createdById;

  return undefined;
}

export interface ClinicalDataItemWithId
  extends ClinicalDataElementWithDeleteDto {
  id?: string;
}

interface DeleteItemFromClinicalDataArrayArgs<T> {
  array: T[];
  id: string;
  reasonForDelete?: string;
  deletedComment?: string;
}

/* Delete a single item based on the Id from an array of ClinicalDataItems */
export const deleteItemFromClinicalDataArray = <
  T extends ClinicalDataItemWithId
>(
  args: DeleteItemFromClinicalDataArrayArgs<T>
) => {
  const { array, id, reasonForDelete, deletedComment } = args;
  const item = array.find(x => x.id === id);
  if (item) {
    const deletedItem: ClinicalDataItemWithId = {
      ...item,
      isDeleted: true,
      reasonForDelete,
      deletedComment
    };
    return upsertItem({
      item: deletedItem,
      array,
      predicate: x => x.id === id
    }) as T[];
  } else {
    return array;
  }
};

type DeleteClinicalDataItemsType = {
  [id: string]: Pick<
    ClinicalDataElementWithDeleteDto,
    "reasonForDelete" | "deletedComment"
  >;
};

interface DeleteItemsFromClinicalDataArrayArgs<T> {
  array: T[];
  deleteItems: DeleteClinicalDataItemsType;
}

/**
 * Delete multiple items from a Clinical Data array
 * @example
 * const deleteditems = {
 *   [itemId1]: { reasonForDelete: "reason", deleteComment: "comment" },
 *   [itemId2]: { reasonForDelete: "reason", deleteComment: "comment" }
 * }
 * const medicalHistories = deleteItemsFromClinicalDataArray(medicalHistories, deletedItems);
 * @param args
 */
export const deleteItemsFromClinicalDataArray = <
  T extends ClinicalDataItemWithId
>(
  args: DeleteItemsFromClinicalDataArrayArgs<T>
) => {
  const { array, deleteItems } = args;
  const ids = Object.keys(deleteItems) || [];
  if (ids.length > 0) {
    return ids.reduce((arr, id) => {
      const { reasonForDelete, deletedComment } = deleteItems[id];

      return deleteItemFromClinicalDataArray({
        array: arr,
        id,
        reasonForDelete,
        deletedComment
      });
    }, array);
  }
  return array;
};

export const sortByDischargeItemCreatedDateDesc = (
  dataItem1: DischargeDataItemDto,
  dataItem2: DischargeDataItemDto
): number => {
  const createdDateTime1 = dataItem1.createLog?.createdDateTime;
  const createdDateTime2 = dataItem2.createLog?.createdDateTime;

  if (!createdDateTime1 && createdDateTime2) return -1;
  if (createdDateTime1 && !createdDateTime2) return 1;

  if (createdDateTime1 && createdDateTime2) {
    const date1 = DateTime.fromISO(createdDateTime1);
    const date2 = DateTime.fromISO(createdDateTime2);

    if (date1 > date2) return -1;
    if (date2 < date1) return 1;
  }

  return 0;
};
export const removeUndefinedProperties = (
  obj: Object | undefined = {},
  ignoredKeys?: string[]
) => {
  const objWithNestedCleared = { ...obj };

  Object.keys(obj).forEach(key => {
    if (
      typeof obj[key] === "object" &&
      typeof obj[key]?.getMonth !== "function" &&
      obj[key] !== null &&
      obj[key] !== undefined
    ) {
      const value = obj[key];
      if (Array.isArray(value)) {
        objWithNestedCleared[key] = removeUndefinedFromArrayObjects(
          value,
          ignoredKeys
        );
      } else {
        objWithNestedCleared[key] = removeUndefinedProperties(
          value,
          ignoredKeys
        );
      }
    }
  });

  const cleanedObj = pickBy(objWithNestedCleared, (v, key) => {
    if (ignoredKeys && ignoredKeys.length) {
      if (ignoredKeys.includes(key)) {
        return false;
      }
    }

    if (
      (typeof v === "string" || typeof v === "number") &&
      !isNaN(Number(v)) &&
      !Number(v)
    ) {
      return false;
    }

    if (Array.isArray(v) && !v.length) return false;

    return key === "observed" ? typeof v !== "undefined" && v !== null : !!v;
  });

  return Object.keys(cleanedObj)?.length > 0 ? cleanedObj : undefined;
};

export const removeUndefinedFromArrayObjects = (
  arr: any[],
  ignoredKeys?: string[]
): any[] => {
  return arr
    .map((x: any) => {
      if (typeof x === "object" && typeof x?.getMonth !== "function") {
        if (Array.isArray(x)) {
          return removeUndefinedFromArrayObjects(x, ignoredKeys);
        } else {
          return removeUndefinedProperties(x, ignoredKeys);
        }
      }
      return x;
    })
    .filter(x => !!x);
};

export const predicate = (value: any) =>
  !value || (Array.isArray(value) && value.length === 0);

// ⚠️ Shallow object customer!
export const isEqualCustomizer = (a: any, b: any) => {
  // compare ISO dates from different formats
  if (typeof a === "string" && typeof b === "string") {
    const aDateIsValid = DateTime.fromISO(a).isValid;
    const bDateIsValid = DateTime.fromISO(a).isValid;
    if (aDateIsValid && bDateIsValid) {
      const aDate = DateTime.fromISO(a)?.toISODate();
      const bDate = DateTime.fromISO(b)?.toISODate();
      return aDate === bDate;
    }
  }

  // compare JS dates by not checking time stamps
  if (a instanceof Date && b instanceof Date) {
    const aISOData = DateTime.jsDateToISODate(a);
    const bISOData = DateTime.jsDateToISODate(b);
    return aISOData === bISOData;
  }

  if (!a && !b) return true;

  return undefined;
};

export const getIsEqual = (
  originalValues: object | undefined,
  values: object | undefined,
  ignoredKeys?: string[]
) => {
  return isEqualWith(
    omitBy(removeUndefinedProperties(values, ignoredKeys), predicate),
    omitBy(removeUndefinedProperties(originalValues, ignoredKeys), predicate),
    isEqualCustomizer
  );
};

export const includeEncounterId = (encounterId?: string) => {
  return encounterId ? `_${encounterId}` : "";
};

export const clinicalRecordMapKey = (
  patientId: string,
  encounterId?: string
) => {
  return `${patientId}${includeEncounterId(encounterId)}`;
};

export const patientTabKey = (patientId: string, encounterId?: string) => {
  return `${patientId}${encounterId ?? ""}`;
};

export const getClinicalDocumentStoreString = (store: string) => {
  switch (store) {
    case StoreType.Investigations:
      return DocumentDestinationType.Investigations;
    case StoreType.Correspondence:
      return DocumentDestinationType.Correspondence;
    case StoreType.ClinicalImages:
      return DocumentDestinationType.ClinicalImages;
    case StoreType.Prescriptions:
      return DocumentDestinationType.Prescriptions;
    default:
      return undefined;
  }
};

export const getClinicalNoteFormat = (note: EncounterClinicalNoteDto) => {
  if (!note || !note.sectionElements || note.sectionElements.length === 0) {
    return ClinicalNoteFormat.Default;
  }
  //check sectionElements for all SOTAP sections
  if (
    sotapSections.every(
      s => note?.sectionElements?.map(o => o.sectionCode).includes(s)
    )
  ) {
    return ClinicalNoteFormat.SOTAP;
  }

  //check sectionElements for all SOAP sections
  if (
    soapSections.every(
      x => note?.sectionElements?.map(y => y.sectionCode).includes(x)
    )
  ) {
    return ClinicalNoteFormat.SOAP;
  }
  return ClinicalNoteFormat.Default;
};

const getSectionCodesByFormat = (
  clinicalNote: EncounterClinicalNoteDto,
  format: ClinicalNoteFormat
) => {
  const isDefault = format === ClinicalNoteFormat.Default;
  let sectionCodes: string[] = [];
  let sectionElementOrderedItems = [
    ClinicalNoteSections.Subjective,
    ClinicalNoteSections.Objective,
    ClinicalNoteSections.Treatment,
    ClinicalNoteSections.Analysis
  ];

  if (!isDefault) {
    sectionElementOrderedItems = sectionElementOrderedItems.concat([
      ClinicalNoteSections.Assessment,
      ClinicalNoteSections.Plan
    ]);
  }

  const sectionHeadings = unique(
    clinicalNote.sectionHeadingReferenceData
      ?.filter(e => !sectionElementOrderedItems.some(s => s === e.code))
      ?.sort((a, b) => a.order! - b.order!)
      ?.map(e => e.code)
  );

  const missingSectionElements = clinicalNote.sectionElements
    ?.filter(
      o =>
        o.sectionCode !== ClinicalNoteSections.Main &&
        sectionElementOrderedItems.some(s => s === o.sectionCode)
    )
    ?.map(x => x.sectionCode);

  sectionCodes = missingSectionElements
    ? missingSectionElements.concat(sectionHeadings)
    : sectionHeadings;

  return sectionCodes;
};

const generateDataStructuredNotes = (
  clinicalNote: EncounterClinicalNoteDto,
  sectionCode: string
) => {
  const dataStructuredNotes: TodaysNotesStructuredNote[] = [];
  const dataElements: ClinicalNoteDataElement[] =
    clinicalNote.clinicalDataElements?.filter(
      e => e.sectionCode === sectionCode
    );

  if (dataElements?.length > 0) {
    dataElements.forEach(e => {
      // skip over elements with empty text
      // unless the heading code is full body and the dataElements also contains image data
      // this is OK if no fully body comment has been supplied but image data has
      if (
        (e.headingCode === ClinicalDataType.FullBody &&
          dataElements.findIndex(e => e.headingCode === FULLBODY_IMAGES) !==
            -1) ||
        e.text !== "<p></p>"
      ) {
        const dataName =
          clinicalNote.clinicalDataHeadingReferenceData?.find(
            i => i.code === e.headingCode
          )?.text ?? "";

        const index = dataStructuredNotes.findIndex(n => n.type === dataName);
        if (index > -1) {
          dataStructuredNotes![index].note =
            dataStructuredNotes![index].note + e.text;
        } else {
          const newStructureNode = {
            sectionCode: e.sectionCode,
            note: e.text,
            type: dataName,
            order: index
          };
          dataStructuredNotes.push(newStructureNode);
        }
      }
    });
  }
  return dataStructuredNotes;
};

type getSectionNameByFormatProps = {
  clinicalNote: EncounterClinicalNoteDto;
  sectionCode: string;
  format: string;
  clinicalNoteSections: {
    key: string;
    name: string;
  }[];
};

const getSectionNameByFormat = (props: getSectionNameByFormatProps) => {
  const { clinicalNote, sectionCode, format, clinicalNoteSections } = props;
  let sectionName;
  if (format === ClinicalNoteFormat.Default) {
    sectionName =
      clinicalNote.sectionHeadingReferenceData?.find(
        sec => sec.code === sectionCode
      )?.text ?? "";
  } else {
    sectionName =
      clinicalNoteSections?.find(x => x.key === sectionCode)?.name ?? "";
  }
  return sectionName;
};

export const toStructuredNotes = (
  clinicalNote: EncounterClinicalNoteDto,
  currentPatientRecordTab: PatientClinicalRecordTab | undefined,
  clinicalNoteSections: {
    key: string;
    name: string;
  }[]
) => {
  const todaysNotes: TodaysNotesHeading[] = [];
  const tab = currentPatientRecordTab;
  const format = getClinicalNoteFormat(clinicalNote);
  const sectionCodes: string[] = getSectionCodesByFormat(clinicalNote, format);

  sectionCodes?.forEach((sectionCode, index) => {
    const sectionFreeText =
      clinicalNote.sectionElements?.find(sec => sec.sectionCode === sectionCode)
        ?.text ?? "";

    const sectionName = getSectionNameByFormat({
      clinicalNote,
      sectionCode,
      format,
      clinicalNoteSections
    });

    const clinicalNoteSectionCodes: string[] = [
      ClinicalNoteSections.Subjective,
      ClinicalNoteSections.Objective,
      ClinicalNoteSections.Treatment,
      ClinicalNoteSections.Analysis,
      ClinicalNoteSections.Plan,
      ClinicalNoteSections.Assessment
    ];

    const dataStructuredNotes: TodaysNotesStructuredNote[] =
      generateDataStructuredNotes(clinicalNote, sectionCode);
    if (
      dataStructuredNotes.length > 0 ||
      clinicalNoteSectionCodes.includes(sectionCode)
    ) {
      const heading: TodaysNotesHeading = {
        name: sectionName,
        order: index,
        code: sectionCode,
        freeText: sectionFreeText,
        structuredNotes: dataStructuredNotes
      };
      tab?.setTodaysNotesUserSettingsIfNotDefined(heading, {
        showImage: true
      });
      todaysNotes.push(heading);
    }
  });

  return todaysNotes;
};

export const convertUtcToLocal = (utcTime: string) => {
  const utcToLocal = DateTime.fromISO(utcTime);

  if (!utcToLocal.isValid) {
    return "No date";
  }

  if (utcToLocal.day.toString())
    return utcToLocal.toFormat(DATE_FORMATS.DAY_TEXT_MONTH_YEAR);

  if (utcToLocal.month.toString())
    return utcToLocal.toFormat(DATE_FORMATS.MONTH_TEXT_FORMAT);

  return utcToLocal.toFormat(DATE_FORMATS.YEAR_FORMAT);
};
export const mapToInvoiceItems = (options: {
  services: ServiceSearchDto[];
  gstPercent: number;
  serviceDate?: string;
  baseProps: Pick<
    InvoiceItemDto,
    | "userId"
    | "calendarEventId"
    | "user"
    | "patient"
    | "patientId"
    | "itemType"
    | "accountId"
    | "locationId"
  >;
}): UpsertInvoiceItemNewDto[] => {
  const { services, gstPercent, serviceDate, baseProps } = options;
  const results = services.map(service =>
    getInvoiceItem({
      service,
      gstPercent,
      serviceDate: serviceDate
        ? DateTime.jsDateFromISO(serviceDate)
        : DateTime.jsDateNow()
    })
  );

  return results.map(invoiceItem => {
    const { serviceId, serviceDate, quantity, fee, gst, total } = invoiceItem;
    return {
      serviceId,
      quantity: Number(quantity),
      fee: Number(fee),
      gst: Number(gst),
      amount: Number(total),
      serviceDate: DateTime.jsDateToISODate(serviceDate) || "",
      ...baseProps
    };
  });
};
