import { computed } from "mobx";

import { InvoiceSettingsDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class InvoiceSettings extends Model<InvoiceSettingsDto> {
  @computed
  get footer() {
    return this.dto.footer;
  }

  @computed
  get alwaysApplyGst(): boolean {
    return this.dto.alwaysApplyGst;
  }

  @computed
  get gstPercent(): number {
    return this.dto.gstPercent;
  }

  @computed
  get business() {
    return this.dto.business;
  }

  @computed
  get abn() {
    return this.business?.abn || "";
  }

  @computed
  get gstNumber() {
    return this.business?.gstNumber || "";
  }

  @computed
  get bankDetails() {
    return this.dto.business?.bankDetails;
  }

  @computed
  get statementFooter() {
    return this.dto.statementFooter;
  }

  @computed
  get bankDetailsUnderStatementFooter() {
    return !!this.dto.bankDetailsUnderStatementFooter;
  }

  get bankDetailsUnderInvoiceFooter() {
    return !!this.dto.bankDetailsUnderInvoiceFooter;
  }
}
