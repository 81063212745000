import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import {
  AppointmentReminderJobDto,
  AppointmentReminderJobOptionsDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import {
  CronDto,
  CronTabHelper
} from "@stores/booking/models/CronTabHelper.ts";

export class AppointmentReminderJob extends Model<AppointmentReminderJobDto> {
  get orgUnitId(): string {
    return this.dto.orgUnitId;
  }

  get jobName(): string {
    return this.dto.jobName;
  }

  get type(): string {
    return "Appointment reminder"; // Hard code this as this will ALWAYS be Appointment for now.
  }

  get jobSchedule(): string {
    return this.cron.days;
  }

  get time(): string {
    return this.cron.time;
  }

  get appointmentReminderOptions(): AppointmentReminderJobOptionsDto {
    return this.dto.appointmentReminderOptions;
  }

  get status(): string {
    return this.dto.status;
  }

  get cron(): CronDto {
    return CronTabHelper.processCronTab(this.dto.jobSchedule);
  }

  @computed
  get scheduleTime() {
    const hour = this.dto.jobSchedule.hour;
    const minute = this.dto.jobSchedule.minute;
    return DateTime.now().set({ hour, minute });
  }
}
