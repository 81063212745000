import { action, observable, runInAction } from "mobx";

import { FormDesignUpdateDTO } from "@libs/gateways/form-design/FormDesignGateway.dtos.ts";

export interface FormDesignModalConfig {
  id?: string;
  duplicateDesign?: boolean;
}

export class FormDesignUi {
  @observable
  showFormDesignListModal: boolean;

  @observable
  showFormDesignPreviewQR: boolean;

  @observable formDesignVisible: boolean;

  formDesignModalConfig?: FormDesignModalConfig;

  @observable showClinicalModal?: boolean;
  @observable showFormDesignPreview: FormDesignUpdateDTO | undefined;
  @observable
  showDeleteConfirmationModel: FormDesignUpdateDTO | undefined;

  @action
  public setShowFormDesignListModal(value: boolean) {
    runInAction(() => {
      this.showFormDesignListModal = value;
    });
  }

  @action
  public setShowDeleteConfirmation(value?: FormDesignUpdateDTO) {
    runInAction(() => {
      this.showDeleteConfirmationModel = value;
    });
  }
  @action
  public setFormDesignPreview(
    value: FormDesignUpdateDTO | undefined,
    qr?: boolean
  ) {
    runInAction(() => {
      this.showFormDesignPreview = value;
      this.showFormDesignPreviewQR = !!qr;
    });
  }

  @action
  public setShowFormDesignModal(config?: FormDesignModalConfig) {
    runInAction(() => {
      this.formDesignModalConfig = config;
      this.formDesignVisible = !!config;
    });
  }
  @action
  public setShowClinicalModal(value?: boolean) {
    runInAction(() => {
      this.showClinicalModal = value;
    });
  }
}
