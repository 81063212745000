import {
  RefundDto,
  RefundItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";

import { Transaction } from "./Transaction.ts";

export class Refund extends Transaction<RefundDto, RefundItemDto> {
  get viewPermission() {
    return Permission.CreditAllowed;
  }

  get viewLink() {
    return routes.accounts.refund;
  }
}
