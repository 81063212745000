import { DateTime } from "@bps/utils";
import { ClaimEpisodeOfCareDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { EpisodeOfCareDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class Condition extends Model<EpisodeOfCareDto> {
  constructor(
    private root: IRootStore,
    dto: EpisodeOfCareDto
  ) {
    super(dto);
  }

  private _claimEpisodeOfCare: ClaimEpisodeOfCareDto | undefined;

  public set claimEpisodeOfCare(
    claimEpisodeOfCare: ClaimEpisodeOfCareDto | undefined
  ) {
    this._claimEpisodeOfCare = claimEpisodeOfCare;
  }

  public get patientId() {
    return this.dto.patientId;
  }

  public get primaryDiagnosis() {
    return (
      this.dto.diagnoses?.find(x => x.isPrimaryDiagnosis)?.diagnosisCode
        ?.originalText ?? "Undiagnosed"
    );
  }

  public get diagnoses() {
    return this.dto.diagnoses || [];
  }

  public get injuryDate() {
    return DateTime.fromISO(this.dto.injuryDate);
  }

  public get referralProvider() {
    return this.dto.referralProvider;
  }

  public get referralDate() {
    return DateTime.fromISO(this.dto.referralDate);
  }

  public get referralNumber() {
    return this.dto.referralNumber;
  }

  public get isReferral() {
    return this.dto.isReferral;
  }

  public get discharged() {
    return this.dto.discharged;
  }

  public get isPrivate() {
    return !this.claimEpisodeOfCare || !this.claimEpisodeOfCare?.isActive;
  }

  public get createdDate() {
    return DateTime.fromISO(this.dto.changeLog?.createdDate)!;
  }

  public get claimEpisodeOfCare() {
    return this._claimEpisodeOfCare;
  }

  public get inActiveClaim() {
    if (
      this._claimEpisodeOfCare?.claimId &&
      !this._claimEpisodeOfCare.isActive
    ) {
      return this.root.acc.claimsMap.get(this._claimEpisodeOfCare?.claimId);
    }
    return undefined;
  }

  public get claim() {
    if (
      !this._claimEpisodeOfCare?.claimId ||
      !this._claimEpisodeOfCare.isActive
    ) {
      return undefined;
    }
    return this.root.acc.claimsMap.get(this._claimEpisodeOfCare?.claimId);
  }

  public get patient() {
    return this.root.practice.contactsMap.get(this.patientId);
  }

  private get futureCalendarEvents() {
    return Array.from(this.root.booking.calendarEventsMap.values()).filter(
      x =>
        x.startDateTime &&
        !x.startDateTime.isBeforeToday &&
        x.reason?.episodeOfCareId === this.id
    );
  }

  public get nextCalendarEventDate() {
    if (this.futureCalendarEvents.length) {
      return this.futureCalendarEvents[0].startTime;
    } else {
      return undefined;
    }
  }

  public get nextCalendarEventProviderId() {
    if (this.futureCalendarEvents.length) {
      return this.futureCalendarEvents[0].userId;
    }
    return undefined;
  }

  public get initialCalendarEvent() {
    const calendarEvent = Array.from(
      this.root.booking.calendarEventsMap.values()
    ).filter(x => x.reason?.episodeOfCareId === this.id);

    if (calendarEvent.length) {
      return calendarEvent[0];
    } else {
      return undefined;
    }
  }

  public getNextCalendarEventProviderName = async () => {
    if (this.futureCalendarEvents.length) {
      const user = await this.root.core.getUser(
        this.futureCalendarEvents[0].userId
      );
      return user.fullName;
    }
    return undefined;
  };

  public async loadClaimEpisodeOfCare() {
    const claimEpisodeOfCare = await this.root.acc.getClaimEpisodesOfCare({
      episodesOfCare: [this.id]
    });
    if (claimEpisodeOfCare.length) {
      this._claimEpisodeOfCare = claimEpisodeOfCare[0];
    }
  }

  public async loadClaim() {
    if (!this.root.core.hasPermissions(Permission.ClaimRead)) return;
    await this.loadClaimEpisodeOfCare();
    if (this._claimEpisodeOfCare?.claimId) {
      await this.root.acc.getClaim(this._claimEpisodeOfCare?.claimId);
    }
  }

  public loadCalendarEvent = async () => {
    await this.root.booking.getCalendarEvents({
      attendees: [this.patientId]
    });
  };

  public async loadPatient() {
    await this.root.practice.getContact(this.patientId);
  }
}
