import { computed } from "mobx";

import { TextBadgeColor } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  BillingStatuses,
  TransactionBaseDto,
  TransactionDto,
  TransactionIndividualDto,
  TransactionItemDto,
  TransactionOrganisationDto,
  TransactionPatientDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IdParam, RouteInfo } from "@libs/routing/routes.ts";
import { BillingRefKeyCodeAccessor } from "@stores/billing/BillingRef.ts";
import { addressText } from "@stores/core/models/Address.ts";

import { billingStatusColours, itemTypeColours } from "./Transaction.types.ts";

export type TransactionBase = Transaction<
  TransactionBaseDto,
  TransactionItemDto
>;

export class Transaction<
  TDto extends TransactionDto<TItem>,
  TItem extends TransactionItemDto
> extends Model<TDto> {
  protected _getStatusColour() {
    if (this.status === BillingStatuses.current) {
      return itemTypeColours[this.itemType];
    }

    return billingStatusColours[this.status];
  }

  get items(): TItem[] {
    return this.dto.items;
  }

  get number() {
    return this.dto.number;
  }

  get accountId() {
    return this.dto.accountId;
  }

  get accountName() {
    return this.accountContactOrganisation?.name;
  }

  @computed
  get accountFirstName() {
    const contact = this.accountContactPatient || this.accountContactIndividual;
    return contact?.firstName;
  }

  @computed
  get accountLastName() {
    const contact = this.accountContactPatient || this.accountContactIndividual;
    return contact?.lastName;
  }

  @computed
  get accountAddress() {
    return this.dto.accountContact?.address;
  }

  @computed
  get accountAddressString(): string {
    return this.accountAddress ? addressText(this.accountAddress) : "";
  }

  @computed
  get accountPhone() {
    return this.dto.accountContact?.phone;
  }

  get accountContactType() {
    return this.dto.accountContact?.contactType;
  }

  @computed
  get accountContactName() {
    if (this.accountContactOrganisation) {
      return this.accountContactOrganisation.name;
    } else {
      const contact =
        this.accountContactPatient || this.accountContactIndividual;
      return `${contact?.firstName} ${contact?.lastName}`;
    }
  }

  get accountContactPatient() {
    return this.accountContact.contactType === ContactType.Patient
      ? (this.accountContact as TransactionPatientDto)
      : undefined;
  }

  get accountContactIndividual() {
    return this.accountContact.contactType === ContactType.Individual
      ? (this.accountContact as TransactionIndividualDto)
      : undefined;
  }

  get accountContactOrganisation() {
    return this.accountContact.contactType === ContactType.Organisation
      ? (this.accountContact as TransactionOrganisationDto)
      : undefined;
  }

  get accountContact() {
    return this.dto.accountContact;
  }

  get itemType() {
    return this.dto.itemType;
  }

  get transactionDate() {
    return DateTime.jsDateFromISO(this.dto.transactionDate);
  }

  get changedDate() {
    return DateTime.jsDateFromISO(this.dto.changedDate);
  }

  get location() {
    return this.dto.location;
  }

  get status() {
    return this.dto.status;
  }

  get comment() {
    return this.dto.comment;
  }

  get reference() {
    return this.dto.reference;
  }

  get adjustmentReason() {
    return this.dto.adjustmentReason;
  }

  get total() {
    return this.dto.total;
  }

  get changeLog() {
    return this.dto.changeLog;
  }

  get itemTypeText(): BillingRefKeyCodeAccessor {
    return { key: "itemTypes", code: this.dto.itemType };
  }

  public get isCancelledOrAdjusted() {
    return this.status !== BillingStatuses.current;
  }
  public get isAdjusted() {
    return this.status === BillingStatuses.adjusted;
  }

  public get isCancelled() {
    return this.status === BillingStatuses.cancelled;
  }

  get viewPermission(): string | string[] | undefined {
    return Permission.CreditAllowed;
  }

  get viewLink(): RouteInfo<IdParam> | undefined {
    return undefined;
  }

  get statusColour() {
    return this._getStatusColour();
  }

  public get statusText(): BillingRefKeyCodeAccessor {
    if (this.status === BillingStatuses.current) {
      return this.itemTypeText;
    } else {
      return { key: "billingStatuses", code: this.dto.status };
    }
  }

  get adjustmentReasonText(): BillingRefKeyCodeAccessor | string | undefined {
    return this.adjustmentReason;
  }

  getItemText(id: string): BillingRefKeyCodeAccessor {
    const item = this.items.find(x => x.id === id);
    return { key: "itemTypes", code: item?.itemType || "" };
  }

  getItemStatusText = (id: string) => {
    const item = this.items.find(x => x.id === id);
    if (item?.status === BillingStatuses.current) {
      return this.itemTypeText;
    } else {
      return { key: "billingStatuses", code: item?.status };
    }
  };

  getItemStatusColour(id: string): TextBadgeColor {
    const item = this.items.find(x => x.id === id);
    if (item?.status === BillingStatuses.current) {
      return itemTypeColours[item?.itemType || ""];
    }

    return billingStatusColours[item?.status || ""];
  }

  getStatusIcon(): string {
    if (this?.status === BillingStatuses.cancelled) {
      return "Remove";
    } else if (this?.status === BillingStatuses.adjusted) {
      return "PageEdit";
    } else {
      return "Completed";
    }
  }

  getStatusTooltip(): string {
    return "";
  }
}
