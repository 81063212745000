import { BhacStore } from "stores/bhac/BhacStore.ts";

import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccStore } from "@stores/acc/AccStore.ts";
import { BhbStore } from "@stores/bhb/BhbStore.ts";
import { BillingStore } from "@stores/billing/BillingStore.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { CorrespondenceStore } from "@stores/clinical/CorrespondenceStore.ts";
import { CommsStore } from "@stores/comms/CommsStore.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { CustomIntegrationsStore } from "@stores/custom-integration/CustomIntegrationsStore.ts";
import { DocumentStore } from "@stores/documents/DocumentStore.ts";
import { DrugsStore } from "@stores/drugs/DrugsStore.ts";
import { FormDesignStore } from "@stores/form-design/FormDesignStore.ts";
import { FormsStore } from "@stores/forms/FormsStore.ts";
import { InboxDocumentsStore } from "@stores/inbox/InboxDocumentsStore.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { ReportsStore } from "@stores/reports/ReportsStore.ts";
import { RouterStore } from "@stores/router/RouterStore.ts";
import { TemplateManagementStore } from "@stores/template-management/TemplateManagementStore.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import { NotificationsStore } from "../notifications/NotificationsStore.ts";

export class RootStore implements IRootStore {
  // eslint-disable-next-line max-params
  constructor(
    public acc: AccStore,
    public routing: RouterStore,
    public core: CoreStore,
    public practice: PracticeStore,
    public billing: BillingStore,
    public booking: BookingStore,
    public clinical: ClinicalStore,
    public notification: NotificationsStore,
    public drugs: DrugsStore,
    public document: DocumentStore,
    public userExperience: UserExperienceStore,
    public correspondence: CorrespondenceStore,
    public inbox: InboxDocumentsStore,
    public reports: ReportsStore,
    public comms: CommsStore,
    public forms: FormsStore,
    public templateManagement: TemplateManagementStore,
    public bhb: BhbStore,
    public bhac: BhacStore,
    public formDesign: FormDesignStore,
    public customIntegrations: CustomIntegrationsStore
  ) {
    const stores = [
      acc,
      routing,
      core,
      practice,
      billing,
      booking,
      clinical,
      notification,
      drugs,
      document,
      userExperience,
      correspondence,
      inbox,
      reports,
      comms,
      forms,
      templateManagement,
      bhb,
      bhac,
      formDesign,
      customIntegrations
    ];
    stores
      .filter(store => "root" in store)
      .forEach(store => {
        (store as any).root = this;
      });

    stores
      .filter(store => "afterAttachRoot" in store)
      .forEach(store => {
        (store as any).afterAttachRoot();
      });
  }

  /**
   * This gets called when the user is authenticated
   * It is preferable to preload some of them, this is where
   * it happens.
   *
   */
  preloadRefData = () => {
    return Promise.all<any>([
      this.core.ref.titles.load(),
      this.core.ref.contactCommTypes.load(),
      this.core.ref.countries.load(),
      this.practice.ref.addressTypes.load(),
      this.core.ref.australianStates.load(),
      this.practice.ref.contactTypes.load(),
      this.practice.ref.relationshipStatuses.load(),
      this.practice.ref.occupations.load(),
      this.practice.ref.relationshipTypes.load(),
      this.practice.ref.dvaCardColors.load(),
      this.practice.ref.genders.load(),
      this.practice.ref.pronouns.load(),
      this.practice.ref.sexes.load(),
      this.practice.ref.languages.load(),
      this.practice.ref.ethnicities.load()
    ]);
  };
}
