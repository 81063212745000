import { observable, runInAction } from "mobx";

import { DateTime, TIME_FORMATS } from "@bps/utils";
import { AvailabilitySlotResponseDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export class AvailabilitySlots {
  constructor(
    private _dto: AvailabilitySlotResponseDto[],
    private _providerId: string
  ) {
    runInAction(() => {
      this.dto = this._dto;
    });
  }

  @observable.ref
  dto: AvailabilitySlotResponseDto[];

  get providerId() {
    return this._providerId;
  }

  get hasValue(): boolean {
    return this.dto?.length > 0;
  }

  public getSelectedDateAvailabilitySlots(
    selectedDate: DateTime,
    todaysDate: Date,
    orgUnitId: string
  ): AvailabilitySlot[] {
    const slots: AvailabilitySlot[] = [];
    const today = DateTime.fromJSDate(todaysDate).startOf("day");

    const time = today.toFormat(TIME_FORMATS.TIME_FORMAT_24);

    const selectedDateInMills = selectedDate.startOf("day").toMillis();

    const filterSlotsRespond = (slots: AvailabilitySlotResponseDto[]) =>
      slots.filter(
        x =>
          x.orgUnitId === orgUnitId &&
          DateTime.fromISO(x.date).startOf("day").toMillis() ===
            selectedDateInMills
      );

    if (selectedDateInMills === today.toMillis()) {
      filterSlotsRespond(this.dto).forEach(i => {
        i.availableTimes.forEach(j => {
          const hour = j.substring(0, 2);
          if (hour > time.substring(0, 2)) {
            slots.push({
              availableTime: j
            });
          } else if (hour === time.substring(0, 2)) {
            const min = j.substring(3, 5);
            if (min >= time.substring(3, 5)) {
              slots.push({
                availableTime: j
              });
            }
          }
        });
      });
    } else {
      filterSlotsRespond(this.dto).forEach(i => {
        i.availableTimes.forEach(j =>
          slots.push({
            availableTime: j
          })
        );
      });
    }
    return slots;
  }

  public isSlotsAvailable(selectedDate: DateTime): boolean {
    const availability = this.dto.filter(x =>
      DateTime.fromISO(x.date.substring(0, 10)).equals(
        selectedDate.startOf("day")
      )
    );
    return availability.length > 0 && availability[0].availableTimes.length > 0;
  }

  public getFirstSlotFromSelectedDate = (date: DateTime, orgUnitId: string) => {
    if (this.dto.length > 0) {
      const slotsFromSelectedDate = this.dto.filter(item => {
        if (orgUnitId !== item.orgUnitId) return false;

        const itemDate = DateTime.fromISO(item.date);
        return itemDate >= date;
      });
      if (slotsFromSelectedDate.length > 0) return slotsFromSelectedDate[0];
      else return undefined;
    } else return undefined;
  };

  public getUnavailableDates(referenceDate: Date): Date[] {
    const slots: Date[] = [];

    let from = DateTime.fromJSDate(referenceDate);
    const to = DateTime.fromJSDate(referenceDate)
      .plus({ months: 3 })
      .startOf("day");

    const { minDate, maxDate } = this.boundaryDates || {};

    if (!minDate || !maxDate) return [];

    do {
      if (!this.isSlotsAvailable(from)) {
        const isDateWithinBoundary = from >= minDate && from <= maxDate;

        if (isDateWithinBoundary) {
          slots.push(from.toJSDate());
        }
      }
      from = from.plus({ days: 1 }).startOf("day");
    } while (from <= to);

    return slots;
  }

  get boundaryDates() {
    const availableDates = this.dto.map(x =>
      DateTime.fromISO(x.date).startOf("day")
    );

    if (availableDates.length === 0) return undefined;

    const maxDate = availableDates.reduce((prevVal, currValue) =>
      prevVal >= currValue ? prevVal : currValue
    );

    const minDate = availableDates.reduce((prevVal, currValue) =>
      prevVal <= currValue ? prevVal : currValue
    );

    return { maxDate, minDate };
  }
}

export interface SlotGroup {
  time: AvailabilitySlot[];
}
export interface AvailabilitySlot {
  availableTime: string;
}
