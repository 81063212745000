import { CalendarEventReminderMessageDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class CalendarEventReminderMessage extends Model<CalendarEventReminderMessageDto> {
  get calendarEventReminderId() {
    return this.dto.calendarEventReminderId;
  }

  get content() {
    return this.dto.content;
  }

  get deliveryStatusCode() {
    return this.dto.deliveryStatusCode;
  }

  get deliveryStatusUpdatedDateTime() {
    return this.dto.deliveryStatusUpdatedDateTime;
  }

  get deliveryChannelCode() {
    return this.dto.deliveryChannelCode;
  }

  get outboundCommContentId() {
    return this.dto.outboundCommContentId;
  }

  get deliveryAddress() {
    return this.dto.deliveryAddress;
  }

  get contactHasOptedOut() {
    return this.dto.contactHasOptedOut;
  }

  get changeLog() {
    return this.dto.changeLog;
  }
}
