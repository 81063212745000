import { computed } from "mobx";

import { AppointmentTypeDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class AppointmentType extends Model<AppointmentTypeDto> {
  @computed
  get name() {
    return this.dto.text;
  }

  @computed
  get onlineName() {
    return this.dto.onlineName;
  }

  @computed
  get duration() {
    return this.dto.length;
  }

  @computed
  get isInactive() {
    return !!this.dto.isInactive;
  }

  @computed
  get icon() {
    return this.dto.icon;
  }

  @computed
  get defaultServiceIds() {
    return this.dto.defaultServiceIds || [];
  }

  @computed
  get isSystemManaged() {
    return this.dto.isSystemManaged;
  }

  @computed
  get code() {
    return this.dto.code;
  }

  @computed
  get text() {
    return this.dto.text;
  }

  @computed
  get maxParticipants() {
    return this.dto.maxParticipants;
  }

  @computed
  get isGroupAppointmentType() {
    return !!this.maxParticipants && this.maxParticipants > 1;
  }

  @computed
  get isInternalStatic() {
    return this.dto.isInternalStatic;
  }
}
