import { computed } from "mobx";

import { to2dp } from "@bps/utils";
import { AccountBalanceDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { ModelBase } from "@libs/models/Model.ts";

export class AccountBalance extends ModelBase<AccountBalanceDto> {
  get accountId(): string {
    return this.dto.accountId;
  }

  @computed
  get unallocatedCredit(): number {
    return to2dp(this.dto.unallocatedCredit);
  }

  @computed
  get totalOwing(): number {
    return to2dp(this.dto.totalOwing);
  }
}
