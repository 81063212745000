import { computed } from "mobx";

import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  PronounCodes,
  RefPronounDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";

export class PronounTypeRefDataAccessor extends RefDataAccessor<
  PronounCodes,
  RefPronounDto
> {
  @computed
  get keyNameValuesWithContructedPronounSubjective() {
    return this.values.map(({ subjective, objective, possessive }) => {
      const text = this.getComputedPronoun(subjective, objective, possessive);
      return {
        key: subjective,
        text
      };
    });
  }

  @computed
  get keyNameValuesWithContructedPronounobjective() {
    return this.values.map(({ subjective, objective, possessive }) => ({
      key: objective,
      text: this.getComputedPronoun(subjective, objective, possessive)
    }));
  }

  @computed
  get keyNameValuesWithContructedPronounpossessive() {
    return this.values.map(({ subjective, objective, possessive }) => ({
      key: possessive,
      text: this.getComputedPronoun(subjective, objective, possessive)
    }));
  }

  private getComputedPronoun = (
    subjective: string,
    objective: string,
    possessive: string
  ) => {
    return `${subjective} / ${objective} / ${possessive}`;
  };
}
