import { computed } from "mobx";

import { BhbProviderDto } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class BhbProvider extends Model<BhbProviderDto> {
  @computed
  get title() {
    return this.dto.title;
  }
  @computed
  get firstName() {
    return this.dto.firstName;
  }
  @computed
  get lastName() {
    return this.dto.lastName;
  }
  @computed
  get displayName() {
    return this.dto.displayName;
  }
  @computed
  get appointmentTypes() {
    return this.dto.appointmentTypes;
  }
  @computed
  get language() {
    return this.dto.language;
  }
  @computed
  get areaOfInterest() {
    return this.dto.areaOfInterest;
  }
  @computed
  get genderIdentity() {
    return this.dto.genderIdentity;
  }
  @computed
  get profileDetail() {
    return this.dto.profileDetail;
  }
  @computed
  get photoUrl() {
    return this.dto.photoUrl;
  }
  @computed
  get providerOnlineStatus() {
    return this.dto.providerOnlineStatus;
  }
}
