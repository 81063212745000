import { TextBadgeColor } from "@bps/fluent-ui";
import {
  BillingStatuses,
  ItemType,
  PaymentStatuses
} from "@libs/gateways/billing/BillingGateway.dtos.ts";

export const paymentStatusColours = {
  [PaymentStatuses.paid]: TextBadgeColor.green,
  [PaymentStatuses.unpaid]: TextBadgeColor.red,
  [PaymentStatuses.part]: TextBadgeColor.red,
  [PaymentStatuses.overpaid]: TextBadgeColor.grey,
  [PaymentStatuses.cancelled]: TextBadgeColor.grey
};

export const billingStatusColours = {
  [BillingStatuses.cancelled]: TextBadgeColor.grey,
  [BillingStatuses.adjusted]: TextBadgeColor.yellow,
  [BillingStatuses.current]: TextBadgeColor.grey
};

export const itemTypeColours = {
  [ItemType.WriteOff]: TextBadgeColor.lightGrey,
  [ItemType.CreditNote]: TextBadgeColor.blue,
  [ItemType.Payment]: TextBadgeColor.green,
  [ItemType.Refund]: TextBadgeColor.lightGrey,
  [ItemType.Allocation]: TextBadgeColor.green
};
