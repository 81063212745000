import { computed } from "mobx";

import { UserSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

type UserSettingDtoExtended = UserSettingDto & {
  id: string;
};

export class UserSetting extends Model<UserSettingDtoExtended> {
  constructor(
    private store: IRootStore,
    dto: UserSettingDtoExtended
  ) {
    super(dto);
  }

  @computed
  get userName() {
    return this.store.core.userMap.get(this.dto.userId)?.name;
  }

  @computed
  get userId() {
    return this.dto.userId;
  }

  @computed
  get patientSummaryPage() {
    return this.dto.clinicalView?.patientSummaryPage ?? [];
  }

  @computed
  get clinicalTaskSettings() {
    return this.dto.clinicalTaskSettings?.value ?? [];
  }

  @computed
  get showOnCalendar() {
    return this.dto.showOnCalendar ?? false;
  }

  @computed
  get clinicalView() {
    if (this.dto.clinicalView !== undefined) {
      this.dto.clinicalView!.patientSummaryPage = this.patientSummaryPage;
      this.dto.clinicalView!.defaultDockView =
        this.dto.clinicalView!.defaultDockView;
      this.dto.clinicalView!.selectedTreeView =
        this.dto.clinicalView.selectedTreeView;
      this.dto.clinicalView!.onlyShowMyNotes =
        this.dto.clinicalView.onlyShowMyNotes;
      this.dto.clinicalView!.pastVisitsReasonAtBottom =
        this.dto.clinicalView.pastVisitsReasonAtBottom;
      this.dto.clinicalView!.clinicalNotesFormat =
        this.dto.clinicalView.clinicalNotesFormat;
      this.dto.clinicalView!.clinicalWorkflow =
        this.dto.clinicalView.clinicalWorkflow;
      this.dto.clinicalView!.enforceDiscardConsultReason =
        this.dto.clinicalView.enforceDiscardConsultReason;
    }
    return this.dto.clinicalView;
  }

  @computed
  get patientLabel() {
    return this.dto.patientLabel;
  }
}
