import { observable, runInAction } from "mobx";

import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import {
  DrugClassDto,
  GetMedicationSummaryDto,
  IngredientDto,
  NonDrugDto
} from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { IDrugsGateway } from "@libs/gateways/drugs/DrugsGateway.interface.ts";
import { ReactionFilter } from "@libs/gateways/drugs/DrugsGateway.ts";
import { Store } from "@stores/types/store.type.ts";

import { DrugRef } from "./DrugRef.ts";

export class DrugsStore implements Store<DrugsStore, DrugRef> {
  constructor(private gateway: IDrugsGateway) {
    this.ref = new DrugRef(this.gateway);
  }

  ref: DrugRef;

  drugClassMap = observable.map<string, DrugClassDto>();
  ingredientMap = observable.map<string, IngredientDto>();
  nonDrugMap = observable.map<string, NonDrugDto>();

  @sharePendingPromise()
  searchProductNames(searchName: string) {
    return this.gateway.getProductNames(searchName);
  }

  @sharePendingPromise()
  searchProductPacks(productNameId: string) {
    return this.gateway.getProductPacks(productNameId);
  }

  searchReactionAgentTypes = async (filter: ReactionFilter = {}) => {
    return this.gateway.getReactionAgentTypes(filter);
  };

  searchMedicationSummary(params: GetMedicationSummaryDto) {
    return this.gateway.getMedicationSummary(params);
  }

  @sharePendingPromise()
  getIngredient(id: string) {
    const ingredient = this.ingredientMap.get(id);
    return ingredient
      ? Promise.resolve(ingredient)
      : this.gateway.getIngredient(id).then(value => {
          runInAction(() => {
            this.ingredientMap.set(id, value);
          });

          return value;
        });
  }

  @sharePendingPromise()
  getDrugClass(id: string) {
    const drug = this.drugClassMap.get(id);
    return drug
      ? Promise.resolve(drug)
      : this.gateway.getDrugClasses(id).then(value => {
          runInAction(() => {
            this.drugClassMap.set(id, value);
          });

          return value;
        });
  }

  @sharePendingPromise()
  getNonDrug(code: string) {
    const nonDrug = this.nonDrugMap.get(code);
    return nonDrug
      ? Promise.resolve(nonDrug)
      : this.gateway.getNonDrug(code).then(value => {
          runInAction(() => {
            this.nonDrugMap.set(code, value);
          });

          return value;
        });
  }
}
