import { action, computed, observable, runInAction } from "mobx";

import { AmendmentDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export class Amendment {
  constructor(dto: AmendmentDto) {
    runInAction(() => {
      this.dto = dto;
    });
  }

  @observable.ref
  dto: AmendmentDto;

  @computed
  get id() {
    return this.dto.id;
  }

  @computed
  get encounterId() {
    return this.dto.encounterId;
  }

  @computed
  get createdDate() {
    return this.dto.changeLog?.createdDate;
  }

  @computed
  get createdBy() {
    return this.dto.changeLog?.createdBy;
  }

  @computed
  get text() {
    return this.dto.text;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }

  @action
  updateFromDto(dto: AmendmentDto) {
    this.dto = dto;
  }
}
