import { action, observable } from "mobx";

import { SendFormConfirmationDialogProps } from "@shared-types/forms/send-form-confirmation-dialog-props.type.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { FormsStore } from "@stores/forms/FormsStore.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";

export class FormUi {
  public core: CoreStore;
  public practice: PracticeStore;
  public forms: FormsStore;
  @observable
  showSendFormsDialog: SendFormConfirmationDialogProps | undefined;

  @action
  public setSendFormDialog(value: SendFormConfirmationDialogProps | undefined) {
    this.showSendFormsDialog = value;
  }
  attachRoot(root: IRootStore) {
    this.core = root.core;
    this.practice = root.practice;
    this.forms = root.forms;
  }
}
