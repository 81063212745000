import { action, computed, observable, runInAction } from "mobx";

import { unique } from "@bps/utils";
import { UserAvailabilityDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export class UserAvailabilityModel {
  @observable.ref
  dto: UserAvailabilityDto;

  constructor(dto: UserAvailabilityDto) {
    runInAction(() => {
      this.dto = dto;
    });
  }

  @action
  updateFromDto(dto: UserAvailabilityDto) {
    this.dto = dto;
  }

  @computed
  get userId() {
    return this.dto.userId;
  }

  @computed
  get schedules() {
    return this.dto.bookingSchedules ?? [];
  }

  @computed
  get availableOrgUnitIds() {
    return unique(this.dto.bookingSchedules.map(x => x.orgUnitId) ?? []);
  }

  @computed
  get scheduleOverrides() {
    return this.dto.bookingScheduleOverrides ?? [];
  }
}
