import { createTransformer } from "mobx-utils";

import { DateTime } from "@bps/utils";

export interface TimeRangeOrgUnit {
  orgUnitId: string;
  from: DateTime;
  to: DateTime;
}

export type StartEndResult = {
  startTime: DateTime | undefined;
  endTime: DateTime | undefined;
};

/**
 * Wraps TimeRange[] into an object that memoizes calculations
 * of start and end time for given days and org unit ids.
 */
export class TimeRangesOrgUnit {
  constructor(public timeRanges: TimeRangeOrgUnit[]) {}

  /**
   * Returns the start and end time for a given day
   */
  startEndTime = (dateTime: DateTime) => {
    return this._startEndTime(dateTime);
  };
  /**
   * Transformer is used to memoize the result of start and end time of a day against an array
   * of time ranges.
   * Note that date parameter is a string (not a moment or Date) because the transformer will
   * use shallow comparison to memoize the result.
   */
  private _startEndTime = createTransformer(
    (date: DateTime) => extractStartEndTime(this.timeRanges, date),
    { keepAlive: true }
  );
}

const extractStartEndTime = (
  ranges: readonly TimeRangeOrgUnit[],
  date: DateTime
): StartEndResult => {
  const dayTimeRange = ranges.filter(timeRange =>
    timeRange.from.hasSame(date, "day")
  );

  const { length } = dayTimeRange;
  return {
    endTime: length
      ? dayTimeRange.reduce((a, b) => (a.to > b.to ? a : b)).to
      : undefined,
    startTime: length
      ? dayTimeRange.reduce((a, b) => (a.from < b.from ? a : b)).from
      : undefined
  };
};
