import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { AccOccupation } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { IAccGateway } from "@libs/gateways/acc/AccGateway.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class AccRef implements Ref<keyof AccRef> {
  constructor(private gateway: IAccGateway) {}

  claimStatuses = new RefDataAccessor(() => this.gateway.getClaimStatuses());

  accSexes = new RefDataAccessor(() => this.gateway.getACCSexes());

  scheduleProcessingStatuses = new RefDataAccessor(() =>
    this.gateway.getScheduleProcessingStatuses()
  );
  scheduleItemPaymentStatuses = new RefDataAccessor(() =>
    this.gateway.getScheduleItemPaymentStatuses()
  );
  claimAdjustmentStatuses = new RefDataAccessor(() =>
    this.gateway.getClaimAdjustmentStatuses()
  );
  accidentLocations = new RefDataAccessor(() =>
    this.gateway.getAccidentLocations()
  );
  accidentScenes = new RefDataAccessor(() => this.gateway.getAccidentScenes());
  sports = new RefDataAccessor(() => this.gateway.getSports());
  levelTwoEthnicities = new RefDataAccessor(() =>
    this.gateway.getLevelTwoEthnicities()
  );

  occupations = new RefDataAccessor<string, AccOccupation>(() =>
    this.gateway.getOccupations()
  );
  workTypes = new RefDataAccessor(() => this.gateway.getWorkTypes());
  employmentStatuses = new RefDataAccessor(() =>
    this.gateway.getEmploymentStatuses()
  );

  diagnosisSides = new RefDataAccessor(() => this.gateway.getDiagnosisSides());
  diagnosisStatuses = new RefDataAccessor(() =>
    this.gateway.getDiagnosisStatuses()
  );
  serviceItems = new RefDataAccessor(() => this.gateway.getServiceItems());
  documentContentTypes = new RefDataAccessor(() =>
    this.gateway.getDocumentContentTypes()
  );
  providerContractTypes = new RefDataAccessor(() =>
    this.gateway.getProviderContractTypes()
  );
  practiceContractTypes = new RefDataAccessor(() =>
    this.gateway.getPracticeContractTypes()
  );
  providerTypes = new RefDataAccessor(() => this.gateway.getProviderTypes());
  statementOnCausation = new RefDataAccessor(() =>
    this.gateway.getStatementOnCausation()
  );
  liaisonWithProviderUndertaken = new RefDataAccessor(() =>
    this.gateway.getLiaisonWithProviderUndertaken()
  );
  claimReviewStatuses = new RefDataAccessor(() =>
    this.gateway.getClaimReviewStatuses()
  );
}
