import { ClaimReviewDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class ClaimReview extends Model<ClaimReviewDto> {
  public get claimId() {
    return this.dto.claimId;
  }

  public get patientId() {
    return this.dto.patientId;
  }

  public get clinicalDirectorId() {
    return this.dto.clinicalDirectorId;
  }

  public get providers() {
    return this.dto.providers;
  }

  public get claimNumber() {
    return this.dto.claimNumber;
  }

  public get totalVisits() {
    return this.dto.totalVisits;
  }

  public get dateOfInjury() {
    return this.dto.dateOfInjury;
  }

  public get dateOfSurgery() {
    return this.dto.dateOfSurgery;
  }

  public get currentDiagnoses() {
    return this.dto.currentDiagnoses;
  }

  public get initialDiagnoses() {
    return this.dto.initialDiagnoses;
  }

  public get mechanismOfInjury() {
    return this.dto.mechanismOfInjury;
  }

  public get reviewInjuryAndDiagnosis() {
    return this.dto.reviewInjuryAndDiagnosis;
  }

  public get statementOnCausation() {
    return this.dto.statementOnCausation;
  }

  public get statementOnCausationDetails() {
    return this.dto.statementOnCausationDetails;
  }

  public get reviewOfCurrentTreatment() {
    return this.dto.reviewOfCurrentTreatment;
  }

  public get documentationOfAnyRecommendationsAndActions() {
    return this.dto.documentationOfAnyRecommendationsAndActions;
  }

  public get liaisonWithProviderUndertaken() {
    return this.dto.liaisonWithProviderUndertaken;
  }

  public get liaisonWithProviderUndertakenDetails() {
    return this.dto.liaisonWithProviderUndertakenDetails;
  }

  public get clinicalDocumentId() {
    return this.dto.clinicalDocumentId;
  }

  public get statusCode() {
    return this.dto.statusCode;
  }

  public get encounterId() {
    return this.dto.encounterId;
  }

  public get documentStatus() {
    return this.dto.documentStatus;
  }
}
