import { observable, runInAction } from "mobx";

import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import {
  GetTemplateDto,
  TemplateDto,
  TemplateFieldGroup,
  TemplateFieldOptions
} from "@libs/gateways/template-management/TemplateManagementGateway.dtos.ts";
import { ITemplateManagementGateway } from "@libs/gateways/template-management/TemplateMangement.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { TemplateInstance } from "./models/TemplateInstance.ts";
import { TemplateManagementRef } from "./TemplateManagementRef.ts";
import { TemplateManagementUi } from "./TemplateManagementUi.ts";

export class TemplateManagementStore
  implements Store<TemplateManagementStore, TemplateManagementRef>
{
  constructor(
    private gateway: ITemplateManagementGateway,
    private hub: IHubGateway
  ) {
    this.ref = new TemplateManagementRef(this.gateway);
  }

  afterAttachRoot() {
    this.hub.onEntityEvent(
      Entity.TemplateManagementTemplate,
      this.onUpdateTemplate
    );
  }

  private onUpdateTemplate = async (event: EntityEventData) => {
    const templateId = event.id;
    runInAction(async () => {
      if (event.action === EventAction.Delete) {
        this.ui.lastUpdatedTemplateInstanceETag = event.id;
        this.templateMap.delete(event.id);
      } else {
        this.ui.lastUpdatedTemplateInstanceETag = event.etag;
        const updatedTemplate = await this.gateway.getTemplate(templateId);
        this.mergeTemplate(updatedTemplate);
      }
    });
  };

  root: IRootStore;
  ref: TemplateManagementRef;
  ui = new TemplateManagementUi();

  templateMap = observable.map<string, TemplateInstance>();

  private get notification() {
    return this.root.notification;
  }

  getTemplateFields = (
    options: TemplateFieldOptions
  ): Promise<TemplateFieldGroup[]> => {
    return this.gateway.getTemplateFields(options);
  };

  createTemplate = (template: TemplateDto) => {
    this.gateway.createTemplate(template);
  };

  updateTemplate = (template: TemplateDto) => {
    this.gateway.updateTemplate(template);
  };

  public mergeTemplate = (dto: TemplateDto) => {
    return runInAction(() =>
      mergeModel({
        dto,
        getNewModel: () =>
          new TemplateInstance(this.root.templateManagement, dto),
        map: this.templateMap
      })
    );
  };

  getTemplates = async (args: GetTemplateDto): Promise<TemplateInstance[]> => {
    const dtos = await this.gateway.getTemplates(args);
    return dtos.map(this.mergeTemplate);
  };

  deleteTemplate = async (id: string): Promise<void> => {
    this.gateway.deleteTemplate(id);
  };

  async getTemplate(id: string): Promise<TemplateInstance | undefined> {
    try {
      if (this.templateMap.has(id)) {
        return this.templateMap.get(id);
      }

      const dto = await this.gateway.getTemplate(id);
      return this.mergeTemplate(dto);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading a template"
      });
    }
    return undefined;
  }
}
