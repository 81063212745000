import { computed } from "mobx";

import { BhbAppointmentTypeDto } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export interface BhbOnlineApptType {
  key: string;
  data: BhbAppointmentType;
  text: string;
}
export class BhbAppointmentType extends Model<BhbAppointmentTypeDto> {
  @computed
  get name() {
    return this.dto.name;
  }
  @computed
  get duration() {
    return this.dto.duration;
  }
  @computed
  get onlineName() {
    return this.dto.onlineName;
  }
  @computed
  get onlineDescription() {
    return this.dto.onlineDescription;
  }
  @computed
  get minuteDuration() {
    return this.dto.minuteDuration;
  }
  @computed
  get sortOrder() {
    return this.dto.sortOrder;
  }
  @computed
  get emailContent() {
    return this.dto.emailContent;
  }
  @computed
  get emailContentEnabled() {
    return this.dto.emailContentEnabled;
  }
  @computed
  get isAvailableNewPatients() {
    return this.dto.isAvailableNewPatients;
  }
  @computed
  get isAvailableExistingPatients() {
    return this.dto.isAvailableExistingPatients;
  }
  @computed
  get newPatientDuration() {
    return this.dto.newPatientDuration;
  }
  @computed
  get newPatientMinuteDuration() {
    return this.dto.newPatientMinuteDuration;
  }

  @computed
  get availabilities() {
    return this.dto.availabilities;
  }

  @computed
  get isCancellable() {
    return this.dto.isCancellable;
  }
}
