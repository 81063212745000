import { DateTime, to2dp } from "@bps/utils";
import {
  AddRefundDto,
  AddRefundItemDto,
  FeeType,
  GstMethod,
  InvoiceItemDto,
  ItemType,
  PaymentMethod,
  ServiceRuleType,
  ServiceSearchDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";

import { CreditNote } from "../models/CreditNote.ts";
import { Payment } from "../models/Payment.ts";

interface InvoiceItemAmounts
  extends Pick<InvoiceItemDto, "writtenOff" | "amount" | "paid" | "credited"> {}

export const getAddRefundBase = (
  creditNote: CreditNote | Payment
): Pick<AddRefundDto, "accountId" | "location" | "accountContact"> => ({
  accountId: creditNote.accountId,
  location: creditNote.location,
  accountContact: creditNote.accountContact
});

export const getRefundUnallocatedAmountDto = (
  creditNote: CreditNote | Payment,
  options: {
    refundNumber: string;
    paymentMethod?: PaymentMethod;
  }
) => {
  const refundItem: AddRefundItemDto = {
    locationId:
      creditNote.items.length > 0 ? creditNote.items[0].locationId : "",
    paymentMethod: options.paymentMethod || PaymentMethod.Cash,
    creditId: creditNote.id,
    amount: creditNote.unallocated,
    itemType: ItemType.Refund
  };

  const addRefundBase = getAddRefundBase(creditNote);

  const addRefund: AddRefundDto = {
    ...addRefundBase,
    transactionDate: DateTime.now().toISODate(),
    itemType: ItemType.Refund,
    items: [refundItem],
    number: options.refundNumber
  };

  return addRefund;
};

export const getInvoiceItemOwing = (invoiceItem: InvoiceItemAmounts) => {
  return (
    (invoiceItem.amount * 1000 -
      (invoiceItem.writtenOff || 0) * 1000 -
      (invoiceItem.paid || 0) * 1000) /
    1000
  );
};

export const getCreditPath = (itemType: string) => {
  if (itemType === ItemType.CreditNote) {
    return routes.accounts.creditNotes.viewPath;
  } else if (itemType === ItemType.Payment) {
    return routes.accounts.payments.viewPath;
  }
  return undefined;
};

export const maxZero = (num: number) => Math.max(to2dp(num), 0);
export const getServiceTax = (
  service: {
    quantity: string;
    fee: string;
    feeType: FeeType;
    serviceSearch?: ServiceSearchDto;
  },
  gstPercent: number
): number => {
  const multiplier =
    Number(service.quantity) / (service.feeType === FeeType.Hourly ? 60 : 1);

  const isUserDefined = service.serviceSearch?.rules?.some(
    rule => rule.ruleType === ServiceRuleType.UserDefinedAmount
  );
  let fee = service.serviceSearch?.fee ?? 0;
  if (!service.serviceSearch || isUserDefined) {
    fee = Number(service.fee);
  }

  const total = to2dp((fee ?? 0) * multiplier);

  switch (service.serviceSearch?.gstMethod) {
    case GstMethod.notApplicable:
      return 0;
    case GstMethod.included:
      return to2dp((total / (1 + gstPercent)) * gstPercent);
    case GstMethod.calculateRounded:
      if (isUserDefined) {
        return to2dp((total / (1 + gstPercent)) * gstPercent);
      } else {
        return to2dp(total * gstPercent);
      }
  }

  return 0;
};
export const getServiceTotal = (
  service: {
    quantity: string;
    fee: string;
    feeType: FeeType;
    serviceSearch?: ServiceSearchDto;
  },
  gstPercent: number
): number => {
  const multiplier =
    Number(service.quantity) / (service.feeType === FeeType.Hourly ? 60 : 1);

  const isUserDefined = service.serviceSearch?.rules?.some(
    rule => rule.ruleType === ServiceRuleType.UserDefinedAmount
  );
  let fee = service.serviceSearch?.fee ?? 0;
  if (!service.serviceSearch || isUserDefined) {
    fee = Number(service.fee);
  }

  const total = to2dp((fee ?? 0) * multiplier);

  if (
    service.serviceSearch?.gstMethod === GstMethod.calculateRounded &&
    !isUserDefined
  ) {
    const gst = to2dp(total * gstPercent);
    return to2dp(total + gst);
  }

  return total;
};
/**
 * Returns a GST inclusive fee amount that should only be used for displaying to the user.
 * The result of this function should never be multiplied by the quantity to get the total
 */
export const getServiceFee = (
  service: {
    quantity: string;
    fee: string;
    feeType: FeeType;
    serviceSearch?: ServiceSearchDto;
  },
  gstPercent: number
): number => {
  if (service.serviceSearch?.gstMethod === GstMethod.calculateRounded) {
    // convert gst exclusive amount to include gst
    const singleQuantity = service.feeType === FeeType.Hourly ? 60 : 1;
    return getServiceTotal(
      { ...service, quantity: String(singleQuantity) },
      gstPercent
    );
  }

  return Number(service.fee) ?? 0;
};
export const getInvoiceItem = (options: {
  service: ServiceSearchDto;
  gstPercent: number;
  serviceDate: Date;
}) => {
  const { service, gstPercent, serviceDate } = options;

  const quantity = service.feeType === FeeType.Hourly ? "60" : "1";
  const userDefinedAmount = service.rules?.find(
    r => r.ruleType === ServiceRuleType.UserDefinedAmount
  );

  const initialFee = String(service.fee ?? userDefinedAmount?.max ?? 0);

  const serviceOptions = {
    fee: initialFee,
    quantity,
    serviceSearch: service,
    feeType: service.feeType
  };

  const fee = getServiceFee(serviceOptions, gstPercent);

  const gst = getServiceTax(serviceOptions, gstPercent);

  const total = getServiceTotal(serviceOptions, gstPercent);

  return {
    serviceId: service.serviceId,
    scheduleId: service.scheduleId,
    serviceSearch: service,
    code: service.code,
    name: service.name || "",
    description: service.description || "",
    quantity: String(quantity),
    fee: String(fee),
    gst: String(gst),
    total: String(total),
    feeType: service.feeType,
    isService: service.isService,
    serviceDate
  };
};
