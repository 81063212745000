import { action, observable, runInAction } from "mobx";

import { StorageProperties } from "@libs/constants/storage-properties.ts";
import { DockableClinicalTab } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { AustralianState } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { patientTabKey } from "@stores/clinical/utils/clinical.utils.ts";

import { ClinicalTabItem } from "./ClinicalTabItem.ts";
import { ClinicalTabName } from "./ClinicalTabName.ts";
import { PatientClinicalRecordTab } from "./PatientClinicalRecordTab.ts";

export class ClinicalTabs {
  constructor(private root: IRootStore) {}

  private _clinicalTabName: ClinicalTabName;
  private _patientClinicalTabs = observable.map<
    string,
    PatientClinicalRecordTab
  >();

  @action
  createPatientTab(patientId: string, encounterId?: string) {
    //encounterId is set to undefined on purpose if its in view mode
    this._patientClinicalTabs.set(
      `${patientTabKey(patientId, encounterId)}${
        encounterId === undefined ? "-view" : ""
      }`,
      new PatientClinicalRecordTab(this.root, patientId, encounterId)
    );
  }

  public removePatientTabs = (patientId: string, encounterId?: string) => {
    const tabKey = patientTabKey(patientId, encounterId);

    const patientClinicalTab = this._patientClinicalTabs.get(
      `${tabKey}${encounterId === undefined ? "-view" : ""}`
    );
    if (patientClinicalTab) {
      patientClinicalTab.disposeReaction();
      runInAction(() => {
        this._patientClinicalTabs.delete(
          `${tabKey}${encounterId === undefined ? "-view" : ""}`
        );
      });

      // remove tab from sessionStorage
      const clinicalRecordsState = sessionStorage.getItem(
        StorageProperties.clinicalRecordsState
      );

      if (clinicalRecordsState) {
        const clinicalRecordsObj: Map<string, AustralianState> =
          JSON.parse(clinicalRecordsState);

        const clinicalRecordsMap = new Map(clinicalRecordsObj);
        clinicalRecordsMap.delete(
          `${tabKey}${encounterId === undefined ? "-view" : ""}`
        );

        sessionStorage.setItem(
          StorageProperties.clinicalRecordsState,
          JSON.stringify(Array.from(clinicalRecordsMap))
        );
      }
    }
  };

  get currentPatientRecordTab() {
    if (
      this.root.clinical.activeRecord &&
      this.root.clinical.activeRecordPatientId
    ) {
      const patientId = this.root.clinical.activeRecordPatientId;
      const encounterId = this.root.clinical.activeRecordEncounterId;

      const tabKey = this.root.clinical.activeRecordIsView
        ? patientId
        : patientTabKey(patientId, encounterId);
      return this._patientClinicalTabs.get(
        `${tabKey}${this.root.clinical.activeRecordIsView ? "-view" : ""}`
      );
    }

    return undefined;
  }

  get patientClinicalTabs() {
    return this._patientClinicalTabs;
  }

  @action
  createClinicalTabItem(data: {
    type: DockableClinicalTab;
    encounterId?: string;
    title?: string;
    toolTipText?: string;
    contextId?: string;
  }): ClinicalTabItem {
    const tab = new ClinicalTabItem(data);

    // @TODO not clear for me why do we need it? need to ask Andrew. Ilya S.
    if (tab.encounterId && tab.hasGenericTitle) {
      if (!this._clinicalTabName)
        this._clinicalTabName = new ClinicalTabName(this.root.clinical);

      this._clinicalTabName
        .getName(data.type, tab.id)
        .then(data => tab.updateTitle(data));
    }

    return tab;
  }
}
