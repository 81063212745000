import { computed } from "mobx";

import { CommPreferenceDto } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class CommPreference extends Model<CommPreferenceDto> {
  @computed
  get preferences() {
    return this.dto.preferenceData;
  }

  @computed
  get isAppointmentConfirmation() {
    return this.preferences?.appointmentConfirmation?.isActive;
  }
}
