import { computed } from "mobx";

import { MeasurementDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class Measurement extends Model<MeasurementDto> {
  @computed
  get type() {
    return this.dto.type;
  }

  @computed
  get parentType() {
    return this.dto.parentType;
  }

  @computed
  get parentId() {
    return this.dto.parentId;
  }

  @computed
  get patientId() {
    return this.dto.patientId;
  }

  @computed
  get value() {
    return this.dto.value;
  }

  @computed
  get summary() {
    return this.dto.summary;
  }

  @computed
  get indicator() {
    return this.dto.indicator;
  }

  @computed
  get encounterId() {
    return this.dto.encounterId;
  }

  @computed
  get contextId() {
    return this.dto.contextId;
  }

  @computed
  get timestamp() {
    return this.dto.timestamp;
  }

  @computed
  get secGroupId() {
    return this.dto.secGroupId;
  }

  get changeLog() {
    return this.dto.changeLog;
  }
}
