import { computed } from "mobx";

import {
  AuOrgUnitBusinessDto,
  AuOrgUnitIdentifierDto,
  LocationDataDto,
  NzOrgUnitBusinessDto,
  NzOrgUnitIdentifierDto,
  NzPracticeAccContractCompanyData,
  OrgUnitCompanyDataDto,
  OrgUnitCompanyDataType,
  OrgUnitDocumentSettingsDto,
  OrgUnitDto,
  OrgUnitEnabledPhysitrackDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class PracOrgUnit extends Model<OrgUnitDto> {
  @computed get auOrgUnitBusiness() {
    return orgUnitCompanyDataToAuOrgUnitBusiness(this.dto.orgUnitCompanyData);
  }

  @computed get auOrgUnitIdentifier() {
    return orgUnitCompanyDataToAuOrgUnitIdentifier(this.dto.orgUnitCompanyData);
  }

  @computed get nzOrgUnitBusiness() {
    return orgUnitCompanyDataToNzOrgUnitBusiness(this.dto.orgUnitCompanyData);
  }

  @computed get nzOrgUnitIdentifier() {
    return orgUnitCompanyDataToNzOrgUnitIdentifier(this.dto.orgUnitCompanyData);
  }

  @computed get orgUnitCompanyData() {
    return this.dto.orgUnitCompanyData;
  }

  @computed get profilePictureUrl() {
    return this.dto.profilePictureUrl;
  }

  @computed get nzAccContracts() {
    return orgUnitCompanyDataToNzAccContracts(this.dto.orgUnitCompanyData);
  }

  @computed get orgUnitEnabledPhysitrack() {
    return orgUnitCompanyDataToOrgUnitEnabledPhysitrack(
      this.dto.orgUnitCompanyData
    );
  }

  @computed get orgUnitDocumentSettings() {
    return orgUnitCompanyDataToOrgUnitDocumentSettings(
      this.dto.orgUnitCompanyData
    );
  }

  @computed get orgUnitLocationData() {
    return orgUnitCompanyDataToLocationData(this.dto.orgUnitCompanyData);
  }

  @computed get isDefault() {
    return this.orgUnitLocationData?.defaultLocation;
  }

  getUpdatedOrgUnitCompanyData(
    update: OrgUnitCompanyDataDto[]
  ): OrgUnitCompanyDataDto[] {
    const data: OrgUnitCompanyDataDto[] = [];

    const auOrgUnitBusiness =
      orgUnitCompanyDataToAuOrgUnitBusiness(update) || this.auOrgUnitBusiness;
    if (auOrgUnitBusiness) {
      data.push(auOrgUnitBusiness);
    }

    const auOrgUnitIdentifier =
      orgUnitCompanyDataToAuOrgUnitIdentifier(update) ||
      this.auOrgUnitIdentifier;
    if (auOrgUnitIdentifier) {
      data.push(auOrgUnitIdentifier);
    }

    const nzOrgUnitBusiness =
      orgUnitCompanyDataToNzOrgUnitBusiness(update) || this.nzOrgUnitBusiness;
    if (nzOrgUnitBusiness) {
      data.push(nzOrgUnitBusiness);
    }

    const nzOrgUnitIdentifier =
      orgUnitCompanyDataToNzOrgUnitIdentifier(update) ||
      this.nzOrgUnitIdentifier;
    if (nzOrgUnitIdentifier) {
      data.push(nzOrgUnitIdentifier);
    }

    const nzAccContract =
      orgUnitCompanyDataToNzAccContracts(update) || this.nzAccContracts;
    if (nzAccContract) {
      data.push(nzAccContract);
    }

    const orgUnitEnabledPhysitrack =
      orgUnitCompanyDataToOrgUnitEnabledPhysitrack(update) ||
      this.orgUnitEnabledPhysitrack;
    if (orgUnitEnabledPhysitrack) {
      data.push(orgUnitEnabledPhysitrack);
    }

    const orgUnitDocumentSettings =
      orgUnitCompanyDataToOrgUnitDocumentSettings(update) ||
      this.orgUnitDocumentSettings;
    if (orgUnitDocumentSettings) {
      data.push(orgUnitDocumentSettings);
    }

    const locationData =
      orgUnitCompanyDataToLocationData(update) || this.orgUnitLocationData;
    if (locationData) {
      data.push(locationData);
    }

    return data;
  }
}

export const orgUnitCompanyDataToAuOrgUnitBusiness = (
  data: OrgUnitCompanyDataDto[]
): AuOrgUnitBusinessDto | undefined => {
  return data?.find(
    (o): o is AuOrgUnitBusinessDto =>
      o.orgUnitCompanyDataTypeCode === OrgUnitCompanyDataType.AuOrgUnitBusiness
  );
};

export const orgUnitCompanyDataToAuOrgUnitIdentifier = (
  data: OrgUnitCompanyDataDto[]
): AuOrgUnitIdentifierDto | undefined => {
  return data?.find(
    (o): o is AuOrgUnitIdentifierDto =>
      o.orgUnitCompanyDataTypeCode ===
      OrgUnitCompanyDataType.AuOrgUnitIdentifier
  );
};

export const orgUnitCompanyDataToNzOrgUnitBusiness = (
  data: OrgUnitCompanyDataDto[]
): NzOrgUnitBusinessDto | undefined => {
  return data?.find(
    (o): o is NzOrgUnitBusinessDto =>
      o.orgUnitCompanyDataTypeCode === OrgUnitCompanyDataType.NzOrgUnitBusiness
  );
};

export const orgUnitCompanyDataToNzAccContracts = (
  data: OrgUnitCompanyDataDto[]
): NzPracticeAccContractCompanyData | undefined => {
  return data?.find(
    (
      o: NzPracticeAccContractCompanyData
    ): o is NzPracticeAccContractCompanyData =>
      o.orgUnitCompanyDataTypeCode === OrgUnitCompanyDataType.NzAccContracts
  );
};

export const orgUnitCompanyDataToLocationData = (
  data: OrgUnitCompanyDataDto[]
): LocationDataDto | undefined => {
  return data.find(
    (o): o is LocationDataDto =>
      o.orgUnitCompanyDataTypeCode === OrgUnitCompanyDataType.LocationData
  );
};

export const orgUnitCompanyDataToNzOrgUnitIdentifier = (
  data: OrgUnitCompanyDataDto[]
): NzOrgUnitIdentifierDto | undefined => {
  return data?.find(
    (o): o is NzOrgUnitIdentifierDto =>
      o.orgUnitCompanyDataTypeCode ===
      OrgUnitCompanyDataType.NzOrgUnitIdentifier
  );
};

export const orgUnitCompanyDataToOrgUnitEnabledPhysitrack = (
  data: OrgUnitCompanyDataDto[]
): OrgUnitEnabledPhysitrackDto | undefined => {
  return data?.find(
    (o): o is OrgUnitEnabledPhysitrackDto =>
      o.orgUnitCompanyDataTypeCode === OrgUnitCompanyDataType.Physitrack
  );
};

const orgUnitCompanyDataToOrgUnitDocumentSettings = (
  data: OrgUnitCompanyDataDto[]
): OrgUnitDocumentSettingsDto | undefined => {
  return data?.find(
    (o): o is OrgUnitDocumentSettingsDto =>
      o.orgUnitCompanyDataTypeCode === OrgUnitCompanyDataType.DocumentSettings
  );
};
