import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import {
  EncounterDto,
  EncounterStatus
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AppointmentEncounters } from "@stores/booking/models/AppointmentEncounters.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { User } from "@stores/core/models/User.ts";

export class Encounter extends Model<EncounterDto> {
  constructor(
    private store: IRootStore,
    dto: EncounterDto
  ) {
    super(dto);
  }

  @computed
  get userId() {
    return this.dto.userId;
  }

  @computed
  get patientId() {
    return this.dto.patientId;
  }

  @computed
  get orgUnitId() {
    return this.dto.orgUnitId;
  }

  @computed
  get startDateTime() {
    return DateTime.fromISO(this.dto.visitDateTime);
  }

  @computed
  get status() {
    return this.dto.status;
  }

  @computed
  get type() {
    return this.dto.encounterType;
  }

  @computed
  get location() {
    return this.dto.encounterLocation;
  }

  @computed
  get user(): User | undefined {
    return this.userId ? this.store.core.userMap.get(this.userId) : undefined;
  }

  @computed
  get isClosed(): boolean {
    return this.status === EncounterStatus.Closed;
  }

  @computed
  get episodeOfCareId() {
    return this.dto.episodeOfCareId;
  }

  @computed
  get businessRole() {
    return this.dto.businessRole;
  }

  @computed
  get secGroupId() {
    return this.dto.secGroupId;
  }

  @computed
  get appointmentEncounters(): AppointmentEncounters[] {
    return Array.from(
      this.store.booking.appointmentEncountersMap.values()
    ).filter(ae => ae.encounterId === this.id);
  }

  @computed get calendarEventId(): string | undefined {
    if (this.appointmentEncounters && this.appointmentEncounters.length > 0) {
      return this.appointmentEncounters[0].calendarEventId;
    }
    return undefined;
  }

  @computed get calendarEvent(): CalendarEvent | undefined {
    if (
      this.calendarEventId &&
      this.store.booking.calendarEventsMap.has(this.calendarEventId)
    ) {
      return this.store.booking.calendarEventsMap.get(this.calendarEventId);
    }
    return undefined;
  }

  async loadCalendarEvent() {
    await this.store.booking.getAppointmentEncounters({
      encounterId: this.id
    });

    if (this.calendarEventId) {
      return await this.store.booking.getCalendarEvent(this.calendarEventId);
    }

    return undefined;
  }
}
