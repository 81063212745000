import { computed } from "mobx";

import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { RefContactCategoryDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

export class ContactCategoryRefDataAccessor extends RefDataAccessor<
  string,
  RefContactCategoryDto
> {
  @computed
  get keyNameValuesWithContactType() {
    return this.values.map(({ code, text, contactType, categoryCode }) => ({
      key: code,
      name: text,
      contactType,
      categoryCode
    }));
  }
}
