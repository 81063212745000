import { computed } from "mobx";

import { BhbLocationDto } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class BhbLocation extends Model<BhbLocationDto> {
  @computed
  get onlineBookingsEnabled() {
    return this.dto.onlineBookingsEnabled;
  }
  @computed
  get name() {
    return this.dto.name;
  }
  @computed
  get address() {
    return this.dto.address;
  }
  @computed
  get timeZoneId() {
    return this.dto.timeZoneId;
  }
  @computed
  get phone() {
    return this.dto.phone;
  }
  @computed
  get url() {
    return this.dto.url;
  }
  @computed
  get parkingAccessEnabled() {
    return this.dto.parkingAccessEnabled;
  }
  @computed
  get parkingAccess() {
    return this.dto.parkingAccess;
  }
  @computed
  get disabilityAccessEnabled() {
    return this.dto.disabilityAccessEnabled;
  }
  @computed
  get disabilityAccess() {
    return this.dto.disabilityAccess;
  }
  @computed
  get bookingPolicyEnabled() {
    return this.dto.bookingPolicyEnabled;
  }
  @computed
  get policy() {
    return this.dto.policy;
  }
  @computed
  get openingHours() {
    return this.dto.openingHours;
  }
  @computed
  get alternativeHours() {
    return this.dto.alternativeHours;
  }
  @computed
  get showAvailability() {
    return this.dto.showAvailability;
  }
  @computed
  get minimumTimeToCancel() {
    return this.dto.minimumTimeToCancel;
  }

  @computed
  get minimumTimeToBook() {
    return this.dto.minimumTimeToBook;
  }
  @computed
  get maximumAppointmentsPerDay() {
    return this.dto.maximumAppointmentsPerDay;
  }
  @computed
  get allowCancellationsEnabled() {
    return this.dto.allowCancellationsEnabled;
  }
  @computed
  get logoUrl() {
    return this.dto.logoUrl;
  }
  @computed
  get emergencyMessageEnabled() {
    return this.dto.emergencyMessageEnabled;
  }
  @computed
  get emergencyMessage() {
    return this.dto.emergencyMessage;
  }
}
