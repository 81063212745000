import { computed } from "mobx";

import {
  OrgUnitSettingDto,
  PreferenceSettingDto,
  WaitingRoomSettingDto
} from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class OrgUnitSetting extends Model<OrgUnitSettingDto & { id: string }> {
  @computed
  get waitingRoom(): WaitingRoomSettingDto | undefined {
    return this.dto.waitingRoom;
  }

  /**
   * These settings are default to true if they don't exist for the tenant
   */
  @computed
  get preferenceSetting(): PreferenceSettingDto {
    const preferenceSettingDto: PreferenceSettingDto = {
      autoLinkCondition:
        this.dto.preferenceSetting?.autoLinkCondition !== undefined
          ? this.dto.preferenceSetting?.autoLinkCondition
          : true,
      captureApptCancellationReason:
        this.dto.preferenceSetting?.captureApptCancellationReason !== undefined
          ? this.dto.preferenceSetting?.captureApptCancellationReason
          : true
    };

    return preferenceSettingDto;
  }

  @computed
  get appointmentSetting() {
    return {
      apptTypeBaseInterval: this.dto?.appointmentSetting?.apptTypeBaseInterval
    };
  }
}
