import { computed } from "mobx";

import { TenantSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class TenantSetting extends Model<TenantSettingDto & { id: string }> {
  @computed
  get patientLabel() {
    return this.dto.patientLabel;
  }

  @computed
  get clinicalTaskSettings() {
    return this.dto.clinicalTaskSettings?.value;
  }

  @computed
  get commsDefaultCampaign() {
    return this.dto.commsDefaultCampaign?.value;
  }

  @computed
  get securitySettings() {
    return this.dto.securitySettings;
  }
}
