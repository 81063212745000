import { action, observable, runInAction } from "mobx";

import { DockableClinicalTab } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { ClinicalDataTypeName } from "./ClinicalTabName.ts";

export class ClinicalTabItem {
  constructor(
    private data: {
      type: DockableClinicalTab;
      encounterId?: string;
      title?: string;
      contextId?: string;
      toolTipText?: string;
      defaultPivot?: string;
    }
  ) {
    // uniq id
    this.id = `id_${data.type}${data.encounterId ?? ""}${data.contextId ?? ""}`;
    this.type = data.type;
    this.encounterId = data.encounterId;
    this.contextId = data.contextId;
    this.toolTipText = data.toolTipText;
    this.defaultPivot = data.defaultPivot;
    runInAction(() => {
      this.title = this.data.title ?? ClinicalDataTypeName[this.data.type];
    });
  }

  id: string;
  encounterId: string | undefined;
  type: DockableClinicalTab;
  contextId: string | undefined;
  toolTipText: string | undefined;
  defaultPivot: string | undefined;

  @observable
  isActive: boolean = false;

  @observable
  isDirty: boolean = false;

  @observable
  title: string | undefined;

  @action
  updateTitle(value: string): void {
    this.title = value;
  }

  get hasGenericTitle(): boolean {
    return !!ClinicalDataTypeName[this.data.type];
  }
}
