import {
  DockableClinicalTab,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalTabItem } from "@stores/clinical/models/clinical-tab/ClinicalTabItem.ts";

export class TabConditionChecker {
  constructor(private clinical: ClinicalStore) {}

  getTabCondition = (): TabSaveCondition | undefined => {
    const patientTabs = this.clinical.ui.tabs.currentPatientRecordTab?.allTabs;

    if (patientTabs) {
      const defaultTabs: ClinicalTabItem[] =
        this.clinical.ui.tabs.currentPatientRecordTab?.defaultTabs ?? [];

      const unsavedTabs: ClinicalTabItem[] = [];
      const safeToCloseTabs: ClinicalTabItem[] = [];

      for (const tab of patientTabs) {
        const dataType: DockableClinicalTab = tab.type;

        if (dataType !== PermanentClinicalTab.TodaysNotes) {
          const dirtyTab =
            this.clinical.ui.clinicalTabs.currentPatientRecordTab?.dirtyTabs.find(
              tab => tab.type === dataType
            );

          if (dirtyTab) {
            unsavedTabs.push(tab);
          } else if (!defaultTabs.find(t => t.type === dataType)) {
            safeToCloseTabs.push(tab);
          }
        }
      }

      return new TabSaveCondition(safeToCloseTabs, defaultTabs, unsavedTabs);
    } else return undefined;
  };
}

export class TabSaveCondition {
  private _safeToClose: ClinicalTabItem[];
  private _keepOpen: ClinicalTabItem[];
  private _unsavedData: ClinicalTabItem[];

  //Tabs where there is no dirty form
  get safeToClose(): ClinicalTabItem[] {
    return this._safeToClose;
  }

  //The default tabs (Patient Summary, Today's Notes)
  get keepOpen(): ClinicalTabItem[] {
    return this._keepOpen;
  }

  //Tabs where there is dirty form
  get unsavedData(): ClinicalTabItem[] {
    return this._unsavedData;
  }

  constructor(
    safeToClose: ClinicalTabItem[],
    keepOpen: ClinicalTabItem[],
    unsavedData: ClinicalTabItem[]
  ) {
    this._safeToClose = safeToClose;
    this._keepOpen = keepOpen;
    this._unsavedData = unsavedData;
  }
}
