import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { ITemplateManagementGateway } from "@libs/gateways/template-management/TemplateMangement.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class TemplateManagementRef implements Ref<keyof TemplateManagementRef> {
  constructor(private gateway: ITemplateManagementGateway) {}

  templateTypes = new RefDataAccessor(() => this.gateway.getTemplateTypes());

  templateFormats = new RefDataAccessor(() =>
    this.gateway.getTemplateFormats()
  );
}
