import { action, observable, runInAction } from "mobx";
import { ReactNode } from "react";

import {
  flatten,
  IMessageBarStyleProps,
  IMessageBarStyles,
  IStyleFunctionOrObject
} from "@bps/fluent-ui";
import { ILogger, Logger } from "@bps/http-client";
import { chunksOf, DateTime } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import {
  NotificationDto,
  NotificationGetRequest,
  NotificationUpdateRequest
} from "@libs/gateways/notification/NotificationGateway.dtos.ts";
import { INotificationGateway } from "@libs/gateways/notification/NotificationGateway.interface.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { SystemNotification } from "./models/SystemNotification.ts";

export type NotificationType = "info" | "success" | "error" | "warning";
const APP_NAME = "bps-titanium-shell-web";
interface Notification {
  message: ReactNode;
  type: NotificationType;
  key: string;
  duration?: number;
  messageBarStyles?: IStyleFunctionOrObject<
    IMessageBarStyleProps,
    IMessageBarStyles
  >;
}

export class NotificationsStore implements Store<NotificationsStore> {
  private keyCounter = 0;
  respondNotifications = observable<Notification>([], { deep: false });
  systemNotificationsMap = observable.map<string, SystemNotification>();
  root: IRootStore;
  public logger: ILogger = new Logger(APP_NAME);

  constructor(
    public config = { notificationDuration: 4000 },
    private gateway: INotificationGateway,
    public hub: IHubGateway
  ) {}

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.Notification, this.onNotificationEvent);
  }

  /**
   * onNotificationEvent is called when a notification event is received from the SignalR hub
   */
  onNotificationEvent = async (eventData: EntityEventData) => {
    const { action, key, etag, id } = eventData;
    const { core } = this.root;
    if (action === EventAction.Create) {
      const notificaton = this.systemNotificationsMap.get(eventData.id);

      if (!notificaton || notificaton.eTag !== etag) {
        await this.getNotification(key, core.userId);
        await this.getUnreadNotificationsCount(this.root.core.userId);
      }
    } else if (id === "MarkAllRead" && action === EventAction.Update) {
      await this.getUnreadNotificationsCount(this.root.core.userId);
    }
  };

  @observable
  continuationToken?: string = undefined;

  @observable
  unreadCount: number = 0;

  @action
  setUnreadCount = (value: number) => {
    this.unreadCount = value;
  };

  @action
  close = (key: string) => {
    const notification = this.respondNotifications.find(x => x.key === key);
    if (notification) {
      return this.respondNotifications.remove(notification);
    }
    return false;
  };

  @action
  private notify = (args: Omit<Notification, "key">): string => {
    const notification = { ...args, key: `${this.keyCounter++}` };
    this.respondNotifications.push(notification);
    setTimeout(() => {
      // ⚠️ remove will not work unless notifications is created with deep: false option
      runInAction(() => {
        this.respondNotifications.remove(notification);
      });
    }, args.duration || this.config.notificationDuration);
    return notification.key;
  };

  error = (
    error: ReactNode | Error,
    options: {
      duration?: number;
      messageBarStyles?: Notification["messageBarStyles"];
      messageOverride?: ReactNode;
    } = {}
  ) => {
    if (error instanceof Error) {
      const timestamp = DateTime.now().toISO();
      if (import.meta.env.NODE_ENV !== "test") {
        this.logger.error(
          `${timestamp}] Error message: ${error.message}`,
          error
        );
        this.logger.error(`${timestamp}] Stack trace: ${error.stack}`, error);
      }
    }

    let message;
    if (options.messageOverride) {
      message = options.messageOverride;
    } else if (error instanceof Error) {
      message = error.message;
    } else {
      message = error;
    }

    return this.notify({
      message,
      type: "error",
      duration: !options.duration ? 3000 : options.duration,
      messageBarStyles: options.messageBarStyles
    });
  };

  info = (
    message: ReactNode,
    options: {
      duration?: number;
      messageBarStyles?: Notification["messageBarStyles"];
    } = {}
  ) =>
    this.notify({
      message,
      type: "info",
      duration: options.duration,
      messageBarStyles: options.messageBarStyles
    });

  success = (
    message: ReactNode,
    options: {
      duration?: number;
      messageBarStyles?: Notification["messageBarStyles"];
    } = {}
  ) =>
    this.notify({
      message,
      type: "success",
      duration: options.duration,
      messageBarStyles: options.messageBarStyles
    });

  warn = (
    message: ReactNode,
    options: {
      duration?: number;
      messageBarStyles?: Notification["messageBarStyles"];
    } = {}
  ) =>
    this.notify({
      message,
      type: "warning",
      duration: options.duration,
      messageBarStyles: options.messageBarStyles
    });

  @action
  mergeSystemNotification = (dto: NotificationDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new SystemNotification(dto),
      map: this.systemNotificationsMap
    });
  };

  @sharePendingPromise()
  async getNotifications(
    request: NotificationGetRequest
  ): Promise<SystemNotification[]> {
    const result = await this.gateway.getNotifications({
      ...request,
      maxPerPage: 25
    });

    runInAction(() => {
      this.continuationToken = result.continuationToken;
    });

    return result.notifications.map(this.mergeSystemNotification);
  }

  @sharePendingPromise()
  async getUnreadNotificationsCount(userId: string): Promise<number> {
    const count = await this.gateway.getNotificationsCount(userId, false);
    this.setUnreadCount(count);
    return count;
  }

  @sharePendingPromise()
  async updateNotification(
    notificationId: string,
    userId: string,
    request: NotificationUpdateRequest
  ) {
    const { isRead } = request;
    const result = await this.gateway.updateNotification(
      notificationId,
      userId,
      request
    );

    const countValue =
      isRead && isRead === true ? this.unreadCount - 1 : this.unreadCount + 1;
    this.setUnreadCount(countValue);

    this.mergeSystemNotification(result);
  }

  @sharePendingPromise()
  async updateBatchNotifications(notifications: NotificationDto[]) {
    const MAX_CHUNK_SIZE = 100;

    const results = await Promise.all(
      chunksOf(notifications, MAX_CHUNK_SIZE).map(chunk =>
        this.gateway.updateBatchNotifications(chunk)
      )
    );

    return flatten(results).map(this.mergeSystemNotification);
  }

  @sharePendingPromise()
  async markAllRead(userId: string) {
    await this.gateway.markAllRead(userId);
  }

  @sharePendingPromise()
  async getNotification(id: string, userId: string) {
    const result = await this.gateway.getNotification(id, userId);
    this.mergeSystemNotification(result);
  }
}
