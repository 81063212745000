import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

type DecoratedFuncType<T extends unknown = unknown> = (
  ...args: any[]
) => PromiseLike<T | undefined>;

/**
 * Decorator that can be applied on a class method or property that checks
 * the permissions for a user, and if no permissions, returns the default value (if supplied)
 *
 * This is useful to avoid performing an HTTP requests when
 * we know that the backend call will fail due to
 * insufficient permissions.
 *
 * Example:
 * ```
 * class MyStore {
 *  @permission<MyListType[]>([Permission.AnyPermission], [])
 *  getResourceArray(id: string):Promise<MyListType[]> {
 *      ...
 *      return undefined || [...forExampleMyListElements]
 *  }
 *  @permission([Permission.AnyPermission,Permission.OtherPermission], {defaultValue: undefined, operator: "or" })
 *  getResourceObject(id: string) {
 *      ...
 *  }
 *  @permission<MyShape>([Permission.AnyPermission,Permission.OtherPermission], {shape:{mine:true}})
 *  getResourceObject(id: string) {
 *     ...
 *     return {} as MyShape
 *  }
 * }
 *
 * ```
 *
 * The first argument is an array or Permission objects.
 * The second optional parameter is the returned result if
 * no permissions are available for the user.
 *
 *
 */

export function permission<T extends unknown = unknown>(
  this: any,
  permissions: Permission[],
  options?: {
    defaultValue?: T;
    operator?: "and" | "or";
  }
) {
  return (
    target: any,
    propertyKey: string,
    descriptor?: TypedPropertyDescriptor<DecoratedFuncType<T>>
  ) => {
    if (!descriptor || !descriptor.value) {
      const updatedDescriptor = Object.getOwnPropertyDescriptor(
        target,
        propertyKey
      );

      if (!updatedDescriptor) {
        throw Error(
          `permission decorator only supports methods and function properties - ${propertyKey}`
        );
      }

      throw updatedDescriptor;
    }

    const originalMethod = descriptor.value!;

    descriptor.value = function (...args: any[]) {
      const root = this["root"] as IRootStore;
      if (!root) {
        throw Error("Must have core or root store defined.");
      }

      const core = root.core;
      // if user has a permission we return the original method
      if (core.hasPermissions(permissions, options?.operator)) {
        return originalMethod.apply(this, args);
      }

      // return default value (undefined or any other)
      return Promise.resolve(options?.defaultValue);
    };
  };
}
