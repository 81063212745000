import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { IBillingGateway } from "@libs/gateways/billing/BillingGateway.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

export const isBillingRefKeyCodeAccessor = (
  val: BillingRefKeyCodeAccessor | string
): val is BillingRefKeyCodeAccessor => {
  return typeof val !== "string";
};

export interface BillingRefKeyCodeAccessor {
  key: keyof BillingRef;
  code: string;
}

export class BillingRef implements Ref<keyof BillingRef> {
  constructor(private gateway: IBillingGateway) {}

  claimStatuses = new RefDataAccessor(() => this.gateway.getClaimStatuses());
  accScheduleTransactionStatuses = new RefDataAccessor(() =>
    this.gateway.getAccScheduleTransactionStatuses()
  );

  transactionCancelReason = new RefDataAccessor(() =>
    this.gateway.getTransactionCancellationReasons()
  );

  paymentMethods = new RefDataAccessor(() => this.gateway.getPaymentMethods());

  feeTypes = new RefDataAccessor(() => this.gateway.getFeeTypes());
  paymentStatuses = new RefDataAccessor(() =>
    this.gateway.getPaymentStatuses()
  );
  billingStatuses = new RefDataAccessor(() =>
    this.gateway.getBillingStatuses()
  );
  itemTypes = new RefDataAccessor(() => this.gateway.getItemTypes());
}
