import { DateTime } from "@bps/utils";
import {
  AccClinicalTab,
  ClinicalDataType,
  ClinicalDataTypeLabel,
  DockableClinicalTab,
  EncounterClinicalDataDto,
  MeasurementType,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";

export const ClinicalDataTypeName = {
  [ClinicalDataType.K10]: ClinicalDataTypeLabel.K10,
  [ClinicalDataType.NPRS]: ClinicalDataTypeLabel.NPRS,
  [ClinicalDataType.OREBRO]: ClinicalDataTypeLabel.OREBRO,
  [ClinicalDataType.GEN]: ClinicalDataTypeLabel.GEN,
  [ClinicalDataType.Alcohol]: ClinicalDataTypeLabel.Alcohol,
  [ClinicalDataType.Tobacco]: ClinicalDataTypeLabel.Tobacco,
  [ClinicalDataType.SubstanceUse]: ClinicalDataTypeLabel.SubstanceUse,
  [ClinicalDataType.Social]: ClinicalDataTypeLabel.Social,
  [ClinicalDataType.PhysicalActivity]: ClinicalDataTypeLabel.PhysicalActivity,
  [ClinicalDataType.Eye]: ClinicalDataTypeLabel.Eye,
  [AccClinicalTab.Review]: ClinicalDataTypeLabel.Review,
  [AccClinicalTab.Injury]: ClinicalDataTypeLabel.Injury,
  [AccClinicalTab.Adjustment]: ClinicalDataTypeLabel.Adjustment,
  [PermanentClinicalTab.TodaysNotes]: ClinicalDataTypeLabel.TodaysNotes,
  [PermanentClinicalTab.PatientSummary]: ClinicalDataTypeLabel.PatientSummary,
  [PermanentClinicalTab.SOTAP]: ClinicalDataTypeLabel.SOTAP,
  [ClinicalDataType.FullBody]: ClinicalDataTypeLabel.FullBody,
  [ClinicalDataType.PSFS]: ClinicalDataTypeLabel.PSFS,
  [ClinicalDataType.GRCS]: ClinicalDataTypeLabel.GRCS,
  [ClinicalDataType.DASH]: ClinicalDataTypeLabel.DASH11,
  [ClinicalDataType.WorkHistory]: ClinicalDataTypeLabel.WorkHistory,
  [ClinicalDataType.ClinicalTask]: ClinicalDataTypeLabel.ClinicalTask,
  [ClinicalDataType.Discharge]: ClinicalDataTypeLabel.Discharge,
  [ClinicalDataType.Sleep]: ClinicalDataTypeLabel.Sleep,
  [ClinicalDataType.GeneralExamination]:
    ClinicalDataTypeLabel.GeneralExamination,
  [ClinicalDataType.BodyExam]: ClinicalDataTypeLabel.BodyExam,
  [ClinicalDataType.TreatmentAndEducation]: ClinicalDataTypeLabel.Management,
  [ClinicalDataType.CurrentInjury]:
    ClinicalDataTypeLabel.HistoryPresentingComplaint,
  [ClinicalDataType.ClinicalFlags]: ClinicalDataTypeLabel.ClinicalFlags,
  [ClinicalDataType.PatientTreatmentPlan]: ClinicalDataTypeLabel.Plan,
  [ClinicalDataType.DermatomesAndMyotomes]:
    ClinicalDataTypeLabel.DermatomesAndMyotomes,
  [ClinicalDataType.FamilyHistory]: ClinicalDataTypeLabel.FamilyHistory,
  [ClinicalDataType.SystemsReview]: ClinicalDataTypeLabel.SystemsReview,
  [ClinicalDataType.RAND36]: ClinicalDataTypeLabel.RAND36,
  [ClinicalDataType.CentralNervousSystem]:
    ClinicalDataTypeLabel.CentralNervousSystem,
  [ClinicalDataType.EPDS]: ClinicalDataTypeLabel.EPDS,
  [ClinicalDataType.DASS21]: ClinicalDataTypeLabel.DASS21,
  [ClinicalDataType.Observations]: ClinicalDataTypeLabel.Observations,
  [ClinicalDataType.Medication]: ClinicalDataTypeLabel.Medications,
  [ClinicalDataType.MedicationsAndSupplements]:
    ClinicalDataTypeLabel.MedicationsAndSupplements,
  [ClinicalDataType.ClinicalActivity]: ClinicalDataTypeLabel.ClinicalActivities
};

export class ClinicalTabName {
  private _clinicalStore: ClinicalStore;

  constructor(clinicalStore: ClinicalStore) {
    this._clinicalStore = clinicalStore;
  }
  async getName(type: DockableClinicalTab, contentId: string): Promise<string> {
    switch (type) {
      case ClinicalDataType.K10View:
        return await this.getReadonlyTabName(contentId, MeasurementType.K10);
      case ClinicalDataType.NPRSView:
        return await this.getReadonlyTabName(contentId, MeasurementType.NPRS);
      case ClinicalDataType.OREBROView:
        return await this.getReadonlyTabName(contentId, MeasurementType.OREBRO);
      case ClinicalDataType.PSFSView:
        return await this.getReadonlyTabName(contentId, MeasurementType.PSFS);
      default:
        return ClinicalDataTypeName[type];
    }
  }

  private async getReadonlyTabName(
    encounterId: string,
    measurementType: MeasurementType
  ): Promise<string> {
    const clinicalData: EncounterClinicalDataDto =
      await this._clinicalStore.getEncounterClinicalData({ encounterId });
    return `${measurementType} - ${DateTime.fromISOOrNow(
      clinicalData?.[measurementType.toLowerCase()]?.createLog?.createdDateTime
    ).toDayDefaultFormat()}`;
  }
}
