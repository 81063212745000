import { BhacPatientDto } from "@libs/gateways/bhac/bhacGateway.dtos.ts";
import { IBhacGateway } from "@libs/gateways/bhac/bhacGateway.interface.ts";
import { Store } from "@stores/types/store.type.ts";

export class BhacStore implements Store<BhacStore> {
  constructor(private gateway: IBhacGateway) {}

  async addPatient(patient: BhacPatientDto): Promise<string | undefined> {
    return await this.gateway.addPatient(patient);
  }
}
