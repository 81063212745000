import { action, runInAction } from "mobx";

import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { ICustomIntegrationsGateway } from "@libs/gateways/custom-inegration/CustomIntegration.interface.ts";
import { PhysitrackApiKeyDto } from "@libs/gateways/custom-inegration/CustomIntegrationGateway.dtos.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import type { Store } from "@stores/types/store.type.ts";

import { CustomIntegrationsRef } from "./CustomIntegrationsRef.ts";
import { CustomIntegrationsUi } from "./CustomIntegrationsUi.ts";

export class CustomIntegrationsStore
  implements Store<CustomIntegrationsStore, CustomIntegrationsRef>
{
  constructor(
    private gateway: ICustomIntegrationsGateway,
    public hub: IHubGateway
  ) {}
  root: IRootStore;
  ui = new CustomIntegrationsUi(this);

  private get notification() {
    return this.root.notification;
  }
  afterAttachRoot() {
    this.hub.onEntityEvent(
      Entity.PhysitrackApiKeyUpdate,
      this.onUpdatePhysitrackApiKey
    );
  }
  @action
  private onUpdatePhysitrackApiKey = async (event: EntityEventData) => {
    // we don't get etag from BE right now (it might be done in the future)
    try {
      if (
        event.action === EventAction.Create ||
        event.action === EventAction.Update
      ) {
        runInAction(() => {
          this.ui.recentlyUpdatedPhysitrackApiKey = event.key;
        });
      } else if (event.action === EventAction.Delete) {
        runInAction(() => {
          this.ui.recentlyUpdatedPhysitrackApiKey = `${event.key}-deleted`;
        });
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  async addPhysitrackApiKey(providerId: string): Promise<PhysitrackApiKeyDto> {
    return await this.gateway.addPhysitrackApiKey(providerId);
  }

  async regeneratePhysitrackApiKey(id: string): Promise<PhysitrackApiKeyDto> {
    return await this.gateway.regeneratePhysitrackApiKey(id);
  }

  async deletePhysitrackApiKey(id: string): Promise<void> {
    return await this.gateway.deletePhysitrackApiKey(id);
  }

  async getPhysitrackApiKeys(): Promise<PhysitrackApiKeyDto[]> {
    return await this.gateway.getPhysitrackApiKeys();
  }

  async getPhysitrackApiKeyByProviderId(
    providerId: string
  ): Promise<PhysitrackApiKeyDto | undefined> {
    const result = await this.gateway.searchPhysitrackApiKey({ providerId });
    return result.shift();
  }

  emailPhysitrackApiKey = (id: string) =>
    this.gateway.emailPhysitrackApiKey(id);
}
