import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import {
  CalendarEventReminderSearchDto,
  CalendarEventStatus
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { CalendarEvent } from "./CalendarEvent.ts";

export class CalendarEventReminderSearchModel {
  constructor(
    private store: IRootStore,
    private dto: CalendarEventReminderSearchDto
  ) {}

  get patientId() {
    return this.dto.patientId;
  }

  get providerId() {
    return this.dto.providerId;
  }

  @computed
  get attendanceStatus() {
    return this.calendarEvent.attendees.find(
      a => a.attendeeId === this.patientId
    )?.attendanceStatus;
  }

  @computed
  get patient() {
    return this.store.practice.contactsMap.get(this.dto.patientId);
  }

  @computed
  get provider() {
    return this.store.core.userMap.get(this.dto.providerId);
  }

  @computed
  get calendarEvent() {
    const item = this.store.booking.calendarEventsMap.get(
      this.dto.calendarEvent.id
    );
    if (item) return item;
    return new CalendarEvent(this.store, this.dto.calendarEvent);
  }

  @computed
  get message() {
    if (this.dto.message) {
      return this.store.booking.calendarEventRemindersMessagesMap.get(
        this.dto.message.id
      );
    }
    return undefined;
  }

  @computed
  get reminder() {
    if (this.dto.reminder) {
      return this.store.booking.calendarEventRemindersMap.get(
        this.dto.reminder.id
      );
    }
    return undefined;
  }

  @computed
  get orgUnit() {
    return this.store.core.orgUnitsMap.get(this.dto.calendarEvent.orgUnitId);
  }

  @computed
  get appointmentStatus() {
    if (this.calendarEvent.status === CalendarEventStatus.Confirmed) {
      return this.calendarEvent.appointmentStatus ?? "";
    } else return this.calendarEvent.status ?? "";
  }

  @computed
  get deliveryStatus() {
    return this.reminder?.calendarEventReminderStatusCode ?? "";
  }

  @computed
  get locationName() {
    return this.orgUnit?.name ?? "";
  }

  @computed
  get appointmentType() {
    return (
      (this.calendarEvent && this.calendarEvent.appointmentType?.text) ?? ""
    );
  }

  @computed
  get appointmentTime() {
    const hour = this.calendarEvent.startDateTime.hour;
    const minute = this.calendarEvent.startDateTime.minute;
    return DateTime.now().set({ hour, minute });
  }

  @computed
  get appointmentDate() {
    return this.calendarEvent.startDateTime;
  }

  @computed
  get phoneNumber() {
    return this.dto.message?.deliveryAddress ?? "";
  }

  @computed
  get attendeeStatus() {
    return this.dto.calendarEvent?.attendees.find(
      x => x.attendeeId === this.dto.patientId
    )?.attendeeStatus;
  }

  @computed
  get attendeeEncounterStatus() {
    return this.dto.calendarEvent?.attendees.find(
      x => x.attendeeId === this.dto.patientId
    )?.status;
  }

  @computed
  get patientName() {
    const patient = this.store.practice.contactsMap.get(this.dto.patientId);
    return patient?.name || "";
  }

  @computed
  get providerName() {
    const provider = this.store.core.userMap.get(this.dto.providerId);
    return provider?.name || "";
  }

  @computed
  get initials() {
    return this.provider?.initials;
  }

  @computed
  get disabled() {
    return (
      !!this.calendarEvent.cancellationReasonId ||
      (!!this.reminder?.dto.calendarEventStartTime &&
        this.calendarEvent.dto.startTime !==
          this.reminder?.dto.calendarEventStartTime)
    );
  }
}
