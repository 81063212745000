import { computed, observable, runInAction } from "mobx";

import { DateTime } from "@bps/utils";
import { ScheduleDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { Service } from "./Service.ts";

export class Schedule extends Model<ScheduleDto> {
  constructor(
    private root: IRootStore,
    dto: ScheduleDto
  ) {
    super(dto);
  }

  @observable
  _services?: Service[];

  @computed get services() {
    return this._services || [];
  }

  get name() {
    return this.dto.name;
  }

  get description() {
    return this.dto.description;
  }

  get benefitSchedule() {
    return this.dto.benefitSchedule;
  }

  get startDate() {
    return this.dto.startDate;
  }

  get endDate() {
    return this.dto.endDate;
  }

  // Note: this field is likely temporary until user IDs are stored in the
  // changelog, at such time this can be removed. For further details check
  // out PBI 18775
  get updatedByUserId() {
    return this.dto.updatedByUserId;
  }

  get isInactive(): boolean {
    if (this.endDate) {
      if (DateTime.fromISO(this.endDate).plus({ days: 1 }) < DateTime.now()) {
        return true;
      }
    }

    if (this.startDate) {
      if (DateTime.fromISO(this.startDate) > DateTime.now()) {
        return true;
      }
    }

    return false;
  }

  get activeBenefitFees() {
    return this.dto.activeBenefitFees || 0;
  }

  get activeNonBenefitFees() {
    return this.dto.activeNonBenefitFees || 0;
  }

  get activeFees() {
    return this.dto.activeFees || 0;
  }

  get totalFees() {
    return this.dto.totalFees || 0;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }

  loadServices = async (options: { ignoreCache?: boolean } = {}) => {
    if (!this._services || options.ignoreCache) {
      const services = await this.root.billing.getServices({
        scheduleId: this.id,
        take: this.totalFees
      });
      runInAction(() => {
        this._services = services.results;
      });
      return services;
    }
    return this._services;
  };
}
