import { computed } from "mobx";

import { ActivityDescriptionDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class ActivityDescription extends Model<ActivityDescriptionDto> {
  @computed get id() {
    return this.dto.id;
  }

  @computed get reasonText() {
    return this.dto.reasonText;
  }

  @computed get isSupplied() {
    return this.dto.isSupplied;
  }

  @computed get interval() {
    return this.dto.interval;
  }

  @computed get frequency() {
    return this.dto.frequency;
  }

  @computed get clinicallySignificant() {
    return this.dto.clinicallySignificant;
  }

  @computed get tenant() {
    return this.dto.tenant;
  }

  @computed get isInactive() {
    return this.dto.isInactive;
  }

  @computed get activityType() {
    return this.dto.activityType;
  }

  @computed get eTag() {
    return this.dto.eTag;
  }

  @computed get changeLog() {
    return this.dto.changeLog;
  }

  @computed get isOther() {
    return this.reasonText === "Other";
  }

  @computed get convertedDescriptionCode() {
    return this.dto.convertedDescriptionCode;
  }
}
