import { ItemType } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Allocation } from "@stores/billing/models/Allocation.ts";
import { CreditNote } from "@stores/billing/models/CreditNote.ts";
import { Invoice } from "@stores/billing/models/Invoice.ts";
import { Payment } from "@stores/billing/models/Payment.ts";
import { Refund } from "@stores/billing/models/Refund.ts";
import { TransactionBase } from "@stores/billing/models/Transaction.ts";
import { WriteOff } from "@stores/billing/models/WriteOff.ts";

export const isAllocation = (
  transacationNew: TransactionBase
): transacationNew is Allocation =>
  transacationNew.itemType === ItemType.Allocation;
export const isInvoice = (
  transacationNew: TransactionBase
): transacationNew is Invoice => transacationNew.itemType === ItemType.Invoice;
export const isWriteOff = (
  transacationNew: TransactionBase
): transacationNew is WriteOff =>
  transacationNew.itemType === ItemType.WriteOff;
export const isCreditNote = (
  transacationNew: TransactionBase
): transacationNew is CreditNote =>
  transacationNew.itemType === ItemType.CreditNote;
export const isPayment = (
  transacationNew: TransactionBase
): transacationNew is Payment => transacationNew.itemType === ItemType.Payment;
export const isRefund = (
  transacationNew: TransactionBase
): transacationNew is Refund => transacationNew.itemType === ItemType.Refund;
export const isInvoiceItemReferenceTransaction = (
  transacationNew: TransactionBase
): transacationNew is CreditNote | WriteOff | Allocation => {
  return transacationNew["invoiceReferences"] !== undefined;
};
