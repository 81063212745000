import { computed, observable } from "mobx";

import { RequestError } from "@bps/http-client";
import { DateTime, Duration, isDefined } from "@bps/utils";
import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import { InvoiceStatus } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  AppointmentStatusCode,
  AttendeeTypeEnum,
  CalendarEventAttendeeDto,
  CalendarEventDto,
  CalendarEventStatus,
  CalendarEventType,
  ValidateRecurrenceSeriesConflictDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CalendarEventExtensionDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { diff } from "@libs/models/model.utils.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { User } from "@stores/core/models/User.ts";
import {
  Contact,
  contactFirstNameCompareFn
} from "@stores/practice/models/Contact.ts";

import { CalendarEventTypeRef } from "../ref/CalendarEventTypeRef.ts";
import { AppointmentCancellationReason } from "./AppointmentCancellationReason.ts";
import { AppointmentType } from "./AppointmentType.ts";

export class CalendarEvent extends Model<CalendarEventDto> {
  @observable calendarEventExtension: CalendarEventExtensionDto | undefined;

  constructor(
    private store: IRootStore,
    dto: CalendarEventDto
  ) {
    super(dto);
  }

  hasMultipleLocks: boolean;

  get practice() {
    return this.store.practice;
  }

  userHasOpenEncounterWithPatient(patientId: string): boolean {
    return !!this.calendarEventExtension?.openEncounters?.some(
      x => x.userId === this.store.core.userId && x.patientId === patientId
    );
  }

  get hasOpenEncounters(): boolean {
    if (
      this.appointmentStatus !== AppointmentStatusCode.WithProvider &&
      this.calendarEventExtension &&
      this.calendarEventExtension.openEncounters &&
      this.calendarEventExtension.openEncounters.length > 0
    ) {
      return true;
    }
    return false;
  }

  @computed
  get locationName() {
    return this.store.core.getLocationName(this.orgUnitId);
  }

  @computed
  get duration(): Duration {
    return this.endDateTime.diff(this.startDateTime, ["minutes"]);
  }

  @computed
  get startDateTime() {
    return DateTime.fromISO(this.dto.startTime);
  }

  @computed
  get endDateTime() {
    return DateTime.fromISO(this.dto.endTime);
  }

  @computed get startTime() {
    return DateTime.fromISOOrNow(this.dto.startTime);
  }

  @computed
  get createdDate() {
    return DateTime.fromISO(this.dto.changeLog?.createdDate);
  }

  @computed
  get type() {
    return this.dto.type;
  }

  @computed
  get priority() {
    return this.dto.priority;
  }

  @computed
  get status() {
    return this.dto.status;
  }

  @computed
  get appointmentTypeId() {
    return this.dto.appointmentTypeId;
  }

  @computed
  get bookedBy() {
    return this.dto.bookedBy;
  }

  @computed
  get bookedByType() {
    return this.dto.bookedByType;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }

  @computed
  get orgUnitId() {
    return this.dto.orgUnitId;
  }

  @computed
  get content() {
    return this.dto.content;
  }

  @computed
  get appointmentStatus() {
    return this.dto.appointmentStatus;
  }

  @computed
  get calendarEventRecurrenceId() {
    return this.dto.calendarEventRecurrenceId;
  }

  @computed
  get isPartOfRecurrence() {
    return this.dto.isPartOfRecurrence;
  }

  @computed
  get purpose() {
    return this.dto.purpose;
  }

  @computed
  get externalPatient() {
    return this.dto.externalPatient;
  }

  @computed
  get invoiceId(): string | undefined {
    return this.calendarEventExtension?.invoiceId;
  }

  @computed
  get invoiceStatus() {
    return this.dto.invoiceStatus;
  }

  @computed
  get cancellationReasonId() {
    return this.dto.cancellationReasonId;
  }

  @computed
  get cancellationDateTime() {
    return this.dto.cancellationDateTime
      ? DateTime.fromISO(this.dto.cancellationDateTime)
      : undefined;
  }

  @computed
  get attendanceStatus() {
    return this.dto.attendees.find(a => a.attendeeId === this.contactId)
      ?.attendanceStatus;
  }

  @computed
  get reason() {
    return this.dto.reason;
  }

  @computed
  get isUnbilled() {
    const invoiceExists =
      this.invoiceStatus && this.invoiceStatus !== InvoiceStatus.Cancelled;

    const statuses = [
      AppointmentStatusCode.WithProvider,
      AppointmentStatusCode.Finalised
    ];

    return (
      !invoiceExists &&
      !!this.appointmentStatus &&
      statuses.includes(this.appointmentStatus)
    );
  }

  @computed
  get appointmentType(): AppointmentType | undefined {
    return this.appointmentTypeId
      ? this.store.booking.appointmentTypesMap.get(this.appointmentTypeId)
      : undefined;
  }

  @sharePendingPromise()
  async loadAppointmentType(): Promise<AppointmentType | undefined> {
    if (this.appointmentTypeId) {
      await this.store.booking.getAppointmentType(this.appointmentTypeId);
    }
    return this.appointmentType;
  }

  @computed
  get appointment() {
    return this.dto.appointment;
  }

  @computed
  get cancellationReason(): AppointmentCancellationReason | undefined {
    return this.dto.cancellationReasonId
      ? this.store.booking.appointmentCancellationReasonsMap.get(
          this.dto.cancellationReasonId
        )
      : undefined;
  }

  get cancellationText() {
    return this.dto.cancellationText;
  }

  @computed
  get typeRef(): CalendarEventTypeRef | undefined {
    return this.store.booking.ref.calendarEventTypes.map.get(this.type);
  }

  @computed get patientId() {
    return this.dto.attendees.find(x => x.type === AttendeeTypeEnum.patient)
      ?.attendeeId;
  }

  @computed get arrivedTime() {
    return this.attendeesPatientAndContact[0]?.arrivedTime;
  }

  @computed get contactId() {
    return this.dto.attendees.find(
      x =>
        x.type === AttendeeTypeEnum.contact ||
        x.type === AttendeeTypeEnum.patient
    )?.attendeeId;
  }

  @computed get attendeesPatientAndContact() {
    return this.dto.attendees.filter(
      x =>
        x.type === AttendeeTypeEnum.contact ||
        x.type === AttendeeTypeEnum.patient
    );
  }

  // ⚠ Includes cancelled attendees
  @computed get attendees(): CalendarEventAttendeeDto[] {
    return this.dto.attendees;
  }

  @computed get activeAttendees(): CalendarEventAttendeeDto[] {
    return this.attendeesPatientAndContact.filter(
      x => x.status !== CalendarEventStatus.Cancelled
    );
  }

  @computed get cancelledAttendees(): CalendarEventAttendeeDto[] {
    return this.attendeesPatientAndContact.filter(
      x => x.status === CalendarEventStatus.Cancelled
    );
  }

  @computed get dnaAttendees(): CalendarEventAttendeeDto[] {
    return this.attendeesPatientAndContact.filter(
      x => x.attendeeStatus === AppointmentStatusCode.DidNotAttend
    );
  }

  @computed get userId() {
    const userAttendee = this.dto.attendees.find(
      x => x.type === AttendeeTypeEnum.user
    );

    if (!userAttendee) {
      throw Error(
        `Could not find a USER attendee id in appointment ${this.dto.id}`
      );
    }
    return userAttendee.attendeeId;
  }

  @computed
  get user() {
    return this.store.core.userMap.get(this.userId);
  }

  @computed
  get contact() {
    return this.contactId
      ? this.store.practice.contactsMap.get(this.contactId)
      : undefined;
  }

  get sortedAttendees(): CalendarEventAttendeeDto[] {
    return this.activeAttendees.sort((a, b) => {
      const contactA = this.practice.contactsMap.get(a.attendeeId);
      const contactB = this.practice.contactsMap.get(b.attendeeId);

      return contactFirstNameCompareFn(contactA, contactB);
    });
  }

  get sortedAttendeesAsContacts(): Contact[] {
    return this.sortedAttendees
      .map(a => this.practice.contactsMap.get(a.attendeeId))
      .filter(isDefined);
  }

  @computed
  get bookedByUser(): User | undefined {
    return this.bookedBy
      ? this.store.core.userMap.get(this.bookedBy)
      : undefined;
  }

  @computed
  get isPatientCalendarEvent(): boolean {
    return (
      this.type !== CalendarEventType.Meeting &&
      this.type !== CalendarEventType.Unavailable
    );
  }

  @computed
  get providerComment(): string | undefined {
    return this.dto.appointment?.providerComment;
  }

  @computed
  get noChargeComment(): string | undefined {
    // Assumes this is a regular (not group) appointment for now
    return this.contactId
      ? this.getPropertyOfAttendee("noChargeComment", this.contactId)
      : undefined;
  }

  @computed
  get hasEncounter(): boolean | undefined {
    return this.calendarEventExtension?.hasEncounter;
  }

  @computed
  get maxParticipants(): number | undefined {
    return this.dto.maxParticipants;
  }

  @computed
  get groupDescription(): string | undefined {
    return this.dto.groupDescription;
  }

  @computed
  get isGroupAppointment(): boolean {
    return !!this.dto.maxParticipants;
  }

  @computed
  get groupAppointmentName(): string | undefined {
    return this.groupDescription ?? this.appointmentType?.name;
  }

  @computed
  get canShowPatientMatchReview(): boolean {
    return (
      !this.contact &&
      !this.isGroupAppointment &&
      this.type === CalendarEventType.Appointment &&
      this.store.core.hasPermissions([
        Permission.PatientMatchAllowed,
        Permission.CalendarEventWrite
      ])
    );
  }

  validate = async () => {
    const validateCalendarEventParams = {
      id: this.id,
      startTime: this.dto.startTime,
      endTime: this.dto.endTime,
      calendarEventRecurrenceId: this.dto.calendarEventRecurrenceId
    };
    return await this.store.booking.validateCalendarEvent({
      ...validateCalendarEventParams
    } as ValidateRecurrenceSeriesConflictDto);
  };

  /***
   * Save the calendar event through the store
   * using any pending change that has been applied through updateFromPatch.
   */
  save = async () => {
    if (!this.originalDto) {
      return Promise.resolve(this);
    }

    try {
      return await this.store.booking.updateCalendarEvent({
        ...diff(this.originalDto, this.dto),
        id: this.id
      });
    } catch (error) {
      this.undoPendingChanges();
      if (error instanceof RequestError) {
        return error;
      } else {
        throw error;
      }
    }
  };

  getCalendarEventExtensionAttendee = (attendeeId: string) => {
    const attendeeExt =
      this.calendarEventExtension?.calendarEventAttendeeExtensions?.find(
        att => att.attendeeId === attendeeId
      );

    return attendeeExt;
  };

  getInvoiceIdByAttendee = (attendeeId: string): string | undefined => {
    const extension =
      this.calendarEventExtension?.calendarEventAttendeeExtensions?.find(
        e => e.attendeeId === attendeeId
      );

    return extension?.invoiceId;
  };

  getPropertyOfAttendee = <T extends keyof CalendarEventAttendeeDto>(
    property: T,
    attendeeId: string
  ) => {
    const attendee = this.getAttendee(attendeeId);
    return attendee ? attendee[property] : undefined;
  };

  getAttendee = (attendeeId: string) => {
    return this.attendees.find(a => a.attendeeId === attendeeId);
  };

  @computed
  get claimId() {
    return Array.from(this.store.acc.claimAppointmentMap.values()).find(
      ca => ca.calendarEventId === this.id
    )?.claimId;
  }

  @computed
  get claim(): Claim | undefined {
    if (this.claimId) {
      return this.store.acc.claimsMap.get(this.claimId);
    }
    return undefined;
  }

  async loadClaim() {
    if (this.claim) {
      return this.claim;
    } else {
      await this.store.acc.getClaimAppointmentDtos({
        calendarEventId: this.id
      });
      if (this.claimId) {
        return this.store.acc.getClaim(this.claimId);
      } else {
        return undefined;
      }
    }
  }
}

export const toAttendees = (data: {
  userId?: string;
  contactId?: string;
}): CalendarEventAttendeeDto[] => {
  const attendees: CalendarEventAttendeeDto[] = [];
  if (data.contactId) {
    attendees.push({
      attendeeId: data.contactId,
      type: AttendeeTypeEnum.contact
    });
  }

  if (data.userId) {
    attendees.push({ attendeeId: data.userId, type: AttendeeTypeEnum.user });
  }

  return attendees;
};

export const isKnownAttendeeType = (attendee: CalendarEventAttendeeDto) =>
  attendee &&
  (attendee.type === AttendeeTypeEnum.user ||
    attendee.type === AttendeeTypeEnum.patient ||
    attendee.type === AttendeeTypeEnum.contact);
