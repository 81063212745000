import { computed } from "mobx";

import { PatientNoticeDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class PatientNotice extends Model<PatientNoticeDto> {
  @computed get id() {
    return this.dto.id;
  }

  @computed get patientId() {
    return this.dto.patientId;
  }

  @computed get comment() {
    return this.dto.comment;
  }
  @computed get isClinical() {
    return this.dto.isClinical;
  }
  @computed get isAdmin() {
    return this.dto.isAdmin;
  }

  @computed get priority() {
    return this.dto.priority;
  }

  @computed get type() {
    return this.dto.type;
  }
}
