import { observable, runInAction } from "mobx";
import { createElement } from "react";

import { Link, Stack } from "@bps/fluent-ui";
import { DateTime, isDefined } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  FormDeploymentOptionsDTO,
  FormDeploymentResultDTO,
  FormInstanceDTO,
  FormsChannelType,
  FormTemplateDTO,
  FormTemplateTypeCode,
  GetFormTemplateDTO
} from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { IFormsGateway } from "@libs/gateways/forms/FormsGateway.interface.ts";
import {
  CommunicationDto,
  CommunicationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { permission } from "@stores/decorators/permission.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import type { Store } from "@stores/types/store.type.ts";

import { FormUi } from "./FormUi.ts";

export class FormsStore implements Store<FormsStore> {
  constructor(
    private gateway: IFormsGateway,
    private hub: IHubGateway
  ) {}
  ui = new FormUi();
  formInstanceMap = observable.map<string, FormInstanceDTO[]>();
  templatesMap = observable.map<string, FormTemplateDTO>();
  templatesRequestMap = observable.map<string, FormTemplateDTO[]>();
  root: IRootStore;

  private get notification() {
    return this.root.notification;
  }

  private get practice() {
    return this.root.practice;
  }

  private get comms() {
    return this.root.comms;
  }

  afterAttachRoot() {
    this.ui.attachRoot(this.root);
    this.hub.onEntityEvent(Entity.FormInstance, this.onUpdateFormInstance);
    this.hub.onEntityEvent(Entity.FormTemplate, this.onUpdateFormTemplate);
  }

  private onUpdateFormTemplate = async (event: EntityEventData) => {
    const formTemplateId = event.id;
    const updatedTemplate = await this.gateway.getFormTemplate(formTemplateId);
    runInAction(() => {
      this.templatesMap.set(updatedTemplate.id, updatedTemplate);
      this.templatesRequestMap.clear();
    });
  };

  private onUpdateFormInstance = async (event: EntityEventData) => {
    const formInstanceId = event.id;
    const updatedInstance = await this.gateway.getFormInstance(formInstanceId);
    const patientForms = this.formInstanceMap.get(updatedInstance.patientId);
    runInAction(() => {
      if (!patientForms) {
        this.formInstanceMap.set(updatedInstance.patientId, [updatedInstance]);
      } else {
        const index = patientForms.findIndex(f => f.id === updatedInstance.id);
        if (index > -1) {
          patientForms[index] = updatedInstance;
        } else {
          patientForms.push(updatedInstance);
        }

        this.formInstanceMap.set(updatedInstance.patientId, patientForms);
      }
    });
  };

  @permission([Permission.SendForms])
  async getTemplates(request: GetFormTemplateDTO): Promise<FormTemplateDTO[]> {
    const requestAsJson = JSON.stringify(request);
    const templates = await this.gateway.getFormTemplates(request);
    runInAction(() => {
      if (!this.templatesRequestMap.has(requestAsJson)) {
        this.templatesRequestMap.set(requestAsJson, templates);
      }
    });
    return this.templatesRequestMap.get(requestAsJson) ?? [];
  }

  @permission<FormTemplateDTO>([Permission.SendForms])
  async getSingleTemplateByCode(
    code: FormTemplateTypeCode
  ): Promise<FormTemplateDTO | undefined> {
    const template = Array.from(this.templatesMap.values()).filter(
      t => t.name === code
    );

    if (template.length === 1) {
      return template[0];
    } else {
      const templates = await this.gateway.getFormTemplates({ type: code });
      if (templates.length === 1) {
        runInAction(() => {
          this.templatesMap.set(templates[0].id, templates[0]);
        });

        return templates[0];
      } else
        throw new Error(
          `Only single template with the code ${code} is expected.`
        );
    }
  }

  @permission<FormTemplateDTO>([Permission.SendForms])
  private async getTemplateById(id: string) {
    if (!this.templatesMap.has(id)) {
      const template = await this.gateway.getFormTemplate(id);
      runInAction(() => {
        this.templatesMap.set(id, template);
      });
    }
    return this.templatesMap.get(id);
  }

  @permission<FormTemplateDTO[]>([Permission.SendForms], { defaultValue: [] })
  async getTemplateByIds(ids: string[]): Promise<FormTemplateDTO[]> {
    const templatePromises = ids.map(id => this.getTemplateById(id));
    const result = await Promise.all(templatePromises);
    return result.filter(isDefined);
  }

  @permission<FormInstanceDTO[]>([Permission.SendForms], { defaultValue: [] })
  async getFormInstances(
    patientId: string,
    options: { ignoreCache: boolean } = {
      ignoreCache: false
    }
  ): Promise<FormInstanceDTO[]> {
    if (!options.ignoreCache) {
      const forms = this.formInstanceMap.get(patientId);

      if (forms) {
        return forms;
      }
    }

    try {
      const result = await this.gateway.getFormInstances({ patientId });
      runInAction(() => {
        this.formInstanceMap.set(patientId, result);
      });

      const forms = this.formInstanceMap.get(patientId);
      if (forms) {
        return forms;
      }
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading form instances"
      });
      throw error;
    }

    return [] as FormInstanceDTO[];
  }
  @permission([Permission.SendForms])
  async cancelForm(formInstance: FormInstanceDTO) {
    formInstance.cancelled = true;
    try {
      await this.gateway.updateForm(formInstance);
      const sentDate = DateTime.fromISO(formInstance.sentDate);
      this.notification.warn(
        `The ${
          formInstance.formTemplateName
        } form sent ${sentDate.toDayDefaultFormat()} has been cancelled.`
      );
    } catch (error) {
      this.notification.error(error);
      throw error;
    }
  }
  @permission([Permission.SendForms])
  async deployFormByCode(options: {
    code: FormTemplateTypeCode;
    context: Record<string, string>;
    expiry?: DateTime;
    qrCode: boolean;
  }) {
    const { code, context, expiry, qrCode } = options;

    const formTemplate = await this.getSingleTemplateByCode(code);

    const expiryISO = expiry ? expiry.toISO() : undefined;
    return (
      formTemplate &&
      this.deployForm({
        formTemplate,
        context,
        expiry: expiryISO,
        channelCode: qrCode ? FormsChannelType.QrCode : undefined
      })
    );
  }
  @permission([Permission.SendForms])
  async deployForm(
    options: FormDeploymentOptionsDTO
  ): Promise<FormDeploymentResultDTO | undefined> {
    try {
      const endOfDayExpiry = { ...options };

      if (options.expiry) {
        const endOfDay = DateTime.fromISO(options.expiry).endOf("day");
        endOfDayExpiry.expiry = endOfDay.toISO();
      } else {
        options.expiry = DateTime.now().plus({ weeks: 1 }).endOf("day").toISO();
      }

      const result = await this.gateway.deployForm(endOfDayExpiry);
      if (result) {
        this.showDeploymentFeedback(result, options);
      }

      const patientId =
        options.context["PatientId"] ?? options.context["ClinicalPatientId"];

      runInAction(() => {
        if (result) {
          const patientForms = this.formInstanceMap.get(patientId);
          if (patientForms) {
            const index = patientForms.findIndex(
              f => f.id === result.formInstance.id
            );
            if (index > -1) {
              patientForms[index] = result.formInstance;
            } else {
              patientForms.unshift(result.formInstance);
            }
          } else {
            this.formInstanceMap.set(patientId, [result.formInstance]);
          }
        }
      });

      return result;
    } catch (error) {
      this.notification.error(error);
      throw error;
    }
  }

  private showDeploymentFeedback(
    result: FormDeploymentResultDTO,
    form: FormDeploymentOptionsDTO
  ) {
    const displayPreRelease = this.root.core.hasPermissions(
      Permission.PreRelease
    );
    if (form.channelCode === FormsChannelType.QrCode) {
      return;
    }

    const link =
      displayPreRelease &&
      createElement(Link, {
        children: "Preview",
        href: result.hostingUrl,
        target: "_blank"
      });

    const text = createElement(Stack, {
      children: `The ${form.formTemplate.name} form has been sent.`
    });

    const message = createElement(
      Stack,
      {
        horizontal: true
      },
      text,
      link
    );
    this.notification.success(message);
  }

  public getDetails: (patientId: string) => Promise<{
    sendOptions: CommunicationDto[];
    patient?: Contact;
  }> = async patientId => {
    const sendOptions: CommunicationDto[] = [];

    const patient = await this.practice.getContact(patientId, {
      includeContactPreferences: true
    });
    if (
      !patient.contactPreferences?.formNotifyPreferences
        ?.preferredCommChannelTypeCode
    ) {
      patient.communications
        .filter(
          x =>
            x.type === CommunicationType.Email ||
            x.type === CommunicationType.Mobile
        )
        .forEach(x => sendOptions.push(x));
    }
    return { sendOptions, patient };
  };
}
