import { action, observable, reaction, toJS } from "mobx";

import { StorageProperties } from "@libs/constants/storage-properties.ts";
import { Country } from "@libs/enums/country.enum.ts";
import {
  BodyArea,
  ClinicalDataType,
  PermanentClinicalTab,
  TodaysStructuredNotesUserSettings,
  TreeView
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalViewDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { patientTabKey } from "@stores/clinical/utils/clinical.utils.ts";

import { ClinicalTabItem } from "./ClinicalTabItem.ts";
import { ClinicalDataTypeName } from "./ClinicalTabName.ts";

export enum BrushKindEnum {
  Pencil = "pencil",
  Line = "line",
  Transparent = "transparent",
  Ellipse = "ellipse"
}

export type Options = {
  color: string;
  width: number;
  fill: string;
  kind?: BrushKindEnum;
};

export type BodyExaminationState = {
  bodyParts?: BodyArea[];
  showDiagramEditorArea?: boolean;
  brush?: Options;
  currentBodyArea?: BodyArea;
  selectedBodyParts?: BodyArea[];
};

type State = {
  sidePanelSize: TreeViewSize;
  sidePanelTab: undefined | string;
  pastVisitId: undefined | string;
  tabs: ClinicalTabItem[];
  collapseClinicalTools: { [key: string]: boolean };
  defaultTab: undefined | PermanentClinicalTab;
  previousNavigatedSidePanelTab: string | undefined;
  todaysNotesUserSettings: Map<string, TodaysStructuredNotesUserSettings>;
  checkedOutcomeMeasures: ClinicalDataType[];
  currentClinicalClaimAdjustmentId: string | undefined;
  currentClaimReviewClaimId: string | undefined;
  tabHistory: ClinicalTabItem[];
  bodyExam: BodyExaminationState;
};

export class ClinicalTabState {
  public patientId: string;
  private encounterId?: string;

  constructor(
    public root: IRootStore,
    params: {
      patientId: string;
      encounterId?: string;
    }
  ) {
    this.patientId = params.patientId;
    this.encounterId = params.encounterId;
    this.clinicalView = this.root.userExperience.userSettingMap?.get(
      this.root.core.userId
    )?.clinicalView;
    this.getCurrentState();
  }

  public syncState = reaction(
    () => toJS(this._state),
    data => {
      this.syncStateWithSessionStorage(data);
    },
    {
      delay: 500
    }
  );

  public clinicalView: ClinicalViewDto | undefined;

  public disposeReaction = () => {
    return this.syncState && this.syncState();
  };

  protected _state: State = observable.object({
    sidePanelSize: TreeViewSize.Default,
    sidePanelTab: undefined,
    pastVisitId: undefined,
    tabs: [],
    collapseClinicalTools: {},
    defaultTab: undefined,
    previousNavigatedSidePanelTab: undefined,
    todaysNotesUserSettings: new Map<
      string,
      TodaysStructuredNotesUserSettings
    >(),
    checkedOutcomeMeasures: [ClinicalDataType.PSFS, ClinicalDataType.NPRS],
    currentClinicalClaimAdjustmentId: undefined,
    currentClaimReviewClaimId: undefined,
    tabHistory: [],
    bodyExam: {}
  });

  private get tabKey(): string {
    return patientTabKey(this.patientId, this.encounterId);
  }

  @action
  private getCurrentState = (): void => {
    const clinicalRecords = sessionStorage.getItem(
      StorageProperties.clinicalRecordsState
    );

    // get clinicalRecords data from sessionStorage
    if (clinicalRecords) {
      // parse clinicalRecords data from sessionStorage
      const clinicalRecordsObj: Map<string, State> =
        JSON.parse(clinicalRecords);

      const clinicalRecordsMap = new Map(clinicalRecordsObj);

      const patientState = clinicalRecordsMap.get(
        `${this.tabKey}${this.encounterId === undefined ? "-view" : ""}`
      );
      // if patientMap data exists set values from sessionStorage

      if (patientState) {
        this._state.sidePanelSize = patientState.sidePanelSize;
        this._state.sidePanelTab = patientState.sidePanelTab;
        this._state.pastVisitId = patientState.pastVisitId;
        this._state.tabs = patientState.tabs;
        this._state.collapseClinicalTools = patientState.collapseClinicalTools;
        this._state.defaultTab = patientState.defaultTab;
        this._state.todaysNotesUserSettings = new Map(
          Object.entries(patientState.todaysNotesUserSettings)
        );
        this._state.checkedOutcomeMeasures =
          patientState.checkedOutcomeMeasures;
        this._state.currentClinicalClaimAdjustmentId =
          patientState.currentClinicalClaimAdjustmentId;
        this._state.currentClaimReviewClaimId =
          patientState.currentClaimReviewClaimId;
        this._state.bodyExam = patientState.bodyExam;
        return;
      }
    }

    const newState: State = {
      sidePanelSize: TreeViewSize.Default,
      sidePanelTab: this.clinicalView?.selectedTreeView,
      tabs: this.tabs,
      pastVisitId: undefined,
      collapseClinicalTools: {},
      defaultTab: this.defaultTab,
      previousNavigatedSidePanelTab: this.clinicalView?.selectedTreeView,
      todaysNotesUserSettings: new Map<
        string,
        TodaysStructuredNotesUserSettings
      >(),
      checkedOutcomeMeasures: [ClinicalDataType.PSFS, ClinicalDataType.NPRS],
      currentClinicalClaimAdjustmentId: undefined,
      currentClaimReviewClaimId: undefined,
      tabHistory: [],
      bodyExam: {}
    };

    const willBeActiveTab = newState.tabs.find(
      x => x.type === this.clinicalView?.defaultDockView
    );
    if (willBeActiveTab) {
      const index = newState.tabs.indexOf(willBeActiveTab);
      newState.tabs[index].isActive = true;
    } else {
      newState.tabs[0].isActive = true;
    }

    // just update state by newState
    this._state.sidePanelTab =
      newState.sidePanelTab ??
      (this.root.core.tenantDetails?.country === Country.NewZealand
        ? TreeView.Conditions
        : TreeView.Timeline);
    this._state.pastVisitId = newState.pastVisitId;
    this._state.tabs = newState.tabs;
    this._state.collapseClinicalTools = newState.collapseClinicalTools;
    this._state.defaultTab = newState.defaultTab;
    this._state.currentClinicalClaimAdjustmentId =
      newState.currentClinicalClaimAdjustmentId;
    this._state.currentClaimReviewClaimId = newState.currentClaimReviewClaimId;
    this._state.bodyExam = newState.bodyExam;

    return;
  };

  syncStateWithSessionStorage = (updates: State): void => {
    const clinicalRecords = sessionStorage.getItem(
      StorageProperties.clinicalRecordsState
    );
    // if we have data in storage already, just update current patient data
    if (clinicalRecords) {
      const clinicalRecordsObj: Map<string, State> =
        JSON.parse(clinicalRecords);

      const clinicalRecordsMap = new Map(clinicalRecordsObj);
      clinicalRecordsMap.set(
        `${this.tabKey}${this.encounterId === undefined ? "-view" : ""}`,
        updates
      );

      // ⚠ ️Excluded isDirty state property from persisting in sessionStorage since clinical from data persisted in js only
      // After app reloading we are resetting persisted form data (stashed clinicalData) and isDirty state should be reset too. Ilya S.
      const readyToPersistClinicalRecordState = Array.from(
        clinicalRecordsMap
      ).map(([key, { tabs, ...restState }]) => [
        key,
        { ...restState, tabs: tabs.map(({ isDirty, ...restTab }) => restTab) }
      ]);

      sessionStorage.setItem(
        StorageProperties.clinicalRecordsState,
        JSON.stringify(readyToPersistClinicalRecordState)
      );
      // add the first record in the sessionStorage
    } else {
      const newMap = new Map().set(
        `${this.tabKey}${this.encounterId === undefined ? "-view" : ""}`,
        updates
      );
      sessionStorage.setItem(
        StorageProperties.clinicalRecordsState,
        JSON.stringify(Array.from(newMap))
      );
    }
  };

  private get defaultTab(): PermanentClinicalTab | undefined {
    if (this.root.clinical.activeRecordIsView)
      return PermanentClinicalTab.PatientSummary;

    return (
      (this.clinicalView?.defaultDockView as PermanentClinicalTab) ??
      PermanentClinicalTab.TodaysNotes
    );
  }

  private get tabs(): ClinicalTabItem[] {
    return this.root.clinical.activeRecordIsView
      ? this.defaultTabs.filter(
          x => x.type !== PermanentClinicalTab.TodaysNotes
        )
      : this.defaultTabs;
  }

  get defaultTabs(): ClinicalTabItem[] {
    return this._defaultTabs;
  }

  private readonly _defaultTabs: ClinicalTabItem[] = [
    new ClinicalTabItem({
      type: PermanentClinicalTab.TodaysNotes,
      title: ClinicalDataTypeName[PermanentClinicalTab.TodaysNotes]
    }),
    new ClinicalTabItem({
      type: PermanentClinicalTab.PatientSummary,
      title: ClinicalDataTypeName[PermanentClinicalTab.PatientSummary]
    })
  ];
}
