import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { RecurrenceDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { diff } from "@libs/models/model.utils.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class RecurrenceModel extends Model<RecurrenceDto> {
  constructor(
    private store: IRootStore,
    dto: RecurrenceDto
  ) {
    super(dto);
  }

  get core() {
    return this.store.core;
  }

  @computed
  get id() {
    return this.dto.id;
  }

  @computed
  get orgUnitId() {
    return this.dto.orgUnitId;
  }

  @computed
  get type() {
    return this.dto.type;
  }

  @computed
  get bookedBy() {
    return this.dto.bookedBy;
  }

  @computed
  get seriesId() {
    return this.dto.seriesId;
  }

  @computed
  get startDate() {
    return DateTime.fromISO(this.dto.startDate);
  }

  @computed
  get endDate() {
    return this.until;
  }

  @computed
  get startDateTime() {
    return DateTime.fromISODateAndTime(this.dto.startDate, this.dto.startTime);
  }

  @computed
  get endDateTime() {
    return DateTime.fromISODateAndTime(
      this.dto.endDate ? this.dto.endDate : this.dto.startDate,
      this.dto.endTime
    );
  }

  @computed
  get purpose() {
    return this.dto.purpose;
  }

  @computed
  get count() {
    return this.dto.recurrenceRule.count;
  }

  @computed
  get frequency() {
    return this.dto.recurrenceRule.frequency;
  }

  @computed
  get interval() {
    return this.dto.recurrenceRule.interval;
  }

  @computed
  get until() {
    return this.dto.recurrenceRule.until;
  }

  @computed
  get dayRecur() {
    return this.dto.recurrenceRule.dayRecur;
  }

  @computed
  get monthRecur() {
    return this.dto.recurrenceRule.monthRecur;
  }

  @computed
  get monthDayRecur() {
    return this.dto.recurrenceRule.monthDayRecur;
  }

  @computed
  get weekPosition() {
    return this.dto.recurrenceRule.weekPosition;
  }

  @computed
  get createdDate() {
    return this.dto.changeLog && this.dto.changeLog.createdDate;
  }

  @computed get userId() {
    const userAttendee = this.dto.attendees.find(x => x.type === "USER");

    if (!userAttendee) {
      throw Error(
        `Could not find a USER attendee id in appointment ${this.dto.id} `
      );
    }
    return userAttendee.attendeeId;
  }

  @computed
  get user() {
    return this.store.core.userMap.get(this.userId);
  }

  /***
   * Save the recurrence through the store
   * using any pending change that has been applied through updateFromPatch.
   */
  save = async () => {
    if (!this.originalDto) {
      return Promise.resolve(this);
    }

    try {
      return await this.store.booking.updateRecurrence({
        ...diff(this.originalDto, this.dto),
        id: this.id
      });
    } catch (error) {
      this.undoPendingChanges();
      throw error;
    }
  };
}
