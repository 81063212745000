import { computed } from "mobx";

import { AppointmentCancellationReasonDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class AppointmentCancellationReason extends Model<AppointmentCancellationReasonDto> {
  @computed
  get text() {
    return this.dto.text;
  }

  @computed
  get isInactive() {
    return !!this.dto.isInactive;
  }

  @computed
  get isDeleted() {
    return !!this.dto.isDeleted;
  }

  @computed
  get isSystemManaged() {
    return this.dto.isSystemManaged;
  }
}
