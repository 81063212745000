import { observable } from "mobx";

import { CustomIntegrationsStore } from "@stores/custom-integration/CustomIntegrationsStore.ts";

export class CustomIntegrationsUi {
  constructor(private store: CustomIntegrationsStore) {}

  @observable
  recentlyUpdatedPhysitrackApiKey?: string;
}
