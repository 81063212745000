import { computed } from "mobx";

import { SecGroupAccessRequestDto } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class SecGroupAccessRequest extends Model<SecGroupAccessRequestDto> {
  @computed
  get id() {
    return this.dto.id;
  }

  @computed
  get userId() {
    return this.dto.userId;
  }

  @computed
  get secGroupId() {
    return this.dto.secGroupId;
  }

  @computed
  get status() {
    return this.dto.status;
  }

  @computed
  get eTag() {
    return this.dto.eTag;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }
}
