import { computed } from "mobx";

import {
  AttendeeTypeEnum,
  WaitingListItemDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class WaitingListItemModel extends Model<WaitingListItemDto> {
  constructor(
    dto: WaitingListItemDto,
    private root: IRootStore
  ) {
    super(dto);
  }

  @computed
  get attendees() {
    return this.dto.attendees;
  }

  @computed
  get user() {
    const attendee = this.attendees.find(a => a.type === AttendeeTypeEnum.user);
    if (attendee) {
      return {
        ...attendee,
        model: this.root.core.userMap.get(attendee.attendeeId)
      };
    }
    return undefined;
  }

  @computed
  get contact() {
    const attendee = this.attendees.find(a => a.type !== AttendeeTypeEnum.user);
    if (attendee) {
      return {
        ...attendee,
        model: this.root.practice.contactsMap.get(attendee.attendeeId)
      };
    }
    return undefined;
  }

  @computed
  get appointmentTypeId() {
    return this.dto.appointmentTypeId;
  }

  @computed
  get appointmentType() {
    if (!this.dto.appointmentTypeId) return undefined;
    return this.root.booking.appointmentTypesMap.get(
      this.dto.appointmentTypeId
    );
  }

  @computed
  get startTime() {
    return this.dto.startTime;
  }

  @computed
  get endTime() {
    return this.dto.endTime;
  }

  @computed
  get until() {
    return this.dto.until;
  }

  @computed
  get priority() {
    return this.dto.priority;
  }

  @computed
  get anyProvider() {
    return this.dto.anyProvider;
  }

  @computed
  get anyLocation() {
    return this.dto.anyLocation;
  }

  @computed
  get content() {
    return this.dto.content;
  }

  @computed
  get bookedBy() {
    return this.dto.bookedBy;
  }

  @computed
  get eTag() {
    return this.dto.eTag;
  }

  @computed
  get title() {
    return this.dto.title;
  }

  @computed
  get purpose() {
    return this.dto.purpose;
  }

  @computed
  get isDeleted() {
    return this.dto.isDeleted;
  }

  @computed
  get duration() {
    return this.dto.duration;
  }

  @computed
  get primaryContact() {
    return this.dto.primaryContact;
  }

  @computed
  get listExpiry() {
    return this.dto.listExpiry;
  }

  @computed
  get orgUnitId() {
    return this.dto.orgUnitId;
  }

  @computed
  get appointmentDateTime() {
    return this.dto.appointmentDateTime;
  }
  @computed
  get calenderEventId() {
    return this.dto.calenderEventId;
  }
}
