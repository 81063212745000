import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  CalendarEventType,
  CalendarEventTypeRefDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { IBookingGateway } from "@libs/gateways/booking/BookingGateway.interface.ts";
import { CalendarEventTypeRef } from "@stores/booking/ref/CalendarEventTypeRef.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class BookingRef implements Ref<keyof BookingRef> {
  constructor(private gateway: IBookingGateway) {}
  calendarEventTypes = new RefDataAccessor<
    CalendarEventType,
    CalendarEventTypeRefDto,
    CalendarEventTypeRef
  >(() => this.gateway.getCalendarEventTypes());
  calendarEventPriorities = new RefDataAccessor(() =>
    this.gateway.getCalendarEventPriorities()
  );
  appointmentStatuses = new RefDataAccessor(() =>
    this.gateway.getAppointmentStatuses()
  );
  appointmentReminderJobStatuses = new RefDataAccessor(() =>
    this.gateway.getAppointmentReminderJobStatuses()
  );

  calendarEventStatuses = new RefDataAccessor(() =>
    this.gateway.getCalendarEventStatus()
  );

  outboundCommStatuses = new RefDataAccessor(() =>
    this.gateway.getOutboundCommStatuses()
  );

  recurrenceLinkingActionTypes = new RefDataAccessor(() =>
    this.gateway.getRecurrenceLinkingActionTypes()
  );

  calendarEventReminderStatuses = new RefDataAccessor(() =>
    this.gateway.getCalendarEventReminderStatuses()
  );
}
