import { action, computed, observable } from "mobx";

import { ClaimPreviewDocumentData } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { Claim } from "@stores/acc/models/Claim.ts";

import { AccStore } from "./AccStore.ts";

export class AccUi {
  constructor(private store: AccStore) {}

  @observable
  recentlyUpdatedClaimKey?: string;

  @observable
  recentlyUpdatedPurchaseOrderKey?: string;

  @observable
  isEmailACC45DialogVisible: boolean = false;

  @observable
  currentClaimToEmail?: Claim | undefined;

  @computed
  private get claimReviewMinimumVisits() {
    const MINIMUM_NUMBER_OF_VISITS = 12;
    const MINIMUM_NUMBER_OF_VISITS_PRE_RELEASE = 2;
    return !this.store.root.core.hasPermissions(
      Permission.ClaimReviewReducedMinimumVisits
    )
      ? MINIMUM_NUMBER_OF_VISITS
      : MINIMUM_NUMBER_OF_VISITS_PRE_RELEASE;
  }

  public shouldDisplayClaimReviewAlert(visitCount: number) {
    return visitCount >= this.claimReviewMinimumVisits;
  }

  @action
  setIsEmailACC45DialogVisible = (value: boolean) => {
    this.isEmailACC45DialogVisible = value;
  };

  @action
  setCurrentClaimToEmail = (claim?: Claim | undefined) => {
    this.currentClaimToEmail = claim;
  };

  @observable
  public claimPreviewDocumentData?: ClaimPreviewDocumentData;

  @action
  setClaimPreviewDocumentData = (data?: ClaimPreviewDocumentData) => {
    this.claimPreviewDocumentData = data;
  };
}
