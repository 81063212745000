import { observable, runInAction } from "mobx";

import { AuthorisationError } from "@bps/http-client";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  IReportsGateway,
  ReportEmbeddingArgs,
  ReportEmbeddingConfiguration
} from "@libs/gateways/reports/ReportGateway.interface.ts";
import {
  AddReportDefinitionDto,
  PdfRequest,
  PresetReportDefinition,
  ReportDefinitionDto,
  ReportDto,
  ReportPrintDocument,
  ReportType,
  SaveType
} from "@libs/gateways/reports/ReportsGateway.dtos.ts";
import { downloadDocumentInIFrame } from "@libs/utils/file.utils.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { permission } from "@stores/decorators/permission.ts";
import type { Store } from "@stores/types/store.type.ts";

export class ReportsStore implements Store<ReportsStore> {
  constructor(
    private gateway: IReportsGateway,
    private hub: IHubGateway
  ) {}

  root: IRootStore;

  @observable userDefinedReportDefinitions: PresetReportDefinition[] = [];
  @observable userDefinedReportsDtos: ReportDefinitionDto[] = [];

  @observable baseReports: ReportDto[] = [];

  @observable publishedReportDefinitions: PresetReportDefinition[] = [];
  @observable publishedReportsDtos: ReportDefinitionDto[] = [];
  @observable presetReportDefinitions: PresetReportDefinition[] = [];

  private get notification() {
    return this.root.notification;
  }

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.ReportDocument, this.onOpenReportingPdfEvent);
    this.hub.onEntityEvent(
      Entity.ReportDefinition,
      this.onReportDefinitionUpdateEvent
    );
  }
  private onReportDefinitionUpdateEvent = async (message: EntityEventData) => {
    if (
      [EventAction.Delete, EventAction.Update, EventAction.Create].includes(
        message.action
      )
    ) {
      this.getMyReports();
      this.getPublishedReports();
    }
  };

  pdfRequests: PdfRequest[] = [];

  private onOpenReportingPdfEvent = async (event: EntityEventData) => {
    if (this.pdfRequests.find(x => x.pdfId === event.id)) {
      if (event.action === EventAction.Create) {
        try {
          const pdfRequest = this.pdfRequests.find(r => r.pdfId === event.id);
          if (pdfRequest) {
            clearTimeout(pdfRequest?.timeout);
          }
          this.pdfRequests = this.pdfRequests.filter(r => r.pdfId !== event.id);

          const url: string = await this.gateway.getSasUrl(event.key);
          downloadDocumentInIFrame(url);
          this.notification.success(notificationMessages.pdfIsDownloaded);
        } catch (e) {
          this.notification.error(e, {
            messageOverride: notificationMessages.pdfCannotBeOpened
          });
        }
      } else {
        this.notification.error(notificationMessages.pdfIsNotCreated);
      }
    }
  };

  openReportPdf = async (printDocument: ReportPrintDocument) => {
    try {
      const pdfId: string = await this.gateway.openReportPdf(printDocument);
      const timeoutId = setTimeout(() => {
        this.notification.error(notificationMessages.pdfTimeout);
      }, 20000);

      this.pdfRequests.push({
        pdfId,
        timeout: timeoutId
      });
      this.notification.warn(notificationMessages.pdfIsBeingGenerated);
    } catch (error) {
      this.notification.error(error);
      throw error;
    }
  };

  @permission<ReportDto[] | undefined>(
    [
      Permission.ReportPresetRead,
      Permission.ReportPublishedRead,
      Permission.ReportBaseRead
    ],
    {
      defaultValue: undefined,
      operator: "or"
    }
  )
  async getBaseReports(): Promise<ReportDto[] | undefined> {
    try {
      const baseReports = (await this.gateway.getBaseReports()).filter(
        r => r.type === ReportType.Base
      );
      runInAction(() => {
        this.baseReports = baseReports;
      });
      return baseReports;
    } catch (error) {
      if (error instanceof AuthorisationError) {
        this.notification.error(error);
      }
      throw error;
    }
  }

  @permission<PresetReportDefinition[]>([Permission.ReportPresetRead], {
    defaultValue: []
  })
  async getPresetReports(): Promise<PresetReportDefinition[]> {
    try {
      const presetReportDto = await this.gateway.getPresetReports();

      const presetReports: PresetReportDefinition[] = presetReportDto.map(r => {
        return {
          ...r,
          ...r.definition,
          type: ReportType.Preset
        };
      });

      runInAction(() => {
        this.presetReportDefinitions = presetReports;
      });

      return presetReports;
    } catch (error) {
      if (error instanceof AuthorisationError) {
        this.notification.error(error);
      }
      throw error;
    }
  }

  @permission<PresetReportDefinition[]>([Permission.ReportPublishedRead], {
    defaultValue: []
  })
  async getPublishedReports(): Promise<PresetReportDefinition[]> {
    try {
      const publishedReportDto = await this.gateway.getPublishedReports();

      const publishedReports: PresetReportDefinition[] = publishedReportDto.map(
        r => {
          return {
            ...r,
            ...r.definition,
            type: ReportType.Published
          };
        }
      );

      runInAction(() => {
        this.publishedReportDefinitions = publishedReports;
        this.publishedReportsDtos = publishedReportDto;
      });

      return publishedReports;
    } catch (error) {
      if (error instanceof AuthorisationError) {
        this.notification.error(error);
      }
      throw error;
    }
  }

  getMyReports = async (): Promise<PresetReportDefinition[]> => {
    try {
      const userDefinedReportsDto = await this.gateway.getMyReports();
      const validUserDefinedReportsDto = userDefinedReportsDto.filter(x =>
        this.baseReports.find(y => y.name === x.baseReportName)
      );

      const userDefinedReports: PresetReportDefinition[] =
        validUserDefinedReportsDto.map(r => {
          return {
            ...r,
            ...r.definition,
            type: ReportType.UserDefined
          };
        });

      runInAction(() => {
        this.userDefinedReportsDtos = validUserDefinedReportsDto;
        this.userDefinedReportDefinitions = userDefinedReports;
      });

      return userDefinedReports;
    } catch (error) {
      if (error instanceof AuthorisationError) {
        this.notification.error(error);
      }
      throw error;
    }
  };

  getEmbedConfiguration = async (
    report: ReportEmbeddingArgs
  ): Promise<ReportEmbeddingConfiguration> => {
    try {
      return await this.gateway.getEmbedConfiguration(report);
    } catch (error) {
      this.notification.error(error);
      throw error;
    }
  };

  public getAllReports = async (): Promise<void> => {
    await this.getBaseReports();

    const getReports = [this.getPublishedReports(), this.getMyReports()];

    if (this.root.core.hasPermissions([Permission.ReportPresetRead])) {
      getReports.push(this.getPresetReports());
    }

    await Promise.all(getReports);
  };

  @permission([Permission.ReportExecute])
  public async deleteUserDefinedReport(
    reportDefinition: PresetReportDefinition
  ) {
    this.gateway.deleteUserDefinedReport(reportDefinition.id);
  }
  @permission([Permission.ReportPublishedWrite])
  public async deletePublishedReport(reportDefinition: PresetReportDefinition) {
    this.gateway.deletePublishedReport(reportDefinition.id);
  }

  @permission([Permission.ReportExecute])
  public async saveUserDefinedReport(
    saveReport: PresetReportDefinition,
    saveType: SaveType
  ): Promise<ReportDefinitionDto | undefined> {
    try {
      const existingReport = this.userDefinedReportsDtos.find(
        r => r.id === saveReport.id
      );

      const { id, name, ...definition } = saveReport;

      const isUpdate =
        saveType === SaveType.rename || saveType === SaveType.update;

      if (isUpdate && existingReport) {
        existingReport.name = name;
        existingReport.definition = definition;

        return await this.gateway.updateUserDefinedReport(
          existingReport.id,
          existingReport
        );
      } else if (saveType === SaveType.saveNew) {
        const baseReport = this.baseReports.find(
          r => r.name === saveReport.baseReportName
        );

        const addReportDefinition: AddReportDefinitionDto = {
          name: saveReport.name,
          baseReportName: baseReport!.name,
          type: ReportType.UserDefined,
          definition,
          userId: this.root.core.userId,
          rowVisibility: saveReport.rowVisibility
        };

        const result =
          await this.gateway.addUserDefinedReport(addReportDefinition);
        return result;
      } else {
        throw new Error(
          `Unexpected save type and existing report combination. SaveType: ${saveType}. ExistingReport ${
            existingReport !== undefined
          }`
        );
      }
    } catch (error) {
      this.notification.error(error);
    }
    return undefined;
  }

  @permission([Permission.ReportPublishedWrite])
  public async savePublishedReport(
    saveReport: PresetReportDefinition,
    saveType: SaveType
  ): Promise<ReportDefinitionDto | undefined> {
    try {
      const existingReport = this.publishedReportsDtos.find(
        r => r.id === saveReport.id
      );

      const { id, name, ...definition } = saveReport;

      const isUpdate =
        saveType === SaveType.rename || saveType === SaveType.update;

      if (isUpdate && existingReport) {
        existingReport.name = name;
        existingReport.definition = definition;

        return await this.gateway.updatePublishedReport(
          existingReport.id,
          existingReport
        );
      } else if (saveType === SaveType.saveNew) {
        const baseReport = this.baseReports.find(
          r => r.name === saveReport.baseReportName
        );

        const addReportDefinition: AddReportDefinitionDto = {
          name: saveReport.name,
          baseReportName: baseReport!.name,
          type: ReportType.Published,
          definition,
          userId: this.root.core.userId,
          rowVisibility: saveReport.rowVisibility
        };

        return await this.gateway.addPublishedReport(addReportDefinition);
      } else {
        throw new Error(
          `Unexpected save type and existing report combination. SaveType: ${saveType}. ExistingReport ${
            existingReport !== undefined
          }`
        );
      }
    } catch (error) {
      this.notification.error(error);
    }
    return undefined;
  }
}
