import { computed } from "mobx";

import { UserStorageDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class UserStorage extends Model<UserStorageDto> {
  @computed
  get userId() {
    return this.dto.userId;
  }

  @computed
  get key() {
    return this.dto.key;
  }

  @computed
  get jsonData() {
    return this.dto.jsonData;
  }
}
