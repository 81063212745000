import { computed } from "mobx";

import { AppointmentEncountersDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class AppointmentEncounters extends Model<AppointmentEncountersDto> {
  @computed get encounterId() {
    return this.dto.encounterId;
  }

  @computed get calendarEventId() {
    return this.dto.calendarEventId;
  }
}
