import { getUniqueObjectsByKeys } from "@bps/utils";
import {
  AllocationDto,
  AllocationItemDto,
  ItemType
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { getInvoiceReferences } from "@stores/billing/utils/invoice.utils.ts";

import { Transaction } from "./Transaction.ts";

export class Allocation extends Transaction<AllocationDto, AllocationItemDto> {
  public get isAllocated() {
    return this.dto.total > 0;
  }

  public get viewPermission() {
    return Permission.AccountHistoryAllowed;
  }

  public get viewLink() {
    return routes.accounts.allocations.allocation;
  }

  //if allocation has 1 payment that id will be the old paymentId
  public get oldPaymentId() {
    const payments = this.items.filter(
      x => x.credit?.itemType === ItemType.Payment
    );

    const uniquePayments = getUniqueObjectsByKeys({
      array: payments,
      keys: ["creditId"]
    });
    if (uniquePayments.length === 1) {
      return uniquePayments[0].creditId;
    }
    return undefined;
  }

  get invoiceReferences() {
    return getInvoiceReferences(this.items);
  }
}
