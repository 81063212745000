import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { UserActionType } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { IInboxDocumentsGateway } from "@libs/gateways/inbox/InboxGateway.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class InboxDocumentRef implements Ref<keyof InboxDocumentRef> {
  constructor(private gateway: IInboxDocumentsGateway) {}
  storeInDestinationsTypes = new RefDataAccessor(() =>
    this.gateway.getStoreInDestinations()
  );

  userActionTypes = new RefDataAccessor(() =>
    this.gateway.getUserActionTypes()
  );

  outcomes = new RefDataAccessor(() => this.gateway.getOutcomes());

  userTaskStatus = new RefDataAccessor(() => this.gateway.getUserTaskStatus());

  priorityTypes = new RefDataAccessor(() => this.gateway.getPriorityTypes());

  reportTypes = new RefDataAccessor(() => this.gateway.getReportType());

  instructionsForProvider = new RefDataAccessor(() =>
    this.gateway
      .getInstructions()
      .then(values =>
        values.filter(
          i => i.userActionTypeCode === UserActionType.ProviderToAdvise
        )
      )
  );

  instructionsForReception = new RefDataAccessor(() =>
    this.gateway
      .getInstructions()
      .then(values =>
        values.filter(
          i => i.userActionTypeCode === UserActionType.ReceptionToAdvise
        )
      )
  );
  instructions = new RefDataAccessor(() => this.gateway.getInstructions());
}
