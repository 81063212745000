import { action, computed, observable } from "mobx";

import {
  AccClinicalTab,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClaimAdjustmentFormValues } from "@shared-types/acc/claim-adjustment-form-values.type.ts";
import { ClaimFormValues } from "@shared-types/acc/claim-form-values.type.ts";
import { ReviewFormValues } from "@shared-types/acc/claim-review-values.interface.ts";
import { EncounterFormValues } from "@shared-types/clinical/encounter-values.interface.ts";
import { SOTAPFormValues } from "@shared-types/clinical/SOTAP-values.interface.ts";
import { ClinicalUi } from "@stores/clinical/ClinicalUi.ts";

import { getIsEqual } from "../utils/clinical.utils.ts";
import { StashedEncounterClinicalData } from "./StashedEncounterClinicalData.ts";

type OriginalInitialValues =
  | EncounterFormValues
  | SOTAPFormValues
  | ReviewFormValues
  | ClaimAdjustmentFormValues
  | ClaimFormValues;
interface ClinicalRecordFormsValues {
  [PermanentClinicalTab.TodaysNotes]: EncounterFormValues | undefined;
  [PermanentClinicalTab.SOTAP]: SOTAPFormValues | undefined;
  [AccClinicalTab.Review]: ReviewFormValues | undefined;
  [AccClinicalTab.Adjustment]: ClaimAdjustmentFormValues | undefined;
  [AccClinicalTab.Injury]: ClaimFormValues | undefined;
}

export type DirtyFormsMap = Map<keyof ClinicalRecordFormsValues, boolean>;

export class ClinicalRecordFormsValuesStash {
  constructor(private clinicalUi: ClinicalUi) {}
  @observable
  public originalValues: ClinicalRecordFormsValues = {
    [PermanentClinicalTab.TodaysNotes]: undefined,
    [PermanentClinicalTab.SOTAP]: undefined,
    [AccClinicalTab.Review]: undefined,
    [AccClinicalTab.Adjustment]: undefined,
    [AccClinicalTab.Injury]: undefined
  };

  @observable
  public persistedValues: ClinicalRecordFormsValues = {
    [PermanentClinicalTab.TodaysNotes]: undefined,
    [PermanentClinicalTab.SOTAP]: undefined,
    [AccClinicalTab.Review]: undefined,
    [AccClinicalTab.Adjustment]: undefined,
    [AccClinicalTab.Injury]: undefined
  };

  @action
  public setPersistedValues = (
    values: Partial<ClinicalRecordFormsValues>,
    skipTabHighlighting?: boolean
  ) => {
    this.persistedValues = { ...this.persistedValues, ...values };

    for (const tab in values) {
      const isDirty = !!this.dirtyForms.get(
        tab as keyof ClinicalRecordFormsValues
      );
      if (!skipTabHighlighting) {
        this.clinicalUi.tabs.currentPatientRecordTab?.setIsDirty(isDirty, {
          type: tab as keyof ClinicalRecordFormsValues
        });
      }
    }
  };

  @action
  public resetPersistedValues = (
    tab: keyof ClinicalRecordFormsValues,
    originalInitialValues: OriginalInitialValues
  ) => {
    this.setPersistedValues({
      [tab]: undefined
    });
    this.setOriginalValues({
      [tab]: originalInitialValues
    });
  };

  @action
  public setOriginalValues = (values: Partial<ClinicalRecordFormsValues>) => {
    this.originalValues = { ...this.originalValues, ...values };
  };

  public init = (
    tab: keyof ClinicalRecordFormsValues,
    originalInitialValues: OriginalInitialValues
  ) => {
    const values = { [tab]: originalInitialValues };

    if (!this.originalValues[tab]) {
      this.setOriginalValues(values);
    }
  };

  @computed
  get dirtyForms(): DirtyFormsMap {
    const map: DirtyFormsMap = new Map();
    for (const key in this.persistedValues) {
      const original = this.originalValues
        ? this.originalValues[key]
        : undefined;

      const persisted = this.persistedValues[key];

      if (!persisted && original) {
        map.set(key as keyof ClinicalRecordFormsValues, false);
      } else {
        const isEqual = getIsEqual(
          original,
          persisted,
          StashedEncounterClinicalData.ignoredKeys
        );
        map.set(key as keyof ClinicalRecordFormsValues, !isEqual);
      }
    }
    return map;
  }

  @computed
  get hasAcc45DirtyAreas() {
    return !!this.dirtyForms.get(AccClinicalTab.Injury);
  }
}
