import { computed } from "mobx";

import {
  ScheduleDto,
  ScheduleProcessingStatuses
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class Schedule extends Model<ScheduleDto> {
  constructor(
    private root: IRootStore,
    dto: ScheduleDto
  ) {
    super(dto);
  }

  @computed
  get isPaidOrPartiallyPaid() {
    return (
      this.dto.processingStatus === ScheduleProcessingStatuses.Paid ||
      this.dto.processingStatus === ScheduleProcessingStatuses.PartiallyPaid
    );
  }
}
