import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  ClaimAdjustmentRequestType,
  ClaimAdjustmentResponseRequest
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ProviderAccContractTypeDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";

export const isTreatmentsRequest = (request: ClaimAdjustmentResponseRequest) =>
  request.claimAdjustmentRequestTypeCode ===
  ClaimAdjustmentRequestType.TreatmentRequest;

export const isDiagnosisAdditionRequest = (
  request: ClaimAdjustmentResponseRequest
) =>
  request.claimAdjustmentRequestTypeCode ===
  ClaimAdjustmentRequestType.DiagnosisAddition;

export const isDiagnosisChangeRequest = (
  request: ClaimAdjustmentResponseRequest
) =>
  request.claimAdjustmentRequestTypeCode ===
  ClaimAdjustmentRequestType.DiagnosisChange;

export const isSideChangeRequest = (request: ClaimAdjustmentResponseRequest) =>
  request.claimAdjustmentRequestTypeCode ===
  ClaimAdjustmentRequestType.SideChange;
export const filterAvailableAccProviderTypes = (
  accProviderTypes: KeyTextValue[],
  providerContractTypes: ProviderAccContractTypeDto[],
  contractTypes?: string[]
): KeyTextValue[] => {
  const availableProviderCC = providerContractTypes.filter(
    pcc => contractTypes?.some(contractType => pcc.code === contractType)
  );
  return accProviderTypes.filter(({ key }) =>
    availableProviderCC.some(acc => acc.providerTypeCode === key)
  );
};
