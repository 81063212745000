import { computed } from "mobx";

import { OutboundCommTemplateReplyActionDto } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class OutboundCommTemplateReplyAction extends Model<OutboundCommTemplateReplyActionDto> {
  constructor(
    private store: IRootStore,
    dto: OutboundCommTemplateReplyActionDto
  ) {
    super(dto);
  }

  @computed
  get outboundCommTypeReplyActionCode() {
    return this.dto.outboundCommTypeReplyActionCode;
  }

  @computed
  get responsePatterns() {
    return this.dto.responsePatterns;
  }

  @computed
  get templateId() {
    return this.dto.outboundCommTemplateId;
  }

  @computed
  get created() {
    return this.dto.changeLog?.createdBy;
  }

  @computed
  get createdDate() {
    return this.dto.changeLog?.createdDate;
  }

  @computed
  get updated() {
    return this.dto.changeLog?.updatedBy;
  }

  @computed
  get updatedDate() {
    return this.dto.changeLog?.updatedDate;
  }
}
