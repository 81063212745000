import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import {
  ClaimAdjustmentDto,
  DiagnosisAdditionRequestDto,
  DiagnosisChangeRequestDto,
  SideChangeRequestDto,
  TreatmentRequestDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Provider } from "@stores/practice/models/Provider.ts";

import {
  isDiagnosisAdditionRequest,
  isDiagnosisChangeRequest,
  isSideChangeRequest,
  isTreatmentsRequest
} from "../utils/acc.utils.ts";

export class ClaimAdjustment extends Model<ClaimAdjustmentDto> {
  private _provider: Provider | undefined;

  constructor(
    private root: IRootStore,
    dto: ClaimAdjustmentDto
  ) {
    super(dto);
  }

  get provider() {
    return this._provider;
  }

  set provider(provider: Provider | undefined) {
    this._provider = provider;
  }

  loadProvider = async () => {
    if (this.dto.providerId) {
      this.provider = await this.root.practice.getProvider(this.dto.providerId);
    }
  };

  @computed
  get createdDate() {
    return DateTime.fromISO(this.dto.changeLog?.createdDate);
  }

  @computed
  get updatedDate() {
    return DateTime.fromISO(this.dto.changeLog?.updatedDate);
  }

  @computed
  get responseDate() {
    return DateTime.fromISO(this.dto.responseDate);
  }

  @computed
  get patientDateOfBirth() {
    return DateTime.fromISO(this.dto.patientDateOfBirth);
  }

  get claimId() {
    return this.dto.claimId;
  }

  get diagnosisChanges() {
    const requests = this.dto.claimAdjustmentRequests?.find(
      isDiagnosisChangeRequest
    ) as DiagnosisChangeRequestDto;
    return requests?.diagnosisChanges ?? [];
  }

  get sideChanges() {
    const requests = this.dto.claimAdjustmentRequests?.find(
      isSideChangeRequest
    ) as SideChangeRequestDto;
    return requests?.sideChanges ?? [];
  }

  get acc32errorMessages() {
    return (
      this.claimAdjustmentDocuments?.flatMap(
        doc =>
          doc.errorMessages?.map(error => ({
            message: error.message,
            details: error.details
          })) ?? []
      ) ?? []
    );
  }

  @computed
  get status() {
    return this.root.acc.ref.claimAdjustmentStatuses.values.find(
      s => s.code === this.dto.statusCode
    );
  }

  get claimNumber() {
    return this.dto.claimNumber;
  }

  get claimAdjustmentNumber() {
    return this.dto.claimAdjustmentNumber;
  }

  get formattedNumber() {
    return `${this.dto.claimNumber}/${this.dto.claimAdjustmentNumber}`;
  }

  get patientFirstName() {
    return this.dto.patientFirstName;
  }

  get patientLastName() {
    return this.dto.patientLastName;
  }

  get patientAddress() {
    return this.dto.patientAddress;
  }

  get providerId() {
    return this.dto.providerId;
  }

  get providerFirstName() {
    return this.dto.providerFirstName;
  }

  get providerLastName() {
    return this.dto.providerLastName;
  }

  get providerContactNumber() {
    return this.dto.providerContactNumber;
  }

  get hpiCpn() {
    return this.dto.hpiCpn;
  }

  get providerContractType() {
    return this.dto.providerContractType;
  }

  get diagnosisAdditions() {
    const requests = this.dto.claimAdjustmentRequests?.find(
      isDiagnosisAdditionRequest
    ) as DiagnosisAdditionRequestDto;
    return requests?.diagnosisAdditions ?? [];
  }

  get claimAdjustmentDocuments() {
    return this.dto.claimAdjustmentDocuments ?? [];
  }

  get hasDiagnosisAddition() {
    return this.dto.claimAdjustmentRequests?.some(isDiagnosisAdditionRequest);
  }

  get hasSideChange() {
    return this.dto.claimAdjustmentRequests?.some(isSideChangeRequest);
  }

  get hasTreatmentDetails() {
    return this.dto.claimAdjustmentRequests?.some(isTreatmentsRequest);
  }

  get hasDiagnosisChange() {
    return this.dto.claimAdjustmentRequests?.some(isDiagnosisChangeRequest);
  }

  get hpiOrganisationNumber() {
    return this.dto.hpiOrganisationNumber;
  }

  get treatmentRequest(): TreatmentRequestDto | undefined {
    return this.dto.claimAdjustmentRequests?.find(isTreatmentsRequest);
  }

  get followUpTreatmentsNeeded() {
    return this.treatmentRequest?.followUpTreatmentsNeeded;
  }

  get treatmentsRequired() {
    return this.treatmentRequest?.treatmentsRequested;
  }

  get handSplintingCost() {
    return this.treatmentRequest?.handSplintingCost;
  }

  get handSplintingCostApproved() {
    return this.treatmentRequest?.handSplintingCostApproved;
  }

  get treatmentStartDate() {
    return DateTime.fromISO(this.treatmentRequest?.treatmentStartDate);
  }

  get initialAssessmentsApproved() {
    return this.treatmentRequest?.initialAssessmentsApproved;
  }

  get initialAssessmentsNeeded() {
    return this.treatmentRequest?.initialAssessmentsNeeded;
  }

  @computed
  get initialAssessmentRequested(): boolean {
    return !!(
      this.initialAssessmentsNeeded && this.initialAssessmentsNeeded > 0
    );
  }

  get firstAdditionalTreatmentRequested() {
    return this.treatmentRequest?.firstAdditionalTreatmentRequested;
  }

  get isVoluntarySubmission() {
    return this.dto.isVoluntarySubmission;
  }

  get originalAccidentEvent() {
    return this.dto.originalAccidentEvent;
  }

  get currentConditionOrDiagnosis() {
    return this.dto.currentConditionOrDiagnosis;
  }

  get causeOfCurrentCondition() {
    return this.dto.causeOfCurrentCondition;
  }

  get causeOfNotResolvedCondition() {
    return this.dto.causeOfNotResolvedCondition;
  }

  get signAndSymptom() {
    return this.dto.signAndSymptom;
  }

  get proposedTreatment() {
    return this.dto.proposedTreatment;
  }

  get expectedTreatmentCompletionDate() {
    return DateTime.fromISO(this.dto.expectedTreatmentCompletionDate);
  }

  get managementPlan() {
    return this.dto.managementPlan;
  }

  get patientDeclarationConfirmed() {
    return this.dto.patientDeclarationConfirmed;
  }

  get providerDeclarationConfirmed() {
    return this.dto.providerDeclarationConfirmed;
  }

  get accRelatedSurgery() {
    return !!this.dto.accRelatedSurgery;
  }

  get mostRecentSurgeryDate() {
    return DateTime.fromISO(this.dto.mostRecentSurgeryDate);
  }

  get purchaseOrderNumber() {
    return this.dto.purchaseOrderNumber;
  }

  get approvedVisits() {
    return this.treatmentRequest?.treatmentsApproved;
  }

  get responseNotes() {
    return this.dto.responseNotes;
  }

  get documents() {
    return this.dto.claimAdjustmentDocuments;
  }

  get abscessHematomaTreatmentsNeeded() {
    return this.treatmentRequest?.abscessHematomaTreatmentsNeeded;
  }

  get nailRemovalResectionTreatmentsNeeded() {
    return this.treatmentRequest?.nailRemovalResectionTreatmentsNeeded;
  }

  get nailSimpleRemovalTreatmentsNeeded() {
    return this.treatmentRequest?.nailSimpleRemovalTreatmentsNeeded;
  }

  get abscessHematomaTreatmentsNeededApproved() {
    return this.treatmentRequest?.abscessHematomaTreatmentsNeededApproved;
  }

  get nailRemovalResectionTreatmentsNeededApproved() {
    return this.treatmentRequest?.nailRemovalResectionTreatmentsNeededApproved;
  }

  get nailSimpleRemovalTreatmentsNeededApproved() {
    return this.treatmentRequest?.nailSimpleRemovalTreatmentsNeededApproved;
  }

  get verballyApproved() {
    return this.dto.verballyApproved;
  }

  @computed
  get requestedTreatments() {
    return this.treatmentsRequired ?? this.followUpTreatmentsNeeded ?? 0;
  }

  public get errorMessages() {
    return this.dto.errorMessages ?? [];
  }
}
