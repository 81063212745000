import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { CalendarEventReminderDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class CalendarEventReminder extends Model<CalendarEventReminderDto> {
  constructor(
    private store: IRootStore,
    dto: CalendarEventReminderDto
  ) {
    super(dto);
  }

  @computed
  get id() {
    return this.dto.id;
  }

  @computed
  get calendarEventReminderStatusCode() {
    return this.dto.deliveryStatusCode;
  }

  @computed
  get outboundCommId() {
    return this.dto.outboundCommId;
  }

  @computed
  get calendarEventId() {
    return this.dto.calendarEventId;
  }

  @computed
  get contactId() {
    return this.dto.contactId;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }

  @computed
  get orgUnitId() {
    return this.dto.orgUnitId;
  }

  @computed
  get replyStatusCode() {
    return this.dto.replyStatusCode;
  }

  @computed
  get contact() {
    return this.contactId
      ? this.store.practice.contactsMap.get(this.contactId)
      : undefined;
  }

  @computed
  get calendarEventStartDate() {
    return DateTime.jsDateFromISO(this.dto.calendarEventStartTime);
  }

  @computed
  get calendarEventStartDateTime() {
    return DateTime.fromISO(this.dto.calendarEventStartTime);
  }

  @computed
  get calendarEventAppointmentTime() {
    const hour = this.calendarEventStartDateTime.hour;
    const minute = this.calendarEventStartDateTime.minute;
    return DateTime.now().set({ hour, minute });
  }
}
