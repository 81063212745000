import { action, observable } from "mobx";

import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";

import { UserExperienceStore } from "./UserExperienceStore.tsx";
import { UserHotkeysHelper } from "./UserHotkeysHelper.ts";

export class UserExperienceUi {
  constructor(private userExperienceStore: UserExperienceStore) {
    this.userHotkeysHelper = new UserHotkeysHelper(userExperienceStore);
  }
  public userHotkeysHelper: UserHotkeysHelper;

  @observable public showHelpDialog = false;

  @action public toggleHelpDialogVisibility = (visible?: boolean) => {
    this.showHelpDialog = visible || !this.showHelpDialog;
  };

  @observable
  editingAutoFill: AutofillDto | undefined;

  @action public setEditingAutoFill = (autofill: AutofillDto | undefined) => {
    this.editingAutoFill = autofill;
  };
}
