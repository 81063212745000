import { action, observable } from "mobx";

import { PurchaseOrderDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { ClaimAdjustment } from "@stores/acc/models/ClaimAdjustment.ts";

export class PurchaseOrder extends Model<PurchaseOrderDto> {
  constructor(
    private store: IRootStore,
    dto: PurchaseOrderDto
  ) {
    super(dto);
  }

  @observable
  claimAdjustment: ClaimAdjustment | undefined;

  @observable
  isRemovable: boolean;

  get purchaseOrderNotes(): string | undefined {
    return this.dto.purchaseOrderNotes;
  }

  get purchaseOrderNumber(): string | string | undefined {
    return this.dto.purchaseOrderNumber;
  }

  get claimAdjustmentId(): string | undefined {
    return this.dto.claimAdjustmentId;
  }

  get claimId(): string {
    return this.dto.claimId;
  }

  get responseDate(): string | undefined {
    return this.dto.responseDate;
  }

  @action
  setClaimAdjustment = (claimAdjustment: ClaimAdjustment | undefined) => {
    this.claimAdjustment = claimAdjustment;
  };

  @action
  setIsRemovable = (value: boolean) => {
    this.isRemovable = value;
  };

  loadRelatedClaimAdjustment = async () => {
    if (this.claimAdjustmentId) {
      const acc32 = await this.store.acc.getClaimAdjustment(
        this.claimAdjustmentId
      );
      this.setClaimAdjustment(acc32);
    }
  };

  loadDeleteCheckData = async () => {
    const response = await this.store.acc.purchaseOrderDeleteCheck(this.id);
    this.setIsRemovable(response);
  };
}
