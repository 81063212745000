import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { LicenceDto } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class Licence extends Model<LicenceDto> {
  @computed
  get productId() {
    return this.dto.productId;
  }

  @computed
  get userId() {
    return this.dto.userId;
  }

  @computed get username() {
    return this.dto.username;
  }

  @computed get expiryDate() {
    return DateTime.fromISO(this.dto.expiryDate);
  }

  @computed get isExpired() {
    return this.dto.isExpired;
  }

  @computed get doNotRenew() {
    return this.dto.doNotRenew;
  }

  @computed get isInactive() {
    return this.dto.isInactive;
  }

  @computed get isValid() {
    return this.dto.isValid;
  }

  @computed get licenceTypeCode() {
    return this.dto.licenceTypeCode;
  }

  @computed get name() {
    return this.dto.name;
  }

  @computed get displayName() {
    return this.dto.displayName;
  }

  @computed get description() {
    return this.dto.description;
  }

  @computed get gracePeriod() {
    return this.dto.gracePeriod ?? 0;
  }

  get changeLog() {
    return this.dto.changeLog;
  }

  @computed get isLicenceInGracePeriod() {
    return (
      this.gracePeriod > 0 && this.expiryDate < DateTime.now().startOf("day")
    );
  }

  @computed get gracePeriodEndDate() {
    return this.expiryDate.plus({ days: this.gracePeriod + 1 });
  }
}
