import { computed } from "mobx";

import { DATE_FORMATS, DateTime } from "@bps/utils";
import { OutboundCommTemplateDto } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { CommsDefaultCampaignSettingDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class OutboundCommTemplate extends Model<OutboundCommTemplateDto> {
  constructor(
    private store: IRootStore,
    dto: OutboundCommTemplateDto
  ) {
    super(dto);
  }

  @computed
  get outboundCommTypeCode() {
    return this.dto.outboundCommTypeCode;
  }

  @computed
  get channelTemplates() {
    return this.dto.channelTemplates;
  }

  @computed
  get name() {
    return this.dto.name;
  }

  @computed
  get templateType() {
    return (
      this.store.comms.ref.outboundCommTypes.keyTextValues.find(
        x => x.key === this.dto.outboundCommTypeCode
      )?.text ?? ""
    );
  }

  @computed
  get created() {
    return this.dto.changeLog?.createdBy;
  }

  @computed
  get createdDate() {
    return this.dto.changeLog?.createdDate;
  }

  @computed
  get updated() {
    return this.dto.changeLog?.updatedBy ?? "";
  }

  @computed
  get updatedDate() {
    return this.dto.changeLog?.updatedDate ?? "";
  }

  @computed
  get updatedByProvider() {
    return this.dto.changeLog?.updatedBy ?? this.dto.changeLog?.createdBy ?? "";
  }

  @computed
  get updatedDateTime() {
    return (
      this.dto.changeLog?.updatedDate ?? this.dto.changeLog?.createdDate ?? ""
    );
  }

  @computed
  get isDefault() {
    const tenantCommsDefaultCampaignSettings = this.store.userExperience
      .tenantCommsDefaultCampaignSettings as CommsDefaultCampaignSettingDto[];
    return !!(
      tenantCommsDefaultCampaignSettings &&
      tenantCommsDefaultCampaignSettings.find(
        x =>
          x.commType === this.outboundCommTypeCode && x.templateId === this.id
      )
    );
  }

  @computed
  get updatedDisplayDate(): string | undefined {
    if (this.updatedDate) {
      const updatedDate = DateTime.fromISO(this.updatedDate);
      if (updatedDate) {
        return updatedDate.toFormat(DATE_FORMATS.DAY_TEXT_MONTH_YEAR_SHORT);
      }
    }

    return DateTime.fromISO(this.createdDate)?.toFormat(
      DATE_FORMATS.DAY_TEXT_MONTH_YEAR_SHORT
    );
  }
}
