import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { ICommsGateway } from "@libs/gateways/comms/CommsGateway.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class CommsRef implements Ref<keyof CommsRef> {
  constructor(private gateway: ICommsGateway) {}
  outboundCommChannels = new RefDataAccessor(() =>
    this.gateway.getOutboundCommChannels()
  );
  outboundCommTypes = new RefDataAccessor(() =>
    this.gateway.getOutboundCommTypes()
  );
}
