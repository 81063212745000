import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import {
  UserActionContextData,
  UserActionDto,
  UserTaskDto
} from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

export class UserTask extends Model<UserTaskDto> {
  constructor(
    private store: IRootStore,
    dto: UserTaskDto
  ) {
    super(dto);
  }

  @computed
  get subject(): string | undefined {
    return this.dto.subject;
  }

  @computed
  get priority(): string | undefined {
    return this.dto.priority;
  }

  @computed
  get userActionId(): string | undefined {
    return this.dto.userActionId;
  }

  @computed
  get userActionETag(): string | undefined {
    return this.dto.userAction?.eTag;
  }

  @computed
  get userAction(): UserActionDto | undefined {
    return this.dto.userAction;
  }

  @computed
  get instructionCode(): string {
    return this.dto.instructionCode;
  }

  @computed
  get assignedToUserId(): string | undefined {
    return this.dto.assignedToUserId;
  }

  @computed
  get userActionType(): string | undefined {
    return this.dto.userAction?.userActionType;
  }

  @computed
  get patientId(): string | undefined {
    return (
      this.context.storeIn?.inboxDocument.patientId ??
      this.context.patientKey?.patientId
    );
  }

  @computed
  get extraInfo(): string | undefined {
    return this.context.storeIn?.inboxDocument.extraInfo;
  }

  @computed
  get documentId(): string | undefined {
    return (
      this.context.storeIn?.inboxDocument.documentDetailId ??
      this.context.patientKey?.documentId
    );
  }

  @computed
  get context(): UserActionContextData {
    return this.dto.userAction?.context!;
  }

  @computed
  get dueDateTime() {
    return DateTime.fromISO(this.dto.dueDateTime);
  }

  @computed
  get checkedDateTime() {
    return DateTime.fromISO(
      this.context.storeIn?.inboxDocument.checkedDateTime
    );
  }

  @computed
  get user() {
    return this.assignedToUserId
      ? this.store.core.userMap.get(this.assignedToUserId)
      : undefined;
  }

  @computed
  get checkedByUser() {
    return this.context.storeIn?.inboxDocument.checkedBy
      ? this.store.core.userMap.get(
          this.context.storeIn?.inboxDocument.checkedBy
        )
      : undefined;
  }

  @computed
  get patient() {
    return this.patientId
      ? this.store.practice.contactsMap.get(this.patientId)
      : undefined;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }

  @computed
  get status() {
    return this.dto.status;
  }

  @computed
  get closingComments() {
    return this.dto.closingComments;
  }

  @computed
  get outcomeLog() {
    return this.dto.outcomeLog;
  }

  @computed
  get outcome() {
    return this.dto.outcome;
  }

  @computed
  get latestOutcomeStatusFromLog() {
    const outcomeLog = this.outcomeLog;
    return outcomeLog ? outcomeLog[0].outcomeStatus : this.outcome;
  }

  @computed
  get rootEntityETag() {
    return this.dto.rootEntityETag;
  }

  loadRelatedUser = () => {
    if (!this.assignedToUserId) return;
    return this.store.core.getUser(this.assignedToUserId);
  };

  loadRelatedPatient = () => {
    if (!this.patientId) return;
    return this.store.practice.getContact(this.patientId);
  };
}
