import { computed } from "mobx";

import { TemplateExtensionDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class TemplateExtension extends Model<
  TemplateExtensionDto & { id: string }
> {
  @computed
  get isComplex() {
    return this.dto.isComplex;
  }

  @computed
  get templateId() {
    return this.dto.templateId;
  }
}
