import { RefData } from "@libs/api/ref-data/RefData.ts";
import { Country } from "@libs/enums/country.enum.ts";
import { AddressDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";

export interface Address extends AddressDto {
  preferred?: boolean;
}

export const addressFields: Array<keyof Address> = [
  nameof<Address>("street1"),
  nameof<Address>("street2"),
  nameof<Address>("suburb"),
  nameof<Address>("postCode"),
  nameof<Address>("state"),
  nameof<Address>("city"),
  nameof<Address>("country"),
  nameof<Address>("preferred")
];

export interface RefCountry extends RefData<Country> {
  isStateRequired: boolean;
}

export function addressText(address: Address) {
  return `${address.street1 ? `${address.street1}, ` : ""}${
    address.street2 ? `${address.street2}, ` : ""
  }${address.suburb || ""} ${address.city || ""} ${address.state || ""} ${
    address.country || ""
  } ${address.postCode || ""}`;
}

export function nzAddressText(address: Omit<Address, "type">) {
  if (address.country === Country.NewZealand) {
    return `${address.street2 ? `${address.street2}, ` : ""}${
      address.street1 ? `${address.street1}, ` : ""
    }${address.suburb || ""} ${address.city || ""} ${address.postCode || ""}`;
  }
  return;
}
