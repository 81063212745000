import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { NotificationDto } from "@libs/gateways/notification/NotificationGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class SystemNotification extends Model<NotificationDto> {
  @computed
  get id() {
    return this.dto.id;
  }

  @computed
  get title() {
    return this.dto.title;
  }

  @computed
  get description() {
    return this.dto.description;
  }

  @computed
  get priority() {
    return this.dto.priority;
  }

  @computed
  get type() {
    return this.dto.type;
  }

  @computed
  get subtype() {
    return this.dto.subtype;
  }

  @computed
  get isRead() {
    return this.dto.isRead;
  }

  @computed
  get createdDate() {
    return DateTime.fromISO(this.dto.changeLog?.createdDate);
  }

  @computed
  get context() {
    return this.dto.context;
  }
}
