// eslint-disable-next-line import/extensions
import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { InteractionDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { convertUtcToLocal } from "../utils/clinical.utils.ts";

export class Interaction extends Model<InteractionDto> {
  constructor(
    private store: IRootStore,
    dto: InteractionDto
  ) {
    super(dto);
  }

  @computed get sourceId() {
    return this.dto.sourceId;
  }

  @computed get patientId() {
    return this.dto.patientId;
  }

  @computed get title() {
    return this.dto.title;
  }

  @computed get text() {
    return this.dto.text;
  }

  @computed get type() {
    return this.dto.type;
  }

  @computed get secGroupId() {
    return this.dto.secGroupId;
  }

  @computed get createdAt() {
    if (!this.dto.timestamp) {
      return "No date";
    }

    //CONVERT UTC TO LOCAL
    const utc = this.dto.timestamp;
    return convertUtcToLocal(utc);
  }

  @computed get user() {
    return this.store.core.userMap.get(this.dto.userId);
  }

  @computed get SourceTypeCode() {
    return this.dto.sourceTypeCode;
  }

  @computed get interactionDateTime() {
    const utc = this.dto.interactionDateTime.replace(" ", "T");
    return DateTime.fromISO(utc);
  }

  @computed get interactionAt() {
    if (!this.dto.interactionDateTime) {
      return "No date";
    }

    //CONVERT UTC TO LOCAL
    const utc = this.dto.interactionDateTime.replace(" ", "T");
    return convertUtcToLocal(utc);
  }
}
