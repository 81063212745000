import { observable, runInAction } from "mobx";

export class TemplateManagementUi {
  @observable showTemplateListModal: boolean;
  @observable lastUpdatedTemplateInstanceETag?: string;

  public setShowTemplateListModal(value: boolean) {
    runInAction(() => {
      this.showTemplateListModal = value;
    });
  }
}
