import { toRefData } from "@libs/api/ref-data/RefData.ts";
import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import type { RefCommunicationDto } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ICoreGateway } from "@libs/gateways/core/CoreGateway.interface.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { RefCommunicationType } from "@stores/core/models/Communication.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class CoreRef implements Ref<keyof CoreRef> {
  constructor(private gateway: ICoreGateway) {}

  titles = new RefDataAccessor(() => this.gateway.getTitles());

  countries = new RefDataAccessor(() => this.gateway.getCountries());

  australianStates = new RefDataAccessor(() =>
    this.gateway.getAustralianStates()
  );

  userStatus = new RefDataAccessor(() => this.gateway.getUserStatus());

  timeZones = new RefDataAccessor(() => this.gateway.getTimeZones());

  contactCommTypes = new RefDataAccessor<
    CommunicationType,
    RefCommunicationDto,
    RefCommunicationType
  >(() => this.gateway.getCommunicationTypes(), {
    transformer: ({ type, ...rest }) => ({ ...toRefData(rest), kind: type })
  });
}
