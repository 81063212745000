import { action, computed, observable, runInAction } from "mobx";

import { UserUnavailabilityDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export class UserUnavailabilityModel {
  @observable.ref
  dto: UserUnavailabilityDto;

  constructor(dto: UserUnavailabilityDto) {
    runInAction(() => {
      this.dto = dto;
    });
  }

  @action
  updateFromDto(dto: UserUnavailabilityDto) {
    this.dto = dto;
  }

  @computed
  get userId() {
    return this.dto.userId;
  }

  @computed
  get calendarEventRecurrences() {
    return this.dto.calendarEventRecurrences ?? [];
  }
}
