import { computed } from "mobx";

import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  RefRelationshipTypeDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";

export class RelationshipTypeRefDataAccessor extends RefDataAccessor<
  RelationshipType,
  RefRelationshipTypeDto
> {
  @computed
  get keyNameValuesWithReverseRelation() {
    return this.values.map(({ code, text, reverseRelationshipTypeCode }) => ({
      key: code,
      name: text,
      reverseRelationshipTypeCode
    }));
  }
}
