import { DateTime, TIME_FORMATS } from "@bps/utils";
import { AppointmentReminderJobScheduleDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export interface CronDto {
  days: string;
  time: string;
}

enum CronDaysEnum {
  Sun = 0,
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6
}

enum CronDaysTextEnum {
  "Sun" = 0,
  "Mon" = 1,
  "Tue" = 2,
  "Wed" = 3,
  "Thu" = 4,
  "Fri" = 5,
  "Sat" = 6
}

enum ScheduleText {
  Daily = "Daily",
  Weekdays = "Weekdays"
}

function getDays(days: number[]): string {
  const weekdays: string[] = [];
  const weekends: string[] = [];

  days.forEach(day => {
    if (day !== CronDaysEnum.Sun && day !== CronDaysEnum.Sat)
      weekdays.push(CronDaysTextEnum[day]);
    else weekends.push(CronDaysTextEnum[day]);
  });

  const alldays = [...weekdays, ...weekends];

  // Cheat - If there are 7 days listed, we definitely have Daily, so we just return this
  if (alldays.length === 7) return ScheduleText.Daily;

  // Cheat - If there are 5 weekdays listed, we definitely have Weekdays. But we also see if there is 1 weekend day listed as well.
  // If there is, we include that in the return value, otherwise we just return 'Weekdays'
  if (weekdays.length === 5) {
    if (weekends.length === 1) {
      return `${ScheduleText.Weekdays} + ${weekends[0]}`;
    } else {
      return ScheduleText.Weekdays;
    }
  }

  // None of the previous rules were met, so we just return which days we have
  return alldays.join(", ");
}

function getTime(hour: number, minute: number): string {
  return DateTime.fromObject({ hour, minute }).toFormat(
    TIME_FORMATS.TIME_FORMAT_WITH_MIDDAY_FULL
  );
}

class CronTabHelper {
  /**
   *
   * @return {Object} CronDto which returns 2 string properties
   * {
   *    days: string;
   *    time: string;
   * }
   * @param dto
   */
  static processCronTab(dto: AppointmentReminderJobScheduleDto): CronDto {
    const days = getDays(dto.daysOfWeek);
    const time = getTime(dto.hour, dto.minute);

    return {
      days,
      time
    };
  }
}

export { CronTabHelper };
