import { computed } from "mobx";

import { BookingTenantSettingsDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class BookingTenantSettings extends Model<
  BookingTenantSettingsDto & { id: string }
> {
  @computed
  get appointmentConfirmationSettings() {
    return this.dto.appointmentConfirmationSettings;
  }
}
