export const mergeModel = <
  TModel extends {
    id: string;
    eTag?: string;
    updateFromDto: (dto: TDto) => void;
  },
  TDto extends { id: string; eTag?: string }
>(options: {
  map: Map<string, TModel>;
  dto: TDto;
  getNewModel: () => TModel;
  id?: string;
}) => {
  const { map, dto, getNewModel, id } = options;
  const key = id ?? dto.id;

  let mapItem = map.get(key);
  if (!mapItem) {
    mapItem = getNewModel();
    map.set(key, mapItem);
  } else {
    if (mapItem.eTag !== dto.eTag) {
      mapItem.updateFromDto(dto);
    }
  }
  return mapItem;
};
