import { action, observable } from "mobx";

import { DateTime } from "@bps/utils";
import {
  SubmitActionCode,
  TemplateVisibility
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  NonCustomTemplateName,
  TemplateDto
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

export class Template extends Model<TemplateDto> {
  constructor(
    private core: CoreStore,
    dto: TemplateDto
  ) {
    super(dto);
  }

  get name() {
    return this.dto.name;
  }

  get description() {
    return this.dto.description;
  }

  get templateType() {
    return this.dto.templateTypeCode;
  }

  get shortcut() {
    return this.dto.shortcut;
  }

  get visibility() {
    if (this.dto.isAdmin && this.dto.isClinical) {
      return TemplateVisibility.Both;
    } else {
      return this.dto.isAdmin
        ? TemplateVisibility.Admin
        : TemplateVisibility.Clinical;
    }
  }

  get documentType() {
    return this.dto.documentType;
  }

  get content() {
    return this.dto.content;
  }

  get templateFormat() {
    return this.dto.templateFormat;
  }

  get parameters() {
    return this.dto.parameters;
  }

  get isCustom() {
    return this.dto.isCustom;
  }

  get isLetterhead() {
    return this.dto.isLetterhead;
  }

  get isSystem() {
    return this.dto.isSystem;
  }

  get isClinical() {
    return this.dto.isClinical;
  }

  get isAdmin() {
    return this.dto.isAdmin;
  }

  get eTag() {
    return this.dto.eTag;
  }

  get isNew() {
    return this.dto.isNew;
  }

  get updatedDate() {
    return DateTime.fromISO(this.dto.changeLog?.updatedDate);
  }

  get changeLog() {
    return this.dto.changeLog;
  }

  get createdBy() {
    return this.dto.changeLog?.createdBy;
  }

  get documentStatus() {
    return this.dto.documentStatus;
  }

  get isPracticeLetterhead() {
    return (
      this.name === NonCustomTemplateName.PracticeLetterhead &&
      !this.isCustom &&
      this.isLetterhead
    );
  }

  @observable
  renderedContent = "";

  @action
  setRenderedContent = (renderedContent: string) => {
    this.renderedContent = renderedContent;
  };

  public isHiddenOnStatus(
    documentStatusCode: SubmitActionCode,
    ignoreAuthorCheck?: boolean
  ) {
    if (ignoreAuthorCheck) {
      return this.documentStatus !== documentStatusCode;
    }
    return (
      this.documentStatus !== documentStatusCode ||
      this.core.accountInfo?.username === this.createdBy
    );
  }
}
