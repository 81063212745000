import { action, computed, observable, runInAction } from "mobx";

import { OrgUnitAvailabilityDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";

export class OrgUnitAvailability {
  constructor(dto: OrgUnitAvailabilityDto) {
    runInAction(() => {
      this.dto = dto;
    });
  }

  @observable.ref
  dto: OrgUnitAvailabilityDto;

  @computed
  get orgUnitId() {
    return this.dto.orgUnitId;
  }

  @computed
  get openingHours() {
    return this.dto.orgUnitOpeningHours ?? [];
  }

  @computed
  get openingHoursOverrides() {
    return this.dto.orgUnitOpeningHoursOverrides ?? [];
  }

  @action
  updateFromDto(dto: OrgUnitAvailabilityDto) {
    this.dto = dto;
  }
}
