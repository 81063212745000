import { TextBadgeColor } from "@bps/fluent-ui";
import { to2dp } from "@bps/utils";
import { isAllocationSpenderItemDto } from "@libs/gateways/billing/billing-gateway.utils.ts";
import {
  BillingStatuses,
  PaymentDto,
  PaymentItemDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";

import { Transaction } from "./Transaction.ts";

export class Payment extends Transaction<PaymentDto, PaymentItemDto> {
  get allocated() {
    return this.dto.allocated;
  }

  get allocationSpenders() {
    return this.spenders?.filter(isAllocationSpenderItemDto);
  }

  get spenders() {
    return this.dto.spenders;
  }

  get unallocated() {
    return this.isCancelled
      ? to2dp(0)
      : to2dp(this.total - (this.dto.allocated || 0));
  }

  get isPartOrFullyAllocated() {
    return !!this.spenders?.length;
  }

  get allocationStatus() {
    return this.dto.allocationStatus;
  }

  get viewLink() {
    return routes.accounts.payments.viewPath;
  }

  public get viewPermission() {
    return Permission.AccountHistoryAllowed;
  }

  get statusColour(): TextBadgeColor {
    if (this.unallocated > 0 && this.status === BillingStatuses.current) {
      return TextBadgeColor.yellow;
    } else {
      return super._getStatusColour();
    }
  }

  getStatusIcon(): string {
    if (this.status === BillingStatuses.current) {
      return "BpDollarSignFlash";
    } else {
      return super.getStatusIcon();
    }
  }

  get customReceiptText() {
    if (this.unallocated > 0) {
      return "Available";
    } else return "Allocated";
  }
}
