import { computed } from "mobx";

import { CreateInboxDocumentDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";

export class PendingUpload implements CreateInboxDocumentDto {
  private dto: CreateInboxDocumentDto;

  constructor(createDto: CreateInboxDocumentDto) {
    this.dto = { ...createDto };
  }

  @computed
  get id(): string {
    return this.dto.id;
  }

  @computed
  get name(): string {
    return this.dto.name;
  }

  @computed
  get extension(): string {
    return this.dto.extension;
  }

  updateFromDto(dto: CreateInboxDocumentDto) {
    this.dto = dto;
  }
}
