import { computed } from "mobx";

import {
  AccContractListDto,
  CpnDto,
  DisciplineAhpraNumberDto,
  MedicareProviderLocationNumberDto,
  PrescriberNumberDto,
  ProviderDto,
  UserQualificationsDto,
  UserQualificationType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { SaveProvider } from "@stores/practice/types/save-provider.type.ts";

export class Provider extends Model<ProviderDto> {
  @computed get medicareProviderNumber() {
    return userQualificationsToMedicare(this.dto.userQualifications);
  }

  @computed get ahpraNumber() {
    return userQualificationsToAhpraNumber(this.dto.userQualifications);
  }

  @computed get discipline() {
    return userQualificationsToDiscipline(this.dto.userQualifications);
  }

  @computed get cpn() {
    return userQualificationsToCpn(this.dto.userQualifications);
  }

  @computed get contractTypes() {
    return userQualificationsToContracts(this.dto.userQualifications);
  }

  @computed get prescriberNumber() {
    return userQualificationsToPrescriberNumber(this.dto.userQualifications);
  }

  @computed get userQualifications() {
    return this.dto.userQualifications;
  }

  @computed get signatureUrl() {
    return this.dto.signatureUrl;
  }
}

export const userQualificationsToMedicare = (
  userQualification: UserQualificationsDto[]
): string | undefined => {
  const value = userQualification?.find(
    (q): q is MedicareProviderLocationNumberDto =>
      q.userQualificationTypeCode === UserQualificationType.MPL
  );

  return value?.medicareProviderNumber;
};

export const userQualificationsToAhpraNumber = (
  userQualification: UserQualificationsDto[]
): string | undefined => {
  const value = userQualification?.find(
    (q): q is DisciplineAhpraNumberDto =>
      q.userQualificationTypeCode === UserQualificationType.DAN
  );

  return value?.ahpraNumber;
};

export const userQualificationsToDiscipline = (
  userQualification: UserQualificationsDto[]
): string | undefined => {
  const value = userQualification?.find(
    (q): q is DisciplineAhpraNumberDto =>
      q.userQualificationTypeCode === UserQualificationType.DAN
  );

  return value?.discipline;
};

export const userQualificationsToCpn = (
  userQualification: UserQualificationsDto[]
): string | undefined => {
  const value = userQualification?.find(
    (q): q is CpnDto =>
      q.userQualificationTypeCode === UserQualificationType.UserIdentifiersNZ
  );

  return value?.cpn;
};

export const userQualificationsToContracts = (
  userQualification: UserQualificationsDto[]
): string[] | undefined => {
  const value = userQualification?.find(
    (q): q is AccContractListDto =>
      q.userQualificationTypeCode === UserQualificationType.ProviderContractsNZ
  );

  return value?.accProviderContractTypeCodes;
};

export const userQualificationsToPrescriberNumber = (
  userQualification: UserQualificationsDto[]
): string | undefined => {
  const value = userQualification?.find(
    (q): q is PrescriberNumberDto =>
      q.userQualificationTypeCode === UserQualificationType.PrescriberNumber
  );

  return value?.prescriberNumber;
};
export enum ContractType {
  HandTherapistAlliedHealth = "58A",
  PhysiotherapistAlliedHealth = "81A",
  PhysiotherapistSpecifiedTreatment = "81S",
  OccupationalTherapistSpecifiedTreatment = "77S",
  OccupationalTherapistAlliedHealth = "77A",
  Orthotist = "60O"
}

const toUserQualifications = ({
  ahpraNumber,
  discipline,
  medicareProviderNumber,
  orgUnitId,
  cpn,
  accProviderContractTypeCodes,
  prescriberNumber
}: SaveProvider & {
  orgUnitId: string;
}) => {
  const userQualifications: UserQualificationsDto[] = [];
  if (ahpraNumber || discipline) {
    userQualifications.push({
      userQualificationTypeCode: UserQualificationType.DAN,
      discipline,
      ahpraNumber
    });
  }

  if (medicareProviderNumber) {
    userQualifications.push({
      userQualificationTypeCode: UserQualificationType.MPL,
      medicareProviderNumber: medicareProviderNumber.toUpperCase(),
      orgUnitId
    });
  }

  if (cpn) {
    userQualifications.push({
      userQualificationTypeCode: UserQualificationType.UserIdentifiersNZ,
      cpn: cpn.toUpperCase()
    });
  }

  if (accProviderContractTypeCodes) {
    userQualifications.push({
      userQualificationTypeCode: UserQualificationType.ProviderContractsNZ,
      accProviderContractTypeCodes: accProviderContractTypeCodes.filter(
        value => !!value
      )
    });
  }

  if (prescriberNumber) {
    userQualifications.push({
      userQualificationTypeCode: UserQualificationType.PrescriberNumber,
      prescriberNumber
    });
  }
  return userQualifications;
};

/**
 * the Provider resource endpoint schema is so convoluted that it
 * is simpler to introduce a different SaveProvider request type and map to it
 * in the store
 * @param request
 */
export const toProviderDto = ({
  id,
  ahpraNumber,
  discipline,
  medicareProviderNumber,
  orgUnitId,
  cpn,
  accProviderContractTypeCodes,
  prescriberNumber
}: SaveProvider & { orgUnitId: string }): Omit<ProviderDto, "eTag"> => {
  const userQualifications = toUserQualifications({
    id,
    ahpraNumber,
    discipline,
    medicareProviderNumber,
    orgUnitId,
    cpn,
    accProviderContractTypeCodes,
    prescriberNumber
  });

  return {
    id,
    userQualifications
  };
};
