import { action, computed } from "mobx";

import { DateTime } from "@bps/utils";
import {
  DocumentDto,
  DocumentEnum
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ReportTypeKeys } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import type { CorrespondenceStore } from "@stores/clinical/CorrespondenceStore.ts";

export class ClinicalDocument extends Model<DocumentDto> {
  constructor(
    dto: DocumentDto,
    private correspondence: CorrespondenceStore
  ) {
    super(dto);
  }

  @computed get patientId() {
    return this.dto.patientId;
  }

  @computed get to() {
    return this.dto.to;
  }

  @computed get from() {
    return this.dto.from;
  }

  @computed get type() {
    return this.dto.type;
  }

  @computed get direction() {
    return this.dto.direction;
  }

  @computed get content() {
    return this.dto.content;
  }

  @computed get status() {
    return this.dto.status;
  }

  @computed get store() {
    return this.dto.store;
  }

  @computed get showOnTimeline() {
    return this.dto.showOnTimeline;
  }

  @computed get visibility() {
    return this.dto.visibility;
  }

  @computed get secGroupId() {
    return this.dto.secGroupId;
  }

  @computed get metadata() {
    return this.dto.metadata;
  }
  //document.metadata properties
  @computed get name() {
    return this.dto.metadata.find(x => x.key === DocumentEnum.Name)?.value;
  }

  @computed get extension() {
    return this.dto.metadata.find(x => x.key === DocumentEnum.Extension)?.value;
  }

  @computed get date() {
    const metadataDate: string | undefined = this.dto.metadata.find(
      x => x.key === DocumentEnum.Date
    )?.value;
    return DateTime.fromISO(metadataDate);
  }

  @computed get dateString(): string {
    return this.dto.metadata.find(x => x.key === DocumentEnum.Date)?.value;
  }

  @computed get encounterId() {
    return this.dto.metadata.find(x => x.key === DocumentEnum.EncounterId)
      ?.value;
  }

  @computed get templateId() {
    return this.dto.metadata.find(x => x.key === DocumentEnum.TemplateId)
      ?.value;
  }

  @computed get contextData() {
    return this.dto.metadata.find(x => x.key === DocumentEnum.ContextData)
      ?.value;
  }

  @computed get checkedBy() {
    return this.dto.metadata.find(x => x.key === DocumentEnum.CheckedBy)?.value;
  }

  @computed get dateChecked(): string {
    return this.dto.metadata.find(x => x.key === DocumentEnum.DateChecked)
      ?.value;
  }

  @computed get reportType(): ReportTypeKeys {
    return this.dto.metadata.find(x => x.key === DocumentEnum.ReportType)
      ?.value;
  }

  //document properties... properties...
  @computed get size() {
    return this.dto.properties ? Number(this.dto.properties.size) : 0;
  }

  @computed get outcome() {
    return this.dto.metadata.find(x => x.key === DocumentEnum.Outcome)?.value;
  }

  @action
  preloadContent = async (contentType?: string) => {
    const { content } = await this.correspondence.getDocumentContent(
      this.patientId,
      this.id,
      contentType
    );
    this.updateFromPatch({ content });
  };
}
