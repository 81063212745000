import { computed } from "mobx";

import { ObservationDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class Observation extends Model<ObservationDto> {
  @computed
  get type() {
    return this.dto.type;
  }

  @computed
  get patientId() {
    return this.dto.patientId;
  }

  @computed
  get values() {
    return this.dto.values;
  }

  @computed
  get summary() {
    return this.dto.summary;
  }

  @computed
  get encounterId() {
    return this.dto.encounterId;
  }

  @computed
  get timestamp() {
    return this.dto.timestamp;
  }

  @computed
  get secGroupId() {
    return this.dto.secGroupId;
  }

  get changeLog() {
    return this.dto.changeLog;
  }
}
