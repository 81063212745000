import {
  RefDataAccessor,
  TPermissions
} from "@libs/api/ref-data/RefDataAccessor.ts";
import { IUserExperienceGateway } from "@libs/gateways/user-experience/UserExperienceGateway.interface.ts";

export class UserExperienceRef {
  constructor(private gateway: IUserExperienceGateway) {}
  patientSummaries = new RefDataAccessor(() =>
    this.gateway.getPatientSummaries()
  );

  dockViews = new RefDataAccessor(() => this.gateway.getDockViews());

  treeViewOptions = new RefDataAccessor<string, TPermissions>(() =>
    this.gateway.getTreeViewOptions()
  );

  businessRoleAbbreviations = new RefDataAccessor(() =>
    this.gateway.getBusinessRoleAbbreviations()
  );

  quickAccessIcon = new RefDataAccessor(() =>
    this.gateway.getQuickAccessIcons()
  );
  apptTypeBaseIntervals = new RefDataAccessor(() =>
    this.gateway.getApptTypeBaseIntervals()
  );

  quickColoursType = new RefDataAccessor(() =>
    this.gateway.getQuickColoursType()
  );
}
