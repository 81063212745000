import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import { IClinicalGateway } from "@libs/gateways/clinical/ClinicalGateway.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class CorrespondenceRef implements Ref<keyof CorrespondenceRef> {
  constructor(private gateway: IClinicalGateway) {}
  correspondenceTypes = new RefDataAccessor(() =>
    this.gateway.getCorrespondenceTypes()
  );

  correspondenceDirections = new RefDataAccessor(() =>
    this.gateway.getCorrespondenceDirections()
  );

  correspondenceStatuses = new RefDataAccessor(() =>
    this.gateway.getCorrespondenceStatuses()
  );
}
