import { ComponentType } from "react";
import { RouteObject } from "react-router-dom";

import { Country } from "@libs/enums/country.enum.ts";
import { RouteInfo } from "@libs/routing/routes.ts";
import { RoutePermissionOptions } from "@libs/routing/types/route-permission-options.interface.ts";
import { HasPermissions } from "@shared-types/core/has-permission.type.ts";
import { AuthProps } from "@shared-types/routing/auth-props.interface.tsx";
import { useStores } from "@stores/hooks/useStores.ts";

export type RouteDefinition = Pick<
  AuthProps,
  | "tenantLocation"
  | "redirect"
  | "businessRoleClasses"
  | "businessRoleClassesOperator"
> &
  Omit<RouteObject, "children" | "path"> & {
    path?: RouteInfo;
    importComponent: () => Promise<{ default: ComponentType<any> }>;
    hasPermissionsExpression?: (checkForPermission: HasPermissions) => boolean;
    children?: RouteDefinition[];
    //when set to true if the current route does not have a permission then find a relative route that does have permission and go to it.
    redirectToClosestPermissiveRoute?: boolean;
  };

export const useRouteDefinitionsFilter = () => {
  const { core } = useStores();

  return (item: {
    path?: RouteDefinition["path"];
    permissions?: RoutePermissionOptions["permissions"];
    permissionsOperator?: RoutePermissionOptions["permissionsOperator"];
    tenantLocation?: Country.NewZealand | Country.Australia;
    redirect?: AuthProps["redirect"];
    businessRoleClasses?: AuthProps["businessRoleClasses"];
    businessRoleClassesOperator?: AuthProps["businessRoleClassesOperator"];
  }) => {
    const permissions = item.permissions || item.path?.permissions;
    const permissionsOperator =
      item.permissionsOperator || item.path?.permissionsOperator;

    return (
      (!permissions ||
        core.hasPermissions(permissions, permissionsOperator || "or")) &&
      // filter by tenant location
      (!item.tenantLocation ||
        item.tenantLocation === core.tenantDetails?.country) &&
      (!item.businessRoleClasses ||
        core.hasBusinessRoleClasses(
          item.businessRoleClasses,
          item.businessRoleClassesOperator
        ))
    );
  };
};
