import { action, computed, observable, runInAction } from "mobx";

import { ScreenWidthMinXLarge } from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ClinicalNotesSubHeadings,
  DockableClinicalTab,
  PermanentClinicalTab,
  TodaysNotesHeading,
  TodaysStructuredNotesUserSettings
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";

import { ClinicalTabItem } from "./ClinicalTabItem.ts";
import { BodyExaminationState, ClinicalTabState } from "./ClinicalTabState.ts";

export class PatientClinicalRecordTab extends ClinicalTabState {
  constructor(
    public root: IRootStore,
    public patientId: string,
    encounterId?: string
  ) {
    super(root, {
      patientId,
      encounterId
    });
  }

  get state() {
    return this._state;
  }

  @computed
  get allTabs(): ClinicalTabItem[] {
    return this._state.tabs;
  }

  @action
  toggleSidePanel(isExpanding: boolean, currentScreenWidth: number) {
    if (currentScreenWidth <= ScreenWidthMinXLarge) {
      if (isExpanding) {
        this.state.sidePanelSize = TreeViewSize.Expanded;
      } else {
        this.state.sidePanelSize = TreeViewSize.IconsOnly;
      }
    } else {
      if (isExpanding) {
        this.state.sidePanelSize = this.state.sidePanelSize + 1;
      } else {
        this.state.sidePanelSize = this.state.sidePanelSize - 1;
      }
    }
  }

  @observable
  sidePanelOpacity: string | undefined = undefined;

  @action
  blinkSidePanel() {
    this.sidePanelOpacity = "60%";

    setTimeout(() => {
      runInAction(() => {
        this.sidePanelOpacity = undefined;
      });
    }, 200);
  }

  @action
  addTab(tab: ClinicalTabItem) {
    this._state.tabs?.push(tab);
  }

  @action
  insertTab(tab: ClinicalTabItem) {
    this._state.tabs?.splice(2, 0, tab);
  }

  @computed
  get activeTabType(): string | undefined {
    return this._state.tabs.find(i => i.isActive)?.type;
  }

  @computed
  get activeTab(): ClinicalTabItem | undefined {
    return this._state.tabs.find(i => i.isActive);
  }

  get sidePanelTab() {
    return this._state.sidePanelTab;
  }

  set sidePanelTab(tab: string | undefined) {
    if (this._state.sidePanelTab !== tab) {
      runInAction(() => {
        this._state.sidePanelTab = tab;
      });
    } else {
      this.blinkSidePanel();
    }
  }

  get previousNavigatedTab() {
    return this._state.previousNavigatedSidePanelTab;
  }

  set previousNavigatedTab(tab: string | undefined) {
    runInAction(() => {
      this._state.previousNavigatedSidePanelTab = tab;
      return;
    });
  }

  @computed
  get sidePanelTabId() {
    return this._state.pastVisitId;
  }

  @computed
  get sidePanelSize(): TreeViewSize | undefined {
    return this._state.sidePanelSize;
  }

  set sidePanelTabId(id: string | undefined) {
    runInAction(() => {
      this._state.pastVisitId = id;
      return;
    });
  }

  public hasTabTypeOpen(
    type: DockableClinicalTab,
    encounterId?: string,
    contextId?: string
  ): boolean {
    const id = `id_${type}${encounterId ?? ""}${contextId ?? ""}`;
    return this.allTabs.some(t => t.id === id);
  }

  @computed
  public get dirtyTabs(): ClinicalTabItem[] {
    return this._state.tabs.filter(x => x.isDirty);
  }

  @action
  show(tab: ClinicalTabItem): void {
    const isExisting: boolean = !this._state.tabs.find(a => a.id === tab.id);
    if (isExisting) {
      this.addTab(tab);
    }
    this.setActiveTab(tab);
  }

  @action
  removeTab(id: string, goToPreviousTab?: boolean): void {
    if (goToPreviousTab) {
      const previousTab = this.goBackToPreviousTab(id);
      this.setActiveTab(previousTab);
    }

    this._state.tabs = this._state.tabs.filter(a => a.id !== id);
  }

  @action
  hideActive = (): void => {
    const activeTabId = this.activeTab?.id;
    this.state.tabs = this.state.tabs.filter(a => {
      if (
        this.activeTab &&
        this.defaultTabs.some(t => t.type === this.activeTab!.type)
      )
        return true;
      return a.id !== this.activeTab!.id;
    });

    const previousTab = this.goBackToPreviousTab(activeTabId);
    this.setActiveTab(previousTab);
  };

  public notesHeadingKey = (heading: TodaysNotesHeading) =>
    heading ? heading.code + heading.name : "";

  @action setTodaysNotesUserSettingsIfNotDefined(
    heading: TodaysNotesHeading,
    val: TodaysStructuredNotesUserSettings
  ) {
    const key = this.notesHeadingKey(heading);
    if (!this._state.todaysNotesUserSettings.get(key)) {
      this._state.todaysNotesUserSettings.set(key, val);
    }
  }
  getTodaysNotesUserSettings(
    heading: TodaysNotesHeading,
    headingKey?: ClinicalNotesSubHeadings
  ) {
    let key = this.notesHeadingKey(heading);
    if (headingKey) {
      key = headingKey;
    }
    return this._state.todaysNotesUserSettings.get(key);
  }

  @action setTodaysNotesUserSettings(
    key: string,
    val: TodaysStructuredNotesUserSettings
  ) {
    this._state.todaysNotesUserSettings.set(key, {
      ...this._state.todaysNotesUserSettings.get(key),
      ...val
    });
  }

  @action
  toggleClinicalTools(key: string) {
    this._state.collapseClinicalTools[key] =
      !this._state.collapseClinicalTools[key];
  }

  @action
  private setActiveTab(tab: ClinicalTabItem | undefined): void {
    const activeTab = this.activeTab;
    if (activeTab) {
      activeTab.isActive = false;
    }

    const tabStillOpen = tab && this.state.tabs.includes(tab);

    if (tab && tabStillOpen) {
      tab.isActive = true;
      this.addToHistory(tab);
    } else {
      //no tab set to active, go back to default tab
      const defaultTab = this.state.tabs.find(t => {
        return this.state.todaysNotesUserSettings &&
          this.state.todaysNotesUserSettings.size
          ? t.type === PermanentClinicalTab.TodaysNotes
          : t.type === (this._state.defaultTab as PermanentClinicalTab);
      });
      if (defaultTab) {
        defaultTab.isActive = true;
      }
    }
  }

  private getTabById(
    type: DockableClinicalTab,
    encounterId?: string,
    contextId?: string
  ): ClinicalTabItem | undefined {
    const id = `id_${type}${encounterId ?? ""}${contextId ?? ""}`;
    return this._state.tabs.find(i => i.id === id);
  }

  // set active by type and id to switch btw opened tabs
  @action
  public setActive(
    type: DockableClinicalTab,
    encounterId?: string,
    contextId?: string
  ): void {
    const tab = this.getTabById(type, encounterId, contextId);
    if (tab) {
      this.setActiveTab(tab);
    }
  }

  // show not opened tabs in red if the form is dirty
  @action
  public setIsDirty(
    value: boolean,
    config: {
      type: DockableClinicalTab;
      encounterId?: string;
      contextId?: string;
    }
  ): void {
    const { type, contextId, encounterId } = config;
    const tab = this.getTabById(type, encounterId, contextId);
    if (tab) {
      tab.isDirty = value;
    }
  }

  @action
  public resetAllDirty(): void {
    for (const tab of this._state.tabs) {
      tab.isDirty = false;
    }
  }

  @action
  setCheckedOutcomeMeasures(checkedItems: ClinicalDataType[]) {
    runInAction(() => {
      this._state.checkedOutcomeMeasures = checkedItems;
    });
  }

  get getCheckedOutcomeMeasures() {
    return this._state.checkedOutcomeMeasures;
  }

  set currentClinicalClaimAdjustmentId(id: string | undefined) {
    runInAction(() => {
      this._state.currentClinicalClaimAdjustmentId = id;
    });
  }

  get currentClinicalClaimAdjustmentId() {
    return this._state.currentClinicalClaimAdjustmentId;
  }

  set currentClaimReviewClaimId(id: string | undefined) {
    runInAction(() => {
      this._state.currentClaimReviewClaimId = id;
    });
  }

  get bodyExam() {
    return this._state.bodyExam;
  }

  set bodyExam(value: BodyExaminationState) {
    runInAction(() => {
      this._state.bodyExam = value;
    });
  }

  get currentClaimReviewClaimId() {
    return this._state.currentClaimReviewClaimId;
  }

  private addToHistory(tabItem: ClinicalTabItem) {
    const tabAlreadyInListIndex = this.state.tabHistory.findIndex(
      (item: ClinicalTabItem) => item.id === tabItem.id
    );
    if (tabAlreadyInListIndex === -1) {
      this.state.tabHistory.push(tabItem);
    } else {
      //If tab is already in the list, move it to the end
      this.state.tabHistory.push(
        this.state.tabHistory.splice(tabAlreadyInListIndex, 1)[0]
      );
    }
  }

  private removeTabItem(tabId: string | undefined) {
    this.state.tabHistory = this.state.tabHistory.filter(
      (item: ClinicalTabItem) => item.id !== tabId
    );
  }

  private goBackToPreviousTab(
    tabBeingClosedId: string | undefined
  ): ClinicalTabItem | undefined {
    let previousTabItem: ClinicalTabItem | undefined;
    const index: number = this.state.tabHistory.findIndex(
      (item: ClinicalTabItem) => item.id === tabBeingClosedId
    );
    if (index > 0) {
      previousTabItem = this.state.tabHistory[index - 1];
    }
    //remove the current item
    this.removeTabItem(tabBeingClosedId);

    return previousTabItem;
  }
}
