import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { StatementDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class Statement extends Model<StatementDto> {
  @computed
  get date(): DateTime | undefined {
    return DateTime.fromISO(this.dto.date);
  }

  @computed
  get startDate(): DateTime {
    return DateTime.fromISO(this.dto.startDate);
  }

  @computed
  get endDate(): DateTime {
    return DateTime.fromISO(this.dto.endDate);
  }

  @computed
  get accountContactId(): string {
    return this.dto.accountContactId;
  }

  statementDocumentUrl: string | undefined;

  public setStatementDocumentUrl(url: string) {
    this.statementDocumentUrl = url;
  }
}
