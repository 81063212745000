import { TemplateDto } from "@libs/gateways/template-management/TemplateManagementGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { TemplateManagementStore } from "@stores/template-management/TemplateManagementStore.ts";

export class TemplateInstance extends Model<TemplateDto> {
  constructor(
    private templateManagement: TemplateManagementStore,
    dto: TemplateDto
  ) {
    super(dto);
  }

  get name() {
    return this.dto.name;
  }

  get description() {
    return this.dto.description;
  }

  get templateType() {
    return this.templateManagement.ref.templateTypes.get(
      this.dto.templateTypeCode
    )?.text;
  }
}
