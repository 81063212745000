import { action, computed, observable } from "mobx";

import { DocumentWriterTab } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { LaunchFrom } from "@libs/routing/routes.ts";
import { TemplatePickerProps } from "@shared-types/clinical/template-picker-props.interface.ts";
import { CorrespondenceStore } from "@stores/clinical/CorrespondenceStore.ts";

export class CorrespondenceUi {
  constructor(private correspondenceStore: CorrespondenceStore) {}

  private get root() {
    return this.correspondenceStore.root;
  }

  navigateToDocumentWriter = (documentTab: DocumentWriterTab) => {
    this.root.correspondence.activeDocumentTab = documentTab;
  };

  private get defaultTemplatePickerProps(): TemplatePickerProps {
    return {
      hidden: false,
      userId: this.root.core.userId,
      onDismiss: () => {
        this.setShowTemplatePicker(false);
      },
      onSuccess: this.navigateToDocumentWriter,
      launchFrom: LaunchFrom.Admin
    };
  }

  @observable
  currentTemplatePickerProps: TemplatePickerProps | undefined;

  @computed
  get showTemplatePicker() {
    return !!this.currentTemplatePickerProps;
  }

  @observable readyToSendEmailDocumentId: string | undefined = undefined;
  @observable readyToSendEmailPatientId: string | undefined = undefined;

  public isWaitingDocumentEvent: boolean = false;

  @action
  public setShowTemplatePicker(
    value: boolean,
    props: TemplatePickerProps = this.defaultTemplatePickerProps
  ) {
    if (value) {
      this.currentTemplatePickerProps = props;
    } else {
      this.currentTemplatePickerProps = undefined;
    }
  }
}
