import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  AddressType,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IPracticeGateway } from "@libs/gateways/practice/PracticeGateway.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

import { ContactCategoryRefDataAccessor } from "./ContactCategoryRefDataAccesor.ts";
import { PronounTypeRefDataAccessor } from "./PronounTypeRefDataAccessor.ts";
import { RelationshipTypeRefDataAccessor } from "./RelationshipTypeRefDataAccesor.ts";

export class PracticeRef implements Ref<keyof PracticeRef> {
  constructor(private gateway: IPracticeGateway) {}

  sexes = new RefDataAccessor(() => this.gateway.getSexes());

  languages = new RefDataAccessor(() =>
    this.gateway
      .getLanguages()
      .then(dtos => dtos.sort((a, b) => a.text.localeCompare(b.text)))
  );

  relationshipStatuses = new RefDataAccessor(() =>
    this.gateway.getRelationshipStatuses()
  );

  occupations = new RefDataAccessor(() =>
    this.gateway
      .getOccupations()
      .then(dtos => dtos.sort((a, b) => a.text.localeCompare(b.text)))
  );

  genders = new RefDataAccessor(() => this.gateway.getGenders());

  pronouns = new PronounTypeRefDataAccessor(() => this.gateway.getPronouns());

  ethnicities = new RefDataAccessor(() => this.gateway.getEthnicities());

  addressTypes = new RefDataAccessor<AddressType>(() =>
    this.gateway.getAddressTypes()
  );

  dvaCardColors = new RefDataAccessor(() => this.gateway.getDvaCardColors());

  contactTypes = new RefDataAccessor<ContactType>(() =>
    this.gateway.getContactTypes()
  );

  contactCategories = new ContactCategoryRefDataAccessor(() =>
    this.gateway.getContactCategories()
  );

  contactDisciplines = new RefDataAccessor(() =>
    this.gateway.getContactDisciplines()
  );

  organisationCategories = new RefDataAccessor(() =>
    this.gateway.getOrganisationCategories()
  );

  contactStatuses = new RefDataAccessor(() =>
    this.gateway.getContactStatuses()
  );

  patientCausesOfDeath = new RefDataAccessor(() =>
    this.gateway.getPatientCauseOfDeath()
  );

  relationshipTypes = new RelationshipTypeRefDataAccessor(() =>
    this.gateway.getRelationshipTypes()
  );

  accProviderTypes = new RefDataAccessor(() =>
    this.gateway.getAccProviderTypes()
  );

  accPracticeContractTypes = new RefDataAccessor(() =>
    this.gateway
      .getPracticeAccContractTypes()
      .then(data =>
        Array.from(data).sort((a, b) => a.sortOrder! - b.sortOrder!)
      )
  );

  accProviderContractTypes = new RefDataAccessor(() =>
    this.gateway.getProviderAccContractTypes()
  );

  patientNoticePriority = new RefDataAccessor(() =>
    this.gateway.getPatientNoticePriority()
  );
  patientNoticeType = new RefDataAccessor(() =>
    this.gateway.getPatientNoticeType()
  );

  organisationRoleType = new RefDataAccessor(() =>
    this.gateway.organisationRoleType()
  );

  employeeTypes = new RefDataAccessor(() => this.gateway.getEmploymentTypes());

  invoiceStatuses = new RefDataAccessor(() =>
    this.gateway.getInvoiceStatuses()
  );
}
